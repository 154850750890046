const allied_culture_buildings = [
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_6",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_6",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_6",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "490000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 6,
        "assetId": "Building_Vikings_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_5",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_5",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_5",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Premium_5",
                "target": "Building_Vikings_Home_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "430000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 5,
        "assetId": "Building_Vikings_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_2",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Premium_2",
                "target": "Building_Vikings_Home_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "26500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 2,
        "assetId": "Building_Vikings_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_1",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Premium_1",
                "target": "Building_Vikings_Home_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "13500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_4",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_4",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_4",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Premium_4",
                "target": "Building_Vikings_Home_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "80000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "370000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 4,
        "assetId": "Building_Vikings_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Premium_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Premium_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Premium_3",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Premium_3",
                "target": "Building_Vikings_Home_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Tattoos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "40000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "310000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "premiumHome",
        "level": 3,
        "assetId": "Building_Vikings_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_3",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_PapyrusPress_3",
                "target": "Building_Egypt_Workshop_PapyrusPress_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_CeremonialDress"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-250000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-3300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_PapyrusPress_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "360",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_PapyrusPress_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-10800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "650",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "30300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 1200,
            "effects": {
                "papyrus_scroll": 0.3
            }
        },
        "level": 3,
        "assetId": "Building_Egypt_Workshop_PapyrusPress_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_4",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_PapyrusPress_4",
                "target": "Building_Egypt_Workshop_PapyrusPress_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Storage"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-430000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-5100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-9300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-16000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "950",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "60",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-5500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-16500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "110",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_PapyrusPress_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-9500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-28500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "190",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "80300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effects": {
                "papyrus_scroll": 0.35,
                "ceremonial_dress": 0.065
            }
        },
        "level": 4,
        "assetId": "Building_Egypt_Workshop_PapyrusPress_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_5",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_PapyrusPress_5",
                "target": "Building_Egypt_Workshop_PapyrusPress_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Scaffolding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-730000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-7600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "500",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-13700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-6000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "1600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-13500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "90",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-8500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "160",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_PapyrusPress_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-15500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "290",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "166000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 2850,
            "effects": {
                "papyrus_scroll": 0.35,
                "ceremonial_dress": 0.065
            }
        },
        "level": 5,
        "assetId": "Building_Egypt_Workshop_PapyrusPress_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_6",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-11500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-5800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-21000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "1450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-10000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-37000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "2550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-8000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-20000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "135",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-14500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-36000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "240",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_PapyrusPress_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-25500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-64000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ceremonial_dress",
                            "amount": "430",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "312000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 4500,
            "effects": {
                "papyrus_scroll": 0.35,
                "ceremonial_dress": 0.065
            }
        },
        "level": 6,
        "assetId": "Building_Egypt_Workshop_PapyrusPress_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_1",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Workshop_PapyrusPress_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_PapyrusScroll"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_PapyrusPress_1",
                "target": "Building_Egypt_Workshop_PapyrusPress_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Joists"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "4",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 600,
            "effects": {
                "papyrus_scroll": 0.3
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_PapyrusPress_2",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_PapyrusPress_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_PapyrusPress_2",
                "target": "Building_Egypt_Workshop_PapyrusPress_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_FinePapyrus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-150000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_PapyrusPress_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_PapyrusPress_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-40",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-440",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "30",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_PapyrusPress_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-2200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_PapyrusPress_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-320",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "papyrus",
                            "amount": "-3500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus_scroll",
                            "amount": "240",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_PapyrusPress_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "250",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "papyrusPress",
        "happinessEffect": {
            "maxHappiness": 900,
            "effects": {
                "papyrus_scroll": 0.3
            }
        },
        "level": 2,
        "assetId": "Building_Egypt_Workshop_PapyrusPress_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_2",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_2",
                "range": 2,
                "points": 980
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Oasis_2",
                "target": "Building_Egypt_Irrigation_Oasis_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "455000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_Oasis_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_3",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_3",
                "range": 2,
                "points": 1350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Oasis_3",
                "target": "Building_Egypt_Irrigation_Oasis_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_ExtractionMethods"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "565000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_Oasis_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_1",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_1",
                "range": 2,
                "points": 700
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_Oasis_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_WaterWheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Oasis_1",
                "target": "Building_Egypt_Irrigation_Oasis_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "350000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_6",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_6",
                "range": 2,
                "points": 3700
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "890000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_Oasis_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_4",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_4",
                "range": 2,
                "points": 1900
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Oasis_4",
                "target": "Building_Egypt_Irrigation_Oasis_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "670000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_Oasis_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Oasis_5",
        "type": "irrigation",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Oasis_5",
                "range": 2,
                "points": 2650
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Oasis_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Oasis_5",
                "target": "Building_Egypt_Irrigation_Oasis_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Oasis_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Oasis_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "780000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "oasis",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_Oasis_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_City_CityHall_2",
        "type": "cityHall",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_City_CityHall_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_City_CityHall_2",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_City_CityHall_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "cityHall",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_City_CityHall_1",
        "type": "cityHall",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_City_CityHall_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_City_CityHall_1",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "cityHall",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_5",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_5",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Average_5",
                "target": "Building_Egypt_Home_Average_6",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Mummification"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-240000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "45000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "55100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1800,
            "effects": {
                "deben": 4.15
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_6",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_6",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "68000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "103000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2700,
            "effects": {
                "deben": 4.15
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_3",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Average_3",
                "target": "Building_Egypt_Home_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_CulturalExchange"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-85000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "10100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 800,
            "effects": {
                "deben": 3.75
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_4",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_4",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Average_4",
                "target": "Building_Egypt_Home_Average_5",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_MakeUp"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-140000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "27100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1200,
            "effects": {
                "deben": 4.15
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_1",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Home_Average_1",
                "duration": "10s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_RiseOfEgypt"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Average_1",
                "target": "Building_Egypt_Home_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Hieroglyphs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "4500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "35",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 360,
            "effects": {
                "deben": 2.1
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Average_2",
        "type": "home",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Average_2",
                "target": "Building_Egypt_Home_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Geography"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-50000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "87",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 530,
            "effects": {
                "deben": 2.8
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_4",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ThreadProcessor_4",
                "target": "Building_China_Workshop_ThreadProcessor_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_LaborDuties"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-120000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-1000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ThreadProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-1820",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "720",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ThreadProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-3300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "16600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 4,
        "assetId": "Building_China_Workshop_ThreadProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_5",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ThreadProcessor_5",
                "target": "Building_China_Workshop_ThreadProcessor_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_PorcelainMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-240000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-450",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-1500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ThreadProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-2790",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "1100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ThreadProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-4800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "40600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 5,
        "assetId": "Building_China_Workshop_ThreadProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_2",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ThreadProcessor_2",
                "target": "Building_China_Workshop_ThreadProcessor_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DynasticLaw"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-20000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-20",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-65",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "25",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ThreadProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-110",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "135",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ThreadProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-630",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "240",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "590",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 2,
        "assetId": "Building_China_Workshop_ThreadProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_3",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ThreadProcessor_3",
                "target": "Building_China_Workshop_ThreadProcessor_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_IronPlough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-150",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ThreadProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-910",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "360",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ThreadProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-1650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "650",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "4590",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 3,
        "assetId": "Building_China_Workshop_ThreadProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_6",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_6",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_6",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "52000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "62100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1700,
            "effects": {
                "deben": 10.0
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_1",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Workshop_ThreadProcessor_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_Sericulture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ThreadProcessor_1",
                "target": "Building_China_Workshop_ThreadProcessor_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_Calligraphy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-5",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "5",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "70",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_5",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_5",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Small_5",
                "target": "Building_Egypt_Home_Small_6",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Library"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-145000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_5",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "33100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1150,
            "effects": {
                "deben": 10.0
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_4",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_4",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Small_4",
                "target": "Building_Egypt_Home_Small_5",
                "autoFinish": true,
                "duration": "720s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Astrology"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-85000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_4",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "16100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 780,
            "effects": {
                "deben": 10.0
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_3",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Small_3",
                "target": "Building_Egypt_Home_Small_4",
                "autoFinish": true,
                "duration": "180s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_EgyptianConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-50000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "12000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "6050",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 510,
            "effects": {
                "deben": 8.0
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_2",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Small_2",
                "target": "Building_Egypt_Home_Small_3",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_BurialRites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "50",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 340,
            "effects": {
                "deben": 6.0
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Small_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Home_Small_1",
                "duration": "5s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Small_1",
                "target": "Building_Egypt_Home_Small_2",
                "autoFinish": true,
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_EgyptianDiplomacy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "20",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 230,
            "effects": {
                "deben": 4.4
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_1",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_Beehive_Average_1",
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Beekeeping"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Beehive_Average_1",
                "target": "Building_Vikings_Beehive_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_ImprovedBeekeeping"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "56",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_5",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Beehive_Average_5",
                "target": "Building_Vikings_Beehive_Average_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_BeekeepingMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-110000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "31000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "36100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_4",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Beehive_Average_4",
                "target": "Building_Vikings_Beehive_Average_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_PerfectedHoney"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-86000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "20500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "18900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_3",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Beehive_Average_3",
                "target": "Building_Vikings_Beehive_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_SuperiorBeekeeping"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-69000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "13500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "5080",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_2",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Beehive_Average_2",
                "target": "Building_Vikings_Beehive_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Pictograms"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-23000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "480",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_4",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Premium_4",
                "target": "Building_China_RiceFarm_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "12000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "115000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 4,
        "assetId": "Building_China_RiceFarm_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_3",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Premium_3",
                "target": "Building_China_RiceFarm_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_IronPlough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "94000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 3,
        "assetId": "Building_China_RiceFarm_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_6",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "27500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 6,
        "assetId": "Building_China_RiceFarm_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_5",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Premium_5",
                "target": "Building_China_RiceFarm_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 5,
        "assetId": "Building_China_RiceFarm_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_2",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Premium_2",
                "target": "Building_China_RiceFarm_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "4000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "75000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 2,
        "assetId": "Building_China_RiceFarm_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Premium_1",
        "type": "riceFarm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_RiceFarm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_InkAndBrush"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Premium_1",
                "target": "Building_China_RiceFarm_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "2700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "56500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "premiumRiceFarm",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_City_CityHall_2",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_City_CityHall_2",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_City_CityHall_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "80000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "cityHall",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Beehive_Average_6",
        "type": "beehive",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Beehive_Average_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Beehive_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Beehive_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "honey",
                            "amount": "46500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Beehive_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "58100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averageBeehive",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_City_CityHall_1",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "cityHall",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_2",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_MothGlade_2",
                "target": "Building_China_ExtractionPoint_MothGlade_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_RefinedSilk"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-3800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "20s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "4000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 2,
        "assetId": "Building_China_ExtractionPoint_MothGlade_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_1",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_MothGlade_1",
                "target": "Building_China_ExtractionPoint_MothGlade_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_Sericulture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "2700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_4",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_MothGlade_4",
                "target": "Building_China_ExtractionPoint_MothGlade_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_LaborDuties"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-22000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "12300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 4,
        "assetId": "Building_China_ExtractionPoint_MothGlade_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_3",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_MothGlade_3",
                "target": "Building_China_ExtractionPoint_MothGlade_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DedicatedWorkforce"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-11000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "6200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 3,
        "assetId": "Building_China_ExtractionPoint_MothGlade_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_6",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "27000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 6,
        "assetId": "Building_China_ExtractionPoint_MothGlade_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_MothGlade_5",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_MothGlade_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_MothGlade_5",
                "target": "Building_China_ExtractionPoint_MothGlade_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_PorcelainMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_MothGlade_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "moth_cocoons",
                            "amount": "18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "mothGlade",
        "level": 5,
        "assetId": "Building_China_ExtractionPoint_MothGlade_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_3",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_ExpeditionPier_Average_3",
                "target": "Building_Vikings_ExpeditionPier_Average_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_GlimaFighting"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-160000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "110"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "225"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "340"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "95"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "190"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "285"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_ExpeditionPier_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-3400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production2_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production2_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "200"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "400"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "600"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "170"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "340"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "510"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production2_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_ExpeditionPier_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-6100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production3_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production3_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "360"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "720"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1080"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "305"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "610"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "915"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production3_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "11900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 3,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_ExpeditionPier_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_4",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_ExpeditionPier_Average_4",
                "target": "Building_Vikings_ExpeditionPier_Average_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_LongDistanceSeafaring"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-200000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-3750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "175"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "350"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "525"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "300"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "450"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_ExpeditionPier_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-6750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production2_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production2_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "315"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "630"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "945"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "270"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "540"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "810"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production2_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_ExpeditionPier_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-4300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-12000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production3_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production3_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "560"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1120"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1680"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "475"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "950"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1425"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production3_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "43900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 4,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_ExpeditionPier_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_5",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_ExpeditionPier_Average_5",
                "target": "Building_Vikings_ExpeditionPier_Average_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_MagneticCompass"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-250000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2050",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-5650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "260"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "520"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "780"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "220"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "440"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "660"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_ExpeditionPier_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-3700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-10200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production2_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production2_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "475"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "950"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1425"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "400"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "800"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1200"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production2_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_ExpeditionPier_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-6500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production3_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production3_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "850"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1700"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2550"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "725"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "2175"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production3_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "83900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 5,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_ExpeditionPier_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_6",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-3100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-8550",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "400"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "800"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1200"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "335"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "670"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1000"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_ExpeditionPier_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-5600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-15400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production2_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production2_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "725"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2175"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "620"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1240"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1860"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production2_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_ExpeditionPier_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-9900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-27300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production3_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production3_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1275"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2550"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "3825"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1585"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "3170"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "4755"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production3_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "134000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 6,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_ExpeditionPier_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_1",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_ExpeditionPier_Average_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_PlunderingRaids"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_ExpeditionPier_Average_1",
                "target": "Building_Vikings_ExpeditionPier_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FaeringMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-4900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 3.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-20",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_1",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_1_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "3"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "5"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "8"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "2"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "4"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "6"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_1_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_1"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "130",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 1,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_ExpeditionPier_Average_2",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_ExpeditionPier_Average_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_ExpeditionPier_Average_2",
                "target": "Building_Vikings_ExpeditionPier_Average_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FelagPartnerships"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-54000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_ExpeditionPier_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_ExpeditionPier_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 2.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production1_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production1_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "12"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "25"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "40"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "10"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "20"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "30"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production1_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production1_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_ExpeditionPier_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-550",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-1100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production2_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production2_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "70"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "135"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "200"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "55"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "115"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "175"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production2_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production2_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_ExpeditionPier_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-950",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_ExpeditionPier_Production3_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_ExpeditionPier_Production3_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "120"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "240"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "360"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "100"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "200"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "300"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "ExpeditionPier_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_ExpeditionPier_Production3_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_ExpeditionPier_Production3_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_ExpeditionPier_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "1110",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "expeditionPier",
        "level": 2,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_ExpeditionPier_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_1",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_SailorPort_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_PlunderingRaids"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-390",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Premium_1",
                "target": "Building_Vikings_SailorPort_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 3.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-15",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-35",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_1",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_1_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "3"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "7"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "10"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "3"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "5"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "8"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_1_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_1"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "295000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 1,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_5",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_5",
                "range": 2,
                "points": 400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_WaterPump_5",
                "target": "Building_Egypt_Irrigation_WaterPump_6",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_MakeUp"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-140000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "31900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_WaterPump_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_2",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Premium_2",
                "target": "Building_Vikings_SailorPort_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 2.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-90",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "20"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "35"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "50"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "15"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "30"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "45"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Premium_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-490",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-990",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production2_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production2_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "90"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "175"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "260"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "75"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "225"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production2_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Premium_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production3_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production3_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "310"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "470"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "130"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "260"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "390"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_2_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production3_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "480000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 2,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_4",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_4",
                "range": 2,
                "points": 280
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_WaterPump_4",
                "target": "Building_Egypt_Irrigation_WaterPump_5",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Storage"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-82000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "15500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_WaterPump_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_6",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_6",
                "range": 2,
                "points": 550
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "59900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_WaterPump_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_5",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Premium_5",
                "target": "Building_Vikings_SailorPort_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-5100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "340"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "675"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1010"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "290"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "570"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "850"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-3300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-9200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production2_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production2_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "610"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1230"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1850"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "530"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1050"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1570"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production2_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-5900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-16000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production3_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production3_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "850"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1700"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2550"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "720"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "2180"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_5_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production3_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-740",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-710",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production4_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production4_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "260"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "530"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "800"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "240"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "470"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "700"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production4_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production5_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production5_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "410"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "820"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1230"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "350"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "700"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1050"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production5_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_SailorPort_Premium_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production6_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production6_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "590"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1170"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1750"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "500"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1000"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1500"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production6_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "1050000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 5,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_1",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_1",
                "range": 2,
                "points": 100
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_WaterPump_1",
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_WaterPump"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_WaterPump_1",
                "target": "Building_Egypt_Irrigation_WaterPump_2",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-195",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "26",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_6",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-7700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "530"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1050"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1570"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "900"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1350"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-5000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-13900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production2_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production2_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "950"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1900"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2850"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "800"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1600"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "2400"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production2_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-8900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-24500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production3_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production3_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1700"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "3350"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "5000"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "2900"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "4350"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_6_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production3_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production4_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production4_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "470"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "950"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1430"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "410"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "830"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1250"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production4_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production5_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production5_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "710"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1430"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "2150"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "630"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1250"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1870"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production5_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_SailorPort_Premium_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-3600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-3500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production6_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production6_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1050"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "2080"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "3100"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "900"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1790"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "2680"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production6_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "1250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 6,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_3",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Premium_3",
                "target": "Building_Vikings_SailorPort_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_DragonBoats"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "140"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "290"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "440"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "120"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "250"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "380"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Premium_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1450",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-3000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production2_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production2_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "260"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "520"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "780"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "220"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "440"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "660"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production2_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Premium_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-5500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production3_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production3_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "460"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "930"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1400"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "400"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "790"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1180"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_3_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production3_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "670000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 3,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_3",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_3",
                "range": 2,
                "points": 200
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_WaterPump_3",
                "target": "Building_Egypt_Irrigation_WaterPump_4",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-48000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "5870",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_WaterPump_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Premium_4",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Premium_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Premium_4",
                "target": "Building_Vikings_SailorPort_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-3400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production1_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production1_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "220"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "680"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "190"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "380"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "570"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production1_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production1_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2150",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-6100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production2_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production2_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "410"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "820"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1230"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "350"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "700"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1050"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production2_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production2_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-3900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-11000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production3_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production3_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic_1"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "720"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic_2"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "1450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic_3"
                                                            },
                                                            "resource": "ceramic_treasure",
                                                            "amount": "2180"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_ceramic_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Ceramic"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Ceramic"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold_1"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "610"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold_2"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1230"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold_3"
                                                            },
                                                            "resource": "gold_treasure",
                                                            "amount": "1850"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gold_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gold"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production3_Treasures_4_Gold"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest1_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production3_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-490",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-480",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production4_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production4_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "300"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "450"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "130"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "260"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "390"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production4_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production4_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-860",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-860",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production5_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production5_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "230"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "460"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "690"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "190"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "390"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "590"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production5_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production5_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_SailorPort_Premium_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPortPremium_Production6_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPortPremium_Production6_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "330"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "650"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "970"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "280"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "560"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "840"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPortPremium_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPortPremium_Production6_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPortPremium_Production6_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "855000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumSailorPort",
        "level": 4,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_WaterPump_2",
        "type": "irrigation",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_WaterPump_2",
                "range": 2,
                "points": 150
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_WaterPump_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_WaterPump_2",
                "target": "Building_Egypt_Irrigation_WaterPump_3",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-29000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_WaterPump_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_WaterPump_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "65",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "waterPump",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_WaterPump_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_6",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "40000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "124000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 3350,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_5",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Average_5",
                "target": "Building_Egypt_PapyrusField_Average_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Scaffolding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-290000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "27000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "66100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 2200,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_1",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_Home_Sailor_1",
                "duration": "10s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_RiseOfTheVikings"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Sailor_1",
                "target": "Building_Vikings_Home_Sailor_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Jarldom"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "8000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "50",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_4",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Average_4",
                "target": "Building_Egypt_PapyrusField_Average_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Storage"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "32100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 1450,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_3",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Average_3",
                "target": "Building_Egypt_PapyrusField_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_CeremonialDress"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "11850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "12100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 950,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_2",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Average_2",
                "target": "Building_Egypt_PapyrusField_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_FinePapyrus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "7900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 620,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Average_1",
        "type": "papyrusField",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_PapyrusField_Average_1",
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_PapyrusScroll"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Average_1",
                "target": "Building_Egypt_PapyrusField_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Joists"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "5250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "40",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averagePapyrusField",
        "happinessEffect": {
            "maxHappiness": 420,
            "effects": {
                "papyrus": 2.1
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_Home_Worker_1",
                "duration": "5s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Worker_1",
                "target": "Building_Vikings_Home_Worker_2",
                "autoFinish": true,
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_ThrallsAndKarls"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "30",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_3",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Worker_3",
                "target": "Building_Vikings_Home_Worker_4",
                "autoFinish": true,
                "duration": "180s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Tattoos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-20000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "1130",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_2",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Worker_2",
                "target": "Building_Vikings_Home_Worker_3",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_RunicWriting"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-5000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "130",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_5",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_5",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Worker_5",
                "target": "Building_Vikings_Home_Worker_6",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_VikingHomesteads"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-56000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_5",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "23000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "14100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_4",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_4",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Worker_4",
                "target": "Building_Vikings_Home_Worker_5",
                "autoFinish": true,
                "duration": "720s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_TaflGames"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-45000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_4",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "5130",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Worker_6",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Vikings_Home_Worker_6",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Worker_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Worker_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Worker_6",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "29000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Worker_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "25300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "workerHome",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_4",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_4",
                "range": 1,
                "points": 700
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Channel_4",
                "target": "Building_Egypt_Irrigation_Channel_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_CulturalExchange"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-150000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "27900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_Channel_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_5",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_5",
                "range": 1,
                "points": 1000
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Channel_5",
                "target": "Building_Egypt_Irrigation_Channel_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Astrology"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-260000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "57900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_Channel_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_6",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_6",
                "range": 1,
                "points": 1350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_Channel_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_1",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_1",
                "range": 1,
                "points": 250
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_Channel_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Channel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Channel_1",
                "target": "Building_Egypt_Irrigation_Channel_2",
                "autoFinish": true,
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_BurialRites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-345",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "46",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_2",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_2",
                "range": 1,
                "points": 350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Channel_2",
                "target": "Building_Egypt_Irrigation_Channel_3",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Preservation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-52000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_Channel_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Channel_3",
        "type": "irrigation",
        "width": 5,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Channel_3",
                "range": 1,
                "points": 500
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Channel_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Channel_3",
                "target": "Building_Egypt_Irrigation_Channel_4",
                "autoFinish": true,
                "duration": "180s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_EgyptianConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-87000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Channel_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Channel_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "10500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "channel",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_Channel_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_1",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_GoldMine_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Ankh"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Premium_1",
                "target": "Building_Egypt_GoldMine_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "5600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 410,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_2",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Premium_2",
                "target": "Building_Egypt_GoldMine_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "8300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "145000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 620,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 2,
        "assetId": "Building_Egypt_GoldMine_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_3",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Premium_3",
                "target": "Building_Egypt_GoldMine_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_GoldenMask"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "12700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 950,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 3,
        "assetId": "Building_Egypt_GoldMine_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_4",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Premium_4",
                "target": "Building_Egypt_GoldMine_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "19500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "215000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 1450,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 4,
        "assetId": "Building_Egypt_GoldMine_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_5",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Premium_5",
                "target": "Building_Egypt_GoldMine_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 2200,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 5,
        "assetId": "Building_Egypt_GoldMine_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Premium_6",
        "type": "goldMine",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Premium_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "290000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumGoldMine",
        "happinessEffect": {
            "maxHappiness": 3300,
            "effects": {
                "gold_ore": 1.7
            }
        },
        "level": 6,
        "assetId": "Building_Egypt_GoldMine_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_4",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Workshop_Tavern_4",
                "target": "Building_Vikings_Workshop_Tavern_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_MeadMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-160000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-3400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "1350",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-6100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "2400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-11000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "4300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-4550",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-3350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "500",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-8200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-6000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_Workshop_Tavern_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-14500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-10700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "1600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "35300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 4,
        "assetId": "Building_Vikings_Workshop_Tavern_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_5",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Workshop_Tavern_5",
                "target": "Building_Vikings_Workshop_Tavern_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_TavernBrawls"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-200000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-5100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "2000",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-9200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "3600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-16300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "6400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-6800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-5050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "750",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-12250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-9100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "1350",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_Workshop_Tavern_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-21800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-16150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "2400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "67300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 5,
        "assetId": "Building_Vikings_Workshop_Tavern_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_2",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Workshop_Tavern_2",
                "target": "Building_Vikings_Workshop_Tavern_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_HealingMead"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-42000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 2.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_Workshop_Tavern_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-1400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_Workshop_Tavern_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-2400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "950",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "860",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 2,
        "assetId": "Building_Vikings_Workshop_Tavern_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_3",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Workshop_Tavern_3",
                "target": "Building_Vikings_Workshop_Tavern_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_MeadHalls"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-130000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_Workshop_Tavern_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-4100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "1600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_Workshop_Tavern_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-7400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "2900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "9260",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 3,
        "assetId": "Building_Vikings_Workshop_Tavern_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_1",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_Workshop_Tavern_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_MeadBrewery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Workshop_Tavern_1",
                "target": "Building_Vikings_Workshop_Tavern_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_TarOvens"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-3800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 3.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "15",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Workshop_Tavern_6",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Workshop_Tavern_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Workshop_Tavern_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-7800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "3100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-14300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "5600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-25500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mead",
                            "amount": "10000",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-10500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-7550",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-19200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-13800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "2100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Vikings_Workshop_Tavern_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 6
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "fish",
                            "amount": "-33800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "honey",
                            "amount": "-24300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stockfish",
                            "amount": "3700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Workshop_Tavern_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "107000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "tavern",
        "level": 6,
        "assetId": "Building_Vikings_Workshop_Tavern_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_City_CityHall_1",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "40000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "cityHall",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_City_CityHall_2",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_City_CityHall_2",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_City_CityHall_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_City_CityHall_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "cityHall",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_6",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-1800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_PorcelainWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-3250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "810",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_PorcelainWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-5750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "1440",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "126000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 6,
        "assetId": "Building_China_Workshop_PorcelainWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_4",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_PorcelainWorkshop_4",
                "target": "Building_China_Workshop_PorcelainWorkshop_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_AdvancedClayworks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-640",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_PorcelainWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1150",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-1450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "360",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_PorcelainWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2050",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-2550",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "640",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "24100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 4,
        "assetId": "Building_China_Workshop_PorcelainWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_5",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_PorcelainWorkshop_5",
                "target": "Building_China_Workshop_PorcelainWorkshop_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_PorcelainMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-340000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-960",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-1200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_PorcelainWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1750",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-2150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "540",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_PorcelainWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-3900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "960",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "58100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 5,
        "assetId": "Building_China_Workshop_PorcelainWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_2",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_PorcelainWorkshop_2",
                "target": "Building_China_Workshop_PorcelainWorkshop_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-29000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-35",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "15",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_PorcelainWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "80",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_PorcelainWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "140",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "860",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 2,
        "assetId": "Building_China_Workshop_PorcelainWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_3",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_PorcelainWorkshop_3",
                "target": "Building_China_Workshop_PorcelainWorkshop_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-87000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-320",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_PorcelainWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-720",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_PorcelainWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "320",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "6660",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 3,
        "assetId": "Building_China_Workshop_PorcelainWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_PorcelainWorkshop_1",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_PorcelainWorkshop_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Workshop_PorcelainWorkshop_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_PorcelainProduction"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_PorcelainWorkshop_1",
                "target": "Building_China_Workshop_PorcelainWorkshop_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-3800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_PorcelainWorkshop_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_PorcelainWorkshop_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "clay",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "porcelain",
                            "amount": "2",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_PorcelainWorkshop_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "porcelainWorkshop",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_2",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Average_2",
                "target": "Building_China_RiceFarm_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_EnhancedPaddyFields"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-7400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "2250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "220",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_2",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_2",
                "range": 2,
                "points": 560
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_IrrigationStation_2",
                "target": "Building_Egypt_Irrigation_IrrigationStation_3",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-68000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_IrrigationStation_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_1",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_RiceFarm_Average_1",
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_InkAndBrush"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Average_1",
                "target": "Building_China_RiceFarm_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_PaddyFields"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-980",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "1500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "26",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_1",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_1",
                "range": 2,
                "points": 400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_IrrigationStation_1",
                "duration": "420s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_ExtractionMethods"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_IrrigationStation_1",
                "target": "Building_Egypt_Irrigation_IrrigationStation_2",
                "autoFinish": true,
                "duration": "150s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "60",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_4",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Average_4",
                "target": "Building_China_RiceFarm_Average_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_Terracing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "6900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "6100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_4",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_4",
                "range": 2,
                "points": 1100
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_IrrigationStation_4",
                "target": "Building_Egypt_Irrigation_IrrigationStation_5",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Alloys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-190000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "35800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_IrrigationStation_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_3",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Average_3",
                "target": "Building_China_RiceFarm_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_IronPlough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-22000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "3500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_3",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_3",
                "range": 2,
                "points": 780
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_IrrigationStation_3",
                "target": "Building_Egypt_Irrigation_IrrigationStation_4",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_GoldenMask"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-110000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "13800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_IrrigationStation_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_6",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "32500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_RiceFarm_Average_5",
        "type": "riceFarm",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_RiceFarm_Average_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_RiceFarm_Average_5",
                "target": "Building_China_RiceFarm_Average_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DrylandFarming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-88000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_RiceFarm_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_RiceFarm_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "rice",
                            "amount": "10350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_RiceFarm_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "14900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "averageRiceFarm",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_4",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Average_4",
                "target": "Building_Egypt_GoldMine_Average_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Alloys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "10500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "32100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_3",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Average_3",
                "target": "Building_Egypt_GoldMine_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_GoldenMask"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "7000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "12100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 890,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_2",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Average_2",
                "target": "Building_Egypt_GoldMine_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Preservation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "4700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 580,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_1",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_GoldMine_Average_1",
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Ankh"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Average_1",
                "target": "Building_Egypt_GoldMine_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Geometry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "3150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "40",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 390,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_6",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "25500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "124000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 3150,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_GoldMine_Average_5",
        "type": "goldMine",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_GoldMine_Average_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_GoldMine_Average_5",
                "target": "Building_Egypt_GoldMine_Average_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Astronomy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-290000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_GoldMine_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_GoldMine_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_ore",
                            "amount": "16500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_GoldMine_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "66100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "averageGoldMine",
        "happinessEffect": {
            "maxHappiness": 2050,
            "effects": {
                "gold_ore": 1.35
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_6",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_6",
                "range": 2,
                "points": 2140
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "138000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_IrrigationStation_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_IrrigationStation_5",
        "type": "irrigation",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_IrrigationStation_5",
                "range": 2,
                "points": 1530
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_IrrigationStation_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_IrrigationStation_5",
                "target": "Building_Egypt_Irrigation_IrrigationStation_6",
                "autoFinish": true,
                "duration": "15000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_MakeUp"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-320000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_IrrigationStation_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_IrrigationStation_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "73800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "irrigationStation",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_IrrigationStation_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_2",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Average_2",
                "target": "Building_Vikings_FishingPier_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_DriftNets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-23000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "11500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "480",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 2,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_3",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Average_3",
                "target": "Building_Vikings_FishingPier_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_ImprovedFishing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-69000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "17000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "5080",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 3,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_1",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_FishingPier_Average_1",
                "duration": "20s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Seafaring"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Average_1",
                "target": "Building_Vikings_FishingPier_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FishingNets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "56",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 1,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_6",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "77000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "58100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 6,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_4",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Average_4",
                "target": "Building_Vikings_FishingPier_Average_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Sagas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-86000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "34000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "18900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 4,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Average_5",
        "type": "fishingPier",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Average_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Average_5",
                "target": "Building_Vikings_FishingPier_Average_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FishingMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-110000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "51000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "36100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "averagePier",
        "level": 5,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_1",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Premium_1",
                "target": "Building_China_Home_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "4300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "94000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_2",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_2",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Premium_2",
                "target": "Building_China_Home_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "8700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "125000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 2,
        "assetId": "Building_China_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_3",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_3",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Premium_3",
                "target": "Building_China_Home_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DedicatedWorkforce"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "17300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "155000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 3,
        "assetId": "Building_China_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_4",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_4",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Premium_4",
                "target": "Building_China_Home_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "55000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "185000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 4,
        "assetId": "Building_China_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_5",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_5",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Premium_5",
                "target": "Building_China_Home_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "95000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "215000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 5,
        "assetId": "Building_China_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Premium_6",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Premium_6",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "165000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "245000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "premiumHome",
        "level": 6,
        "assetId": "Building_China_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_4",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_4",
                "range": 1,
                "points": 550
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_SmallWell_4",
                "target": "Building_Egypt_Irrigation_SmallWell_5",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_WaterPump"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-130000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "24100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_SmallWell_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_5",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_5",
                "range": 1,
                "points": 770
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_SmallWell_5",
                "target": "Building_Egypt_Irrigation_SmallWell_6",
                "autoFinish": true,
                "duration": "12000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Astrology"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-220000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "50100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_SmallWell_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_2",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_2",
                "range": 1,
                "points": 280
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_SmallWell_2",
                "target": "Building_Egypt_Irrigation_SmallWell_3",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Geography"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-45000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_SmallWell_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_3",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_3",
                "range": 1,
                "points": 400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_SmallWell_3",
                "target": "Building_Egypt_Irrigation_SmallWell_4",
                "autoFinish": true,
                "duration": "180s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_EgyptianConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-75000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "9100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_SmallWell_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_1",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_1",
                "range": 1,
                "points": 200
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_SmallWell_1",
                "duration": "20s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_WaterWheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_SmallWell_1",
                "target": "Building_Egypt_Irrigation_SmallWell_2",
                "autoFinish": true,
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Hieroglyphs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "40",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_2",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ClayProcessor_2",
                "target": "Building_China_Workshop_ClayProcessor_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-20000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-20",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "20",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ClayProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-110",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "110",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ClayProcessor_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "190",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "590",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 2,
        "assetId": "Building_China_Workshop_ClayProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_1",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Workshop_ClayProcessor_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_KaolinProcessing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ClayProcessor_1",
                "target": "Building_China_Workshop_ClayProcessor_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "3",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "70",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_4",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ClayProcessor_4",
                "target": "Building_China_Workshop_ClayProcessor_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_BakedBricks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-120000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ClayProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-750",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-3100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ClayProcessor_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-5400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "950",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "16600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 4,
        "assetId": "Building_China_Workshop_ClayProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_3",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ClayProcessor_3",
                "target": "Building_China_Workshop_ClayProcessor_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ClayProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-1500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "270",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ClayProcessor_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-670",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-2700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "480",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "4590",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 3,
        "assetId": "Building_China_Workshop_ClayProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_SmallWell_6",
        "type": "irrigation",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_SmallWell_6",
                "range": 1,
                "points": 1080
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_SmallWell_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_SmallWell_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_SmallWell_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "94100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "smallWell",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_SmallWell_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_6",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_6",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "75000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "45000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_2",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Sailor_2",
                "target": "Building_Vikings_Home_Sailor_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_SkaldicPoetry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "220",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_6",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-980",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-4000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ClayProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1750",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-7150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "1250",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ClayProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3550",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-14500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "2550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "88600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 6,
        "assetId": "Building_China_Workshop_ClayProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_3",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Sailor_3",
                "target": "Building_Vikings_Home_Sailor_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FuneralAtSea"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-35000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "2020",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ClayProcessor_5",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ClayProcessor_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_ClayProcessor_5",
                "target": "Building_China_Workshop_ClayProcessor_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_FloorPlans"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-240000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ClayProcessor_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ClayProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-630",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ClayProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-4600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ClayProcessor_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "kaolin",
                            "amount": "-8400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "clay",
                            "amount": "1450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ClayProcessor_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "40600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "clayProcessor",
        "level": 5,
        "assetId": "Building_China_Workshop_ClayProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_4",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_4",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Sailor_4",
                "target": "Building_Vikings_Home_Sailor_5",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_BurialSites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-80000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "9020",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_Home_Sailor_5",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Building_Vikings_Home_Sailor_5",
                "amount": 2,
                "type": "WorkerType_SAILOR_VIKINGS"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_Home_Sailor_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_Home_Sailor_5",
                "target": "Building_Vikings_Home_Sailor_6",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_FjordDominion"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_Home_Sailor_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_Home_Sailor_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_Home_Sailor_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "25000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "age": "Vikings",
        "group": "sailorHome",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_5",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Premium_5",
                "target": "Building_Vikings_FishingPier_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "75000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "355000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 5,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_FishingPier_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_4",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Premium_4",
                "target": "Building_Vikings_FishingPier_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "50000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "295000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 4,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_FishingPier_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_3",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Premium_3",
                "target": "Building_Vikings_FishingPier_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_ImprovedFishing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "25000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "235000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 3,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_FishingPier_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_2",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Premium_2",
                "target": "Building_Vikings_FishingPier_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "16500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "175000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 2,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_FishingPier_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_1",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_FishingPier_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_Seafaring"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_FishingPier_Premium_1",
                "target": "Building_Vikings_FishingPier_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "115000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 1,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_FishingPier_Premium_6",
        "type": "fishingPier",
        "width": 6,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_FishingPier_Premium_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_FishingPier_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Vikings_FishingPier_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "fish",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_FishingPier_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "415000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "premiumPier",
        "level": 6,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_FishingPier_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_5",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_5",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Average_5",
                "target": "Building_China_Home_Average_6",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DrylandFarming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "80000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "28400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_6",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_6",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "145000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "62400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_1",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_1",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Home_Average_1",
                "duration": "10s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_RiseOfChina"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Average_1",
                "target": "Building_China_Home_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_RammedEarthHouses"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "3700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "50",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_2",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_2",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Average_2",
                "target": "Building_China_Home_Average_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DynasticLaw"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-14000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "430",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_3",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_3",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Average_3",
                "target": "Building_China_Home_Average_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DedicatedWorkforce"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-42000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "3230",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Average_4",
        "type": "home",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Average_4",
                "amount": 5
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Average_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Average_4",
                "target": "Building_China_Home_Average_5",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_LaborDuties"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-84000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Average_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "45000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "11600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "averageHome",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Premium_2",
                "target": "Building_Egypt_Home_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "25000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 470,
            "effects": {
                "deben": 6.5
            }
        },
        "level": 2,
        "assetId": "Building_Egypt_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Premium_1",
                "target": "Building_Egypt_Home_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "12300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "230000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 310,
            "effects": {
                "deben": 5.0
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Small_2",
                "target": "Building_China_Home_Small_3",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_RefinedSilk"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-2900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "2250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "86",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Home_Small_1",
                "duration": "5s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Small_1",
                "target": "Building_China_Home_Small_2",
                "autoFinish": true,
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_SilkManufacture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_6",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_6",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_6",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "12600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_5",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_5",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Small_5",
                "target": "Building_China_Home_Small_6",
                "autoFinish": true,
                "duration": "1500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_ChineseConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-34000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_5",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "5810",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_4",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_4",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Small_4",
                "target": "Building_China_Home_Small_5",
                "autoFinish": true,
                "duration": "720s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DeepFoundations"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-17000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_4",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "13500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "2410",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_China_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Home_Small_3",
                "target": "Building_China_Home_Small_4",
                "autoFinish": true,
                "duration": "180s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_KaolinProcessing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-8700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "4500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "670",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "age": "China",
        "group": "smallHome",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_6",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_6",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "200000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "590000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 2350,
            "effects": {
                "deben": 10.5
            }
        },
        "level": 6,
        "assetId": "Building_Egypt_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_5",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_5",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Premium_5",
                "target": "Building_Egypt_Home_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "135000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "515000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1600,
            "effects": {
                "deben": 10.5
            }
        },
        "level": 5,
        "assetId": "Building_Egypt_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_4",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_4",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Premium_4",
                "target": "Building_Egypt_Home_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "87000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "445000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1050,
            "effects": {
                "deben": 10.5
            }
        },
        "level": 4,
        "assetId": "Building_Egypt_Home_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Home_Premium_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_Egypt_Home_Premium_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Home_Premium_3",
                "target": "Building_Egypt_Home_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_EgyptianConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Home_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_Home_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "50000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Home_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "370000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 700,
            "effects": {
                "deben": 9.0
            }
        },
        "level": 3,
        "assetId": "Building_Egypt_Home_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_2",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Average_2",
                "target": "Building_Vikings_SailorPort_Average_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-83000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 2.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-40",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_2_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_2_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "7"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "15"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "25"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_2_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_2_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_2_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "5"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "10"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_2_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "15"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_2_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production2_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production2_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_2_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_2_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "40"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "80"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "120"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_2_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_2_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_2_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "30"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "55"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_2_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "85"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_2_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production2_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Average_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production3_Treasures_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production3_Treasures_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_2_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_2_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "70"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "145"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "220"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_2_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_2_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_2_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "50"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "100"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_2_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "150"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_2_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production3_Treasures_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 2,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_1",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Vikings_SailorPort_Average_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_DragonBoats"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-1000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Average_1",
                "target": "Building_Vikings_SailorPort_Average_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 3.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-5",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_1",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_1_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_1_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_1_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "2"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "3"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "5"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_1_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_1_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_1_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "2"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_1_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "3"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_1_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_1"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 1,
        "expansionSubType": "ExpansionSubType_WATER"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_4",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Average_4",
                "target": "Building_Vikings_SailorPort_Average_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_SunCompass"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-310000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-540",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "110"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "230"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "350"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "100"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "200"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "300"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-960",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-950",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production2_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production2_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "200"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "410"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "620"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "180"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "360"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "540"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production2_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Average_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production3_Treasures_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production3_Treasures_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_4_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_4_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "360"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "730"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1100"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_4_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_4_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_4_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "320"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "640"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_4_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "960"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_4_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production3_Treasures_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "68300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 4,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_3",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Average_3",
                "target": "Building_Vikings_SailorPort_Average_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-250000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-360",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_3_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_3_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "75"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "225"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_3_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_3_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_3_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "60"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "125"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_3_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "190"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_3_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-650",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production2_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production2_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_3_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_3_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "130"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "270"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "400"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_3_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_3_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_3_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "110"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "230"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_3_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "350"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_3_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production2_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Average_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1150",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-830",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production3_Treasures_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production3_Treasures_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_3_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_3_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "240"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "480"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "720"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_3_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_3_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_3_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "200"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "400"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_3_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "600"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_3_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production3_Treasures_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "18300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 3,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_ThreadProcessor_6",
        "type": "workshop",
        "width": 6,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_ThreadProcessor_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_ThreadProcessor_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_ThreadProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_ThreadProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-4050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "1600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_ThreadProcessor_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.7,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "moth_cocoons",
                            "amount": "-7400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk_threads",
                            "amount": "2900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_ThreadProcessor_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "88600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "threadProcessor",
        "level": 6,
        "assetId": "Building_China_Workshop_ThreadProcessor_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_6",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "250"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "500"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "750"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "220"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "430"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "650"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-2150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production2_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production2_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "450"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "900"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1350"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "380"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "770"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1160"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production2_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Average_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-4000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-3850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production3_Treasures_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production3_Treasures_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_6_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_6_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "800"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1600"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "2400"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_6_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_6_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_6_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "690"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1380"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_6_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "2070"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_6_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production3_Treasures_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "208000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 6,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Vikings_SailorPort_Average_5",
        "type": "workshop",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Vikings_SailorPort_Average_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Vikings_SailorPort_Average_5",
                "target": "Building_Vikings_SailorPort_Average_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Vikings_HarbourEconomics"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pennies",
                            "amount": "-390000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5,
                    "type": "WorkerType_SAILOR_VIKINGS"
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Vikings_SailorPort_Average_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Vikings_SailorPort_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-820",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-790",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production1_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production1_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "170"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "350"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "530"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production1_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production1_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "150"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "300"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production1_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "450"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production1_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_1"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production1_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Vikings_SailorPort_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-1500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-1430",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production2_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production2_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "310"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "630"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "950"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production2_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production2_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "270"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "540"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production2_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "810"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production2_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_2"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production2_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Vikings_SailorPort_Average_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5,
                        "type": "WorkerType_SAILOR_VIKINGS"
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mead",
                            "amount": "-2580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stockfish",
                            "amount": "-2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_SailorPort_Production3_Treasures_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/LootContainerRewardDTO",
                                    "baseData": {
                                        "id": "Reward_SailorPort_Production3_Treasures_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_5_Spice",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_5_Spice_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Spice_1"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "550"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Spice_2"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1100"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Spice_3"
                                                            },
                                                            "resource": "spice_treasure",
                                                            "amount": "1650"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_spice_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Spice"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_5_Spice"
                                            }
                                        },
                                        {
                                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                                            "id": "Reward_SailorPort_Production3_Treasures_5_Gem",
                                            "rewards": [
                                                {
                                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                                    "baseData": {
                                                        "id": "Reward_SailorPort_Production3_Treasures_5_Gem_Id"
                                                    },
                                                    "rewards": [
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Gem_1"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "480"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Gem_2"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "960"
                                                        },
                                                        {
                                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                                            "baseData": {
                                                                "id": "Reward_SailorPort_Production3_Treasures_5_Gem_3"
                                                            },
                                                            "resource": "gem_treasure",
                                                            "amount": "1440"
                                                        }
                                                    ],
                                                    "chances": [
                                                        1,
                                                        1,
                                                        1
                                                    ],
                                                    "assetId": "icon_gem_treasure",
                                                    "randomSeedCounter": "SailorPort_Production_Gem"
                                                }
                                            ],
                                            "baseData": {
                                                "id": "Reward_SailorPort_Production3_Treasures_5_Gem"
                                            }
                                        }
                                    ],
                                    "assetId": "icon_mixedchest2_3"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_SailorPort_Production3_Treasures_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Vikings_SailorPort_Average_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pennies",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Vikings"
        ],
        "freeProductionSlots": 3,
        "age": "Vikings",
        "group": "sailorPort",
        "level": 5,
        "expansionSubType": "ExpansionSubType_WATER",
        "assetId": "Building_Vikings_SailorPort_Average_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_1",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_China_Workshop_SilkWorkshop_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_SilkManufacture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_SilkWorkshop_1",
                "target": "Building_China_Workshop_SilkWorkshop_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_RefinedSilk"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-3800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-5",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "3",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_2",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_SilkWorkshop_2",
                "target": "Building_China_Workshop_SilkWorkshop_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_SilkMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-29000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-35",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "20",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_SilkWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-165",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "110",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_SilkWorkshop_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "190",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "860",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 2,
        "assetId": "Building_China_Workshop_SilkWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_3",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_SilkWorkshop_3",
                "target": "Building_China_Workshop_SilkWorkshop_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_IronPlough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-87000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-320",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_SilkWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-490",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "270",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_SilkWorkshop_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1030",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-870",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "480",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "6660",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 3,
        "assetId": "Building_China_Workshop_SilkWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_6",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_6",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "74000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "290000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 3150,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 6,
        "assetId": "Building_Egypt_PapyrusField_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_4",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_SilkWorkshop_4",
                "target": "Building_China_Workshop_SilkWorkshop_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_Terracing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-640",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-540",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_SilkWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1180",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-990",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_SilkWorkshop_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "950",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "24100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 4,
        "assetId": "Building_China_Workshop_SilkWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_1",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_PapyrusField_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_PapyrusScroll"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Premium_1",
                "target": "Building_Egypt_PapyrusField_Premium_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "9300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 390,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_5",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_Workshop_SilkWorkshop_5",
                "target": "Building_China_Workshop_SilkWorkshop_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_ChineseConsensus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-340000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-960",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-810",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_SilkWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-1440",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_SilkWorkshop_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-3100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "1450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "58100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 5,
        "assetId": "Building_China_Workshop_SilkWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_Workshop_SilkWorkshop_6",
        "type": "workshop",
        "width": 5,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_Workshop_SilkWorkshop_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_China_Workshop_SilkWorkshop_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_China_Workshop_SilkWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-1500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_China_Workshop_SilkWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-2700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-2250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "1250",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_China_Workshop_SilkWorkshop_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "rice",
                            "amount": "-5450",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silk_threads",
                            "amount": "-4750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silk",
                            "amount": "2550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_China_Workshop_SilkWorkshop_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wu_zhu",
                            "amount": "126000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "silkWorkshop",
        "level": 6,
        "assetId": "Building_China_Workshop_SilkWorkshop_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_5",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Premium_5",
                "target": "Building_Egypt_PapyrusField_Premium_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_5",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 2050,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 5,
        "assetId": "Building_Egypt_PapyrusField_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_4",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Premium_4",
                "target": "Building_Egypt_PapyrusField_Premium_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_4",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "32000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "215000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 1370,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 4,
        "assetId": "Building_Egypt_PapyrusField_Premium_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_3",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Premium_3",
                "target": "Building_Egypt_PapyrusField_Premium_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_CeremonialDress"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_3",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "21500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 880,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 3,
        "assetId": "Building_Egypt_PapyrusField_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_PapyrusField_Premium_2",
        "type": "papyrusField",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_PapyrusField_Premium_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_PapyrusField_Premium_2",
                "target": "Building_Egypt_PapyrusField_Premium_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_PapyrusField_Premium_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_Egypt_PapyrusField_Premium_2",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "papyrus",
                            "amount": "14000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_PapyrusField_Premium_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "145000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "premiumPapyrusField",
        "happinessEffect": {
            "maxHappiness": 590,
            "effects": {
                "papyrus": 3.0
            }
        },
        "level": 2,
        "assetId": "Building_Egypt_PapyrusField_Premium_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_2",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_Goldsmith_2",
                "target": "Building_Egypt_Workshop_Goldsmith_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Preservation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-150000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_2",
                "type": "ProductionType_WORKER",
                "duration": "300s",
                "minCollectionPeriod": "300s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-40",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "20",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_Goldsmith_2",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-1350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_Goldsmith_2",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-320",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "160",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "250",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 900,
            "effects": {
                "ankh": 0.2
            }
        },
        "level": 2,
        "assetId": "Building_Egypt_Workshop_Goldsmith_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_1",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Workshop_Goldsmith_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Ankh"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 4
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_Goldsmith_1",
                "target": "Building_Egypt_Workshop_Goldsmith_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Geometry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_1",
                "type": "ProductionType_WORKER",
                "duration": "60s",
                "minCollectionPeriod": "60s",
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "3",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 600,
            "effects": {
                "ankh": 0.2
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_6",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-5800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-13800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "1100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-10000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-8000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-13500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "90",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-14500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-24000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "160",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_Goldsmith_6",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-25500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "290",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "312000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 4500,
            "effects": {
                "ankh": 0.28,
                "golden_mask": 0.04
            }
        },
        "level": 6,
        "assetId": "Building_Egypt_Workshop_Goldsmith_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_5",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_Goldsmith_5",
                "target": "Building_Egypt_Workshop_Goldsmith_6",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Astronomy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-730000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-5000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-9000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "720",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-6000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-16000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-8000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "55",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-8500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-14500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_Goldsmith_5",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-15500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-25500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "175",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "166000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 2850,
            "effects": {
                "ankh": 0.28,
                "golden_mask": 0.04
            }
        },
        "level": 5,
        "assetId": "Building_Egypt_Workshop_Goldsmith_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_4",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_Goldsmith_4",
                "target": "Building_Egypt_Workshop_Goldsmith_5",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_Alloys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-430000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-3100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "250",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-5600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-10000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production5_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-3000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-5000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "35",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production6_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-5500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-9200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "65",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_Egypt_Workshop_Goldsmith_4",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-9500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-15800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "golden_mask",
                            "amount": "110",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "80300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effects": {
                "ankh": 0.28,
                "golden_mask": 0.04
            }
        },
        "level": 4,
        "assetId": "Building_Egypt_Workshop_Goldsmith_2"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Workshop_Goldsmith_3",
        "type": "workshop",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Workshop_Goldsmith_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Workshop_Goldsmith_3",
                "target": "Building_Egypt_Workshop_Goldsmith_4",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_GoldenMask"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-250000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Workshop_Goldsmith_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_Egypt_Workshop_Goldsmith_3",
                "type": "ProductionType_WORKER",
                "duration": "1800s",
                "minCollectionPeriod": "1800s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-2000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_Egypt_Workshop_Goldsmith_3",
                "type": "ProductionType_WORKER",
                "duration": "3600s",
                "minCollectionPeriod": "3600s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-1100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-3600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "270",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_Egypt_Workshop_Goldsmith_3",
                "type": "ProductionType_WORKER",
                "duration": "7200s",
                "minCollectionPeriod": "7200s",
                "skipPricePerMinute": 0.3333,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "deben",
                            "amount": "-2000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_ore",
                            "amount": "-6500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ankh",
                            "amount": "480",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Workshop_Goldsmith_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "30300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "freeProductionSlots": 3,
        "age": "Egypt",
        "group": "goldsmith",
        "happinessEffect": {
            "maxHappiness": 1200,
            "effects": {
                "ankh": 0.2
            }
        },
        "level": 3,
        "assetId": "Building_Egypt_Workshop_Goldsmith_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_2",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_2",
                "range": 4,
                "points": 850
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Fountain_2",
                "target": "Building_Egypt_Irrigation_Fountain_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "625000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 2,
        "assetId": "Building_Egypt_Irrigation_Fountain_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_1",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_1",
                "range": 4,
                "points": 600
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_Egypt_Irrigation_Fountain_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_Egypt_ExtractionMethods"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-390",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Fountain_1",
                "target": "Building_Egypt_Irrigation_Fountain_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "470000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_6",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_6",
                "range": 4,
                "points": 3350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_6"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "1250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 6,
        "assetId": "Building_Egypt_Irrigation_Fountain_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_5",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_5",
                "range": 4,
                "points": 2400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Fountain_5",
                "target": "Building_Egypt_Irrigation_Fountain_6",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "1100000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 5,
        "assetId": "Building_Egypt_Irrigation_Fountain_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_4",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_4",
                "range": 4,
                "points": 1700
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Fountain_4",
                "target": "Building_Egypt_Irrigation_Fountain_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "935000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 4,
        "assetId": "Building_Egypt_Irrigation_Fountain_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_Egypt_Irrigation_Fountain_3",
        "type": "irrigation",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_Egypt_Irrigation_Fountain_3",
                "range": 4,
                "points": 1200
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_Egypt_Irrigation_Fountain_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_Egypt_Irrigation_Fountain_3",
                "target": "Building_Egypt_Irrigation_Fountain_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_Egypt_Irrigation_Fountain_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_Egypt_Irrigation_Fountain_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "deben",
                            "amount": "780000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Egypt"
        ],
        "age": "Egypt",
        "group": "fountain",
        "level": 3,
        "assetId": "Building_Egypt_Irrigation_Fountain_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_6",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_6",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "45000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 6,
        "assetId": "Building_China_ExtractionPoint_KaolinQuarry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_4",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_KaolinQuarry_4",
                "target": "Building_China_ExtractionPoint_KaolinQuarry_5",
                "autoFinish": true,
                "duration": "2280s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_DeepFoundations"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_4",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "19500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 4,
        "assetId": "Building_China_ExtractionPoint_KaolinQuarry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_5",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_KaolinQuarry_5",
                "target": "Building_China_ExtractionPoint_KaolinQuarry_6",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_UrbanManagement"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-60000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_5",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 5,
        "assetId": "Building_China_ExtractionPoint_KaolinQuarry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_2",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_KaolinQuarry_2",
                "target": "Building_China_ExtractionPoint_KaolinQuarry_3",
                "autoFinish": true,
                "duration": "90s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-3800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "30s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "6300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 2,
        "assetId": "Building_China_ExtractionPoint_KaolinQuarry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_3",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_KaolinQuarry_3",
                "target": "Building_China_ExtractionPoint_KaolinQuarry_4",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_China_KaolinProcessing"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "9500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 3,
        "assetId": "Building_China_ExtractionPoint_KaolinQuarry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_China_ExtractionPoint_KaolinQuarry_1",
        "type": "extractionPoint",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_China_ExtractionPoint_KaolinQuarry_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_SLOTS",
                "slots": 3
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_China_ExtractionPoint_KaolinQuarry_1",
                "target": "Building_China_ExtractionPoint_KaolinQuarry_2",
                "autoFinish": true,
                "duration": "30s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wu_zhu",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_China_ExtractionPoint_KaolinQuarry_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "10s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "kaolin",
                            "amount": "4200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.EVENT_CITY_PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            }
        ],
        "cities": [
            "City_China"
        ],
        "freeProductionSlots": 3,
        "age": "China",
        "group": "kaolinQuarry",
        "level": 1
    }
]

export default allied_culture_buildings;