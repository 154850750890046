const wondersChests = [
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Vikings_DragonshipEllida",
        "cityDefinition": "City_Vikings",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Vikings_DragonshipEllida",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Vikings_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "105"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "195"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "520"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "510"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "620"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1950"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "660"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "670"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "820"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "880"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "810"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Vikings_DragonshipEllida",
                "modifier": 0.01,
                "resourceDefinitionId": "honey",
                "cities": [
                    "City_Vikings"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Vikings_ResourceBoostReduced_1"
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost2_Wonder_Vikings_DragonshipEllida",
                "modifier": 0.01,
                "resourceDefinitionId": "pennies",
                "cities": [
                    "City_Vikings"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Vikings_ResourceBoostReduced_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Vikings_DragonshipEllida",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Worker_SpecialReduced"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Wonder_Vikings_DragonshipEllida",
                "type": "WorkerType_SAILOR_VIKINGS",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Sailor_SpecialReduced"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Vikings_DragonshipEllida",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_China_TerracottaArmy",
        "cityDefinition": "City_China",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_China_TerracottaArmy",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_China_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_2_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_3_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_6_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_13_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "135"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "185"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "395"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "930"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "465"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1070"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "535"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "570"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "285"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "830"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "415"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "605"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "870"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "435"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "675"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "940"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "710"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "355"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "970"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "485"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "245"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "745"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "375"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1010"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "505"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "525"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "815"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1080"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "425"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "885"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "445"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "575"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "595"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1910"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "955"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1980"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "990"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "495"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "315"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1025"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "515"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1060"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "665"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "335"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1095"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "685"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "345"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "565"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "585"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_China_TerracottaArmy",
                "modifier": 0.01,
                "resourceDefinitionId": "wu_zhu",
                "cities": [
                    "City_China"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_China_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_China_TerracottaArmy",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_China_Worker_Special"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_China_TerracottaArmy",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Vikings_Yggdrasil",
        "cityDefinition": "City_Vikings",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Vikings_Yggdrasil",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Vikings_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "105"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "195"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "520"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "510"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "620"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1950"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "660"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "670"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "820"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "880"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "810"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Vikings_Yggdrasil",
                "modifier": 0.01,
                "resourceDefinitionId": "honey",
                "cities": [
                    "City_Vikings"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Vikings_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Vikings_Yggdrasil",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Worker_Normal"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Wonder_Vikings_Yggdrasil",
                "type": "WorkerType_SAILOR_VIKINGS",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Sailor_Normal"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Vikings_Yggdrasil",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_China_ForbiddenCity",
        "cityDefinition": "City_China",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_China_ForbiddenCity",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_China_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_2_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_3_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_6_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_13_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "135"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "185"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "395"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "930"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "465"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1070"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "535"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "570"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "285"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "830"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "415"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "605"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "870"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "435"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "675"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "940"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "710"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "355"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "970"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "485"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "245"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "745"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "375"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1010"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "505"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "525"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "815"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1080"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "425"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "885"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "445"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "575"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "595"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1910"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "955"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1980"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "990"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "495"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "315"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1025"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "515"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1060"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "665"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "335"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1095"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "685"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "345"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "565"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "585"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_China_ForbiddenCity",
                "modifier": 0.01,
                "resourceDefinitionId": "wu_zhu",
                "cities": [
                    "City_China"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_China_ResourceBoostReduced_1"
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost2_Wonder_China_ForbiddenCity",
                "modifier": 0.01,
                "resourceDefinitionId": "rice",
                "cities": [
                    "City_China"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_China_ResourceBoostReduced_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_China_ForbiddenCity",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_China_Worker_SpecialReduced"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_China_ForbiddenCity",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Egypt_AbuSimbel",
        "cityDefinition": "City_Egypt",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Egypt_AbuSimbel",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Egypt_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_1",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_2",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_5",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_6",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_5",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_6",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_5",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_6",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Egypt_AbuSimbel",
                "modifier": 0.01,
                "resourceDefinitionId": "papyrus",
                "cities": [
                    "City_Egypt"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Egypt_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Egypt_AbuSimbel",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Egypt_Worker_Normal"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Egypt_AbuSimbel",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Vikings_Valhalla",
        "cityDefinition": "City_Vikings",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Vikings_Valhalla",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Vikings_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_10_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_11_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_12_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_13_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_14_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_15_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_16_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_17_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "105"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_18_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "195"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_19_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_21_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_23_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "520"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "1900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "510"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "620"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "1950"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "660"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "580"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "310"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "670"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "680"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "820"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "740"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "2900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "880"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3600"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "spice_treasure",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "810"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gem_treasure",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceramic_treasure",
                                        "amount": "3800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "StoriesAndMyths_Vikings_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "gold_treasure",
                                        "amount": "3250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Vikings_Valhalla",
                "modifier": 0.01,
                "resourceDefinitionId": "pennies",
                "cities": [
                    "City_Vikings"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Vikings_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Vikings_Valhalla",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Worker_Special"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker2_Wonder_Vikings_Valhalla",
                "type": "WorkerType_SAILOR_VIKINGS",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Vikings_Sailor_Special"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Vikings_Valhalla",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_China_GreatWall",
        "cityDefinition": "City_China",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_China_GreatWall",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_China_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_2_1",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_3_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_3_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_6_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_6_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_13_1",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_13_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_14_1",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_14_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_15_1",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_15_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_16_1",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_16_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_17_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "55"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_18_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "45"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_19_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "230"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_20_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_21_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_22_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_22_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_23_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_24_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_24_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_25_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "135"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_25_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_26_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_26_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_27_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "185"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_27_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "95"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_28_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "790"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "395"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_28_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "115"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_29_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "860"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "215"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_29_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_30_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "930"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "465"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_31_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "760"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_32_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1070"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "535"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "800"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_33_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "570"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "285"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "830"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "415"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_34_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "605"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "870"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "435"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_35_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "640"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "900"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_36_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "675"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "940"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "470"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "235"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_37_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1420"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "710"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "355"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "970"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "485"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "245"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_38_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1490"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "745"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "375"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1010"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "505"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "255"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_39_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "780"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "390"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "525"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "265"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_40_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "815"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "410"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1080"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "540"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "270"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_41_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "425"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "560"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_42_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1770"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "885"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "445"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "575"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "290"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_43_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1840"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "920"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "460"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "595"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_44_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1910"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "955"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "480"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "610"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "305"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_45_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1980"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "990"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "495"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "630"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "315"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_46_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2050"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1025"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "515"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "325"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_47_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1060"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "530"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "665"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "335"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_48_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1095"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "550"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1370"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "685"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "345"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_49_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "565"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "700"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_China_Crate_50_1",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "2330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "1165"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "silk",
                                        "amount": "585"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "1440"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "720"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_China_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "porcelain",
                                        "amount": "360"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_China_GreatWall",
                "modifier": 0.01,
                "resourceDefinitionId": "rice",
                "cities": [
                    "City_China"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_China_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_China_GreatWall",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_China_Worker_Normal"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_China_GreatWall",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Egypt_CheopsPyramid",
        "cityDefinition": "City_Egypt",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Egypt_CheopsPyramid",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Egypt_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_1",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_2",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_5",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_6",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_5",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_6",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_5",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_6",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Egypt_CheopsPyramid",
                "modifier": 0.01,
                "resourceDefinitionId": "deben",
                "cities": [
                    "City_Egypt"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Egypt_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Egypt_CheopsPyramid",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Egypt_Worker_Special"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Egypt_CheopsPyramid",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    },
    {
        "@type": "type.googleapis.com/ReworkedWonderDefinitionDTO",
        "id": "Wonder_Egypt_GreatSphinx",
        "cityDefinition": "City_Egypt",
        "tags": [
            "Statue"
        ],
        "components": [
            {
                "@type": "type.googleapis.com/WonderLevelUpComponentDTO",
                "id": "LevelUp_Wonder_Egypt_GreatSphinx",
                "duration": "300s",
                "dynamicCosts": {
                    "id": "Dac_Wonder_EventCity_Egypt_UpgradeCosts",
                    "byWonderLevel": {
                        "0": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_1_2",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "3"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "1": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_1",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_2_2",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "2": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_1",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "5"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_3_2",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "4"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "3": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_4_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "4": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_5_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "5": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_6_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "6": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_7_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "7": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_8_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "8": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_9_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "9": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_10_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "10": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_11_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "11": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_12_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "12": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_13_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "13": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_14_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "14": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "125"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_15_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "15": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_16_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "16": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "350"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "175"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_17_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "17": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "400"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "340"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_18_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "18": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "750"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "450"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "225"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "650"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "380"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_19_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "190"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "19": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "250"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "430"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_20_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "20": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "25"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "15"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "10"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_21_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "21": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_2",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "30"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "35"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_5",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "20"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_22_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "22": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_23_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "23": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "papyrus_scroll",
                                        "amount": "1000"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_24_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ankh",
                                        "amount": "850"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "24": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_2",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_3",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_5",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_25_6",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "25": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_2",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_3",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_5",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_26_6",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "26": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_2",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_3",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_5",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_27_6",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "27": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_2",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_3",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_5",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_28_6",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "28": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_2",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_3",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_4",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_5",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_29_6",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "29": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_30_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "30": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_1",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_31_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "31": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_32_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "32": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_33_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "33": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "60"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_4",
                                    "crateAmount": 2,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_34_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "40"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "34": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_35_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "35": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_36_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "36": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_37_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "37": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_38_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "38": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "75"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_4",
                                    "crateAmount": 3,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_39_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "50"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "39": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_40_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "40": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_41_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "41": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_42_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "42": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_43_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "43": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_4",
                                    "crateAmount": 4,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_44_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "65"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "44": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "220"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_4",
                                    "crateAmount": 5,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "145"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_45_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "70"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "45": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "240"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "120"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_4",
                                    "crateAmount": 6,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_46_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "80"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "46": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "260"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "130"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_4",
                                    "crateAmount": 7,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "170"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_47_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "85"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "47": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "280"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "140"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_4",
                                    "crateAmount": 8,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "180"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_48_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "90"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "48": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "300"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "150"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_4",
                                    "crateAmount": 9,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "200"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_49_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "100"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        },
                        "49": {
                            "crates": [
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_1",
                                    "crateAmount": 1,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "500"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_2",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "320"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_3",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "ceremonial_dress",
                                        "amount": "160"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_4",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "330"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_5",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "210"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                },
                                {
                                    "id": "AncientWorld_Egypt_Crate_50_6",
                                    "crateAmount": 10,
                                    "cost": {
                                        "definitionId": "golden_mask",
                                        "amount": "110"
                                    },
                                    "contributionReward": {
                                        "rewards": [
                                            {
                                                "@type": "type.googleapis.com/ResourceRewardDTO",
                                                "baseData": {},
                                                "resource": "event_ranking_points",
                                                "amount": "1",
                                                "fromResourceChange": true
                                            }
                                        ]
                                    },
                                    "contributionRewardIsGrantedToOwner": true
                                }
                            ]
                        }
                    }
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "event_city_progress",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/BoostResourceComponentDTO",
                "id": "Boost1_Wonder_Egypt_GreatSphinx",
                "modifier": 0.01,
                "resourceDefinitionId": "gold_ore",
                "cities": [
                    "City_Egypt"
                ],
                "dynamicModifiedFloatDefinitionId": "Dv_Wonder_EventCity_Egypt_ResourceBoost_1"
            },
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Wonder_Egypt_GreatSphinx",
                "dynamicAmountDefinitionId": "Dv_Wonder_EventCity_Egypt_Worker_Normal"
            }
        ],
        "blueprintMaterialDefinitionId": "Blueprint_Wonder_Egypt_GreatSphinx",
        "firstMaterialDefinitionId": "material_statue",
        "secondMaterialDefinitionId": "material_statue"
    }
]

export default wondersChests;