const abilities = [
    {
        "id": "ability.BenjaminFranklin_9",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_8",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.145,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_7",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.14,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_6",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.135,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_5",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_4",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.125,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_3",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_2",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_1",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.11,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen8_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBotRed_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBotRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.QueenElizabethI_15",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.142,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.142,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.142,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.142,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_16",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.145,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.145,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.145,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.145,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_17",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.148,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.148,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.148,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.148,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_18",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.151,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.151,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.151,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.151,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_19",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.3596,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.6493,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_11",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.13,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_18",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.3257,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.6326,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_12",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.133,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_13",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.136,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_14",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.139,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.139,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.139,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.139,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_19",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.154,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.154,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.154,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.154,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_8",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.02,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_9",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.03,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_4",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.98,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_5",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.99,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen13_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.GeorgeWashington_6",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_7",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.01,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Dracula_15",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.2241,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5828,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_14",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.1903,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5661,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_1",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Dracula_17",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.2919,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.616,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_2",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.96,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Dracula_16",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.258,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5994,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_10",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.127,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_3",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.97,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Dracula_11",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.0887,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5163,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_10",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.0548,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.4996,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_13",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.1564,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5495,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_12",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.1225,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.5329,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_39",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.138,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.138,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.87,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.138,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_38",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.137,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.137,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.855,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.137,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_17",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.09,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_18",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_19",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_13",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.84,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_14",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.86,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_15",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.03,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_16",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_20",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_21",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_31",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.766,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.8488,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_40",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_40",
        "descriptionParameters": {
            "movement_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "accuracy_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.885,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_22",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_30",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.7321,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.8321,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_23",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_37",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.9692,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9485,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_36",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.9353,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9319,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_39",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 4.0369,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9818,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_38",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 4.0031,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9651,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_33",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.8337,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.882,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed9_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Dracula_32",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.7999,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.8654,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_35",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.9015,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9153,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_34",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.8676,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.8986,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TribalHealer_5",
        "descriptionLocaKey": "hero_battle_ability_description.TribalHealer_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.93,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_29",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.6983,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.8155,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TribalHealer_4",
        "descriptionLocaKey": "hero_battle_ability_description.TribalHealer_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.69,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_24",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_25",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_15",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "bleed_dmg_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_20",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.3935,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.6659,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_26",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.5967,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.7656,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_14",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.515,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 7.0722,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TribalHealer_1",
        "descriptionLocaKey": "hero_battle_ability_description.TribalHealer_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_25",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.5628,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.749,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_15",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_15",
        "descriptionParameters": {
            "hp_percentage_friendly": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.53,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 7.1778,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_28",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.6644,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.7989,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_12",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.485,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.8611,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TribalHealer_3",
        "descriptionLocaKey": "hero_battle_ability_description.TribalHealer_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.45,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_27",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.6305,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.7823,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_13",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.9667,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TribalHealer_2",
        "descriptionLocaKey": "hero_battle_ability_description.TribalHealer_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.22,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_22",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.4612,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.6991,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_10",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.455,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_21",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.4273,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.6825,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_11",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.47,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.7556,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_24",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.5289,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.7324,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Dracula_23",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.4951,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.7158,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_17",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.116,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.116,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.54,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.116,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_16",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.525,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_19",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_18",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.117,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.117,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.555,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.117,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JapaneseShogun_2",
        "descriptionLocaKey": "hero_battle_ability_description.JapaneseShogun_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.025,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JapaneseShogun_3",
        "descriptionLocaKey": "hero_battle_ability_description.JapaneseShogun_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.05,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JapaneseShogun_1",
        "descriptionLocaKey": "hero_battle_ability_description.JapaneseShogun_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JapaneseShogun_4",
        "descriptionLocaKey": "hero_battle_ability_description.JapaneseShogun_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.075,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JapaneseShogun_5",
        "descriptionLocaKey": "hero_battle_ability_description.JapaneseShogun_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_24",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.123,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.123,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.645,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.123,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_23",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.122,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.122,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.63,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.122,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_26",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.125,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.125,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.675,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.125,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_25",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.66,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_20",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.119,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.119,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.585,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.119,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Jester_4",
        "descriptionLocaKey": "hero_battle_ability_description.Jester_1",
        "descriptionParameters": {
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.3225,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Jester_5",
        "descriptionLocaKey": "hero_battle_ability_description.Jester_1",
        "descriptionParameters": {
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.33,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_22",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.615,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Jester_2",
        "descriptionLocaKey": "hero_battle_ability_description.Jester_1",
        "descriptionParameters": {
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.3075,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_21",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.6,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Jester_3",
        "descriptionLocaKey": "hero_battle_ability_description.Jester_1",
        "descriptionParameters": {
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.315,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MansaMusa_14",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.91,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_15",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.93,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Jester_1",
        "descriptionLocaKey": "hero_battle_ability_description.Jester_1",
        "descriptionParameters": {
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_1",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_28",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.705,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.127,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_2",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.26,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.94,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_27",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.126,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.126,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.69,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.126,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_3",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.32,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_4",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.22,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_29",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.128,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.128,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.72,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.128,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_5",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.44,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_6",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_7",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.56,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.64,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_8",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.62,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.78,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_9",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.68,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MansaMusa_10",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.83,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_11",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.85,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_12",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.87,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_13",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.89,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_40",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 4.0708,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.9984,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_10",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.78,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_11",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_12",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.82,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue9_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.NapoleonBonaparte_35",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.134,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.134,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.81,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.134,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_34",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.795,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.133,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_37",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.84,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.136,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_36",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.135,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.135,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.825,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.135,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_31",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.75,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_30",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.129,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.129,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.735,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.129,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_33",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.132,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.132,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.78,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.132,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_32",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.131,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.131,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.765,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.131,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_9",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4363,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_8",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4293,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_9",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.65,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_8",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.63,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KarlMarx_15",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.71,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KarlMarx_14",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.695,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossLightningStorm_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossLightningStorm_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.KarlMarx_11",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.65,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KarlMarx_10",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.635,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KarlMarx_13",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.68,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KarlMarx_12",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.665,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_5",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4081,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_7",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.61,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_4",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4011,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_6",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.59,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_7",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4222,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_5",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_6",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4152,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_4",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_1",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_3",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.54,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_2",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.52,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_3",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3941,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_1",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_2",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.387,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Pythagoras_2",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_1",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple2_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Pythagoras_8",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_7",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_9",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.58,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_4",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.29,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_3",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.24,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_6",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.41,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_5",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_12",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.81,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.6286,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_11",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.8,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.5714,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_14",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.83,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.7429,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_13",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.82,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.6857,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_16",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.85,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.8571,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_15",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.84,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_18",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.87,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.9714,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_17",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.86,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.9143,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_19",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.88,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.0286,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_21",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.9,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.1429,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_20",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.89,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.0857,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_23",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.92,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.2571,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_22",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.91,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.EgyptianVizier_5",
        "descriptionLocaKey": "hero_battle_ability_description.EgyptianVizier_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VanHelsing_25",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.94,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.3714,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_24",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.93,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 5.3143,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.EgyptianVizier_2",
        "descriptionLocaKey": "hero_battle_ability_description.EgyptianVizier_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.36,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.EgyptianVizier_1",
        "descriptionLocaKey": "hero_battle_ability_description.EgyptianVizier_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.34,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.EgyptianVizier_4",
        "descriptionLocaKey": "hero_battle_ability_description.EgyptianVizier_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_11",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.157,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.EgyptianVizier_3",
        "descriptionLocaKey": "hero_battle_ability_description.EgyptianVizier_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_10",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.1233,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_13",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.2244,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_12",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.1907,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_15",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.2919,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_14",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.2581,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_37",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.208,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.208,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.208,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.208,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed5_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.QueenElizabethI_38",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.211,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.211,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.211,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.211,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_39",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.214,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.214,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.214,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.214,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_33",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.196,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.196,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.196,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.196,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_34",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.199,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.199,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.199,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.199,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_25",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_35",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.202,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.202,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.202,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.202,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_36",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.205,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.205,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.205,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.205,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_19",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.83,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_1",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.6,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_11",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.68,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_4",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.38,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_12",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.7,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_3",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.32,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_3",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.62,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_13",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.72,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_2",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.26,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue12_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RamsesII_2",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.61,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_14",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.74,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_1",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.2,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_5",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.64,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_15",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.76,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_4",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.63,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_16",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.78,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_7",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.66,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_17",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_6",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.65,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_18",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.81,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_22",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.46,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_30",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.187,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.187,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.187,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.187,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_21",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.4,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_31",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.19,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_24",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.58,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_32",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.193,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.193,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.193,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.193,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_23",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.52,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_9",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.68,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_8",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.62,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_7",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.56,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_20",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.34,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.9,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_6",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.5,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_10",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_1",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.67,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_5",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.44,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_26",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.175,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_3",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.82,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.12,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_27",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.178,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.178,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.178,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.178,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_4",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.83,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.13,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_28",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.181,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.181,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.181,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.181,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_5",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.84,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.14,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_29",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.184,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.184,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.184,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.184,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_6",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.85,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.15,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_22",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.163,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.163,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.163,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.163,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_23",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.166,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.166,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.166,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.166,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_24",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.169,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.169,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.169,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.169,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_1",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.8,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_25",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.172,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.172,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.172,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.172,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_2",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.81,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.11,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_7",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.86,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.16,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_8",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.87,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.17,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_9",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.18,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_22",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 2.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_23",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 3.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_24",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 4.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_25",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 4.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_26",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_27",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_28",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_29",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 8.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_20",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.157,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.157,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.157,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.157,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_21",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_10",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.79,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.5143,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_20",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_21",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_13",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4644,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_33",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 11.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_14",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4715,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_34",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 12.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_11",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4504,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_35",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 12.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_12",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4574,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_36",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 13.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_37",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 14.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_38",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 15.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_15",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4785,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_39",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 16.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OracleOfDephi_10",
        "descriptionLocaKey": "hero_battle_ability_description.OracleOfDephi_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4433,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_30",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 8.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_31",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 9.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hercules_32",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 10.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.DArtagnan_19",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.28,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple13_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.DArtagnan_18",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.22,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow1_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.DArtagnan_15",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.04,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_14",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.98,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_17",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.16,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_16",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.1,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_9",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.68,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_8",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.67,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_8",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1875,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_9",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3371,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_6",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1825,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2857,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_15",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_15",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.5481,
                "type": "percentage"
            },
            "shield_percentage_friendly": {
                "value": 0.2,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Charlemagne_7",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.185,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3029,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_4",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1775,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2514,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_5",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2686,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_2",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1725,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2171,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_3",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2343,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_1",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_40",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.217,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.217,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.217,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.217,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_11",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.8,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_10",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.74,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 9.9,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.DArtagnan_13",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.92,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue5_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.OttoVonBismarck_10",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.0667,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.DArtagnan_12",
        "descriptionLocaKey": "hero_battle_ability_description.Dartagnan",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.86,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_6",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.225,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hercules_40",
        "descriptionLocaKey": "hero_battle_ability_description.Hercules_20",
        "descriptionParameters": {
            "duration": {
                "value": 1.5,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 16.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_11",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.163,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LeifErikson_7",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.228,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_12",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.2593,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LeifErikson_8",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.231,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_13",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.3556,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LeifErikson_9",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.234,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_14",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 6.4519,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LeifErikson_2",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.213,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_3",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.216,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_4",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.219,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_40",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.38,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_5",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.222,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_1",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.21,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_8",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.057,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.405,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.107,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_7",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.056,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.39,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.106,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_9",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.058,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.42,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.108,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_4",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.053,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.345,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.103,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_3",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.052,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.33,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.102,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_6",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.055,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.375,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.105,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_5",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.054,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.36,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.104,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_2",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.051,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.315,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.101,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_1",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.05,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossTeslaCharge_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossTeslaCharge_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Achilles_15",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.07,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5241,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_16",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5401,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_13",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.05,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.4921,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_14",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5081,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_11",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.03,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.4601,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow6_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Achilles_12",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.4761,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_10",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.4441,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_19",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.11,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5882,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_17",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.09,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5562,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_18",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.5722,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue4_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed4_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.WilliamTell_8",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 16.662,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_7",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 16.3889,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_15",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 10.68,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.91,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_9",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_10",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.88,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.835,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_2",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 15.0231,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_1",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 14.75,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_14",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 10.52,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.895,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_4",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 15.5694,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_13",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 10.36,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.88,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_3",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 15.2963,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_12",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 10.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.865,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_6",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 16.1157,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_11",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 10.04,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.85,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_5",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 15.8426,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_3",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.76,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.73,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_2",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.6,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.715,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_1",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.44,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_10",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.78,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_17",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.92,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_18",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.94,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_15",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.88,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_16",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.9,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_13",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.84,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_14",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.86,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_11",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_12",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.82,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_15",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1138,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7621,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_16",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1255,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7701,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_17",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1372,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7781,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_18",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1489,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7861,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_19",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1606,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7941,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed13_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.JohnLocke_9",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.72,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.82,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_10",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0553,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.722,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_8",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.805,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_11",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.067,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.73,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_7",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.4,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.79,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_12",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0787,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7381,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_6",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.24,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.775,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_13",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0904,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7461,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_5",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 9.08,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.76,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_14",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1021,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.7541,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JohnLocke_4",
        "descriptionLocaKey": "hero_battle_ability_description.JohnLocke_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.92,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage_around": {
                "value": 0.745,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_20",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.98,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_21",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_1",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_28",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.14,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_2",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3186,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.45,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_29",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.16,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_3",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3371,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.45,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_26",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow10_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.ThomasAlvaEdison_4",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3557,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_27",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.12,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_5",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3743,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_24",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.06,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_6",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3929,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_25",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.08,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_7",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4114,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_22",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.02,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_8",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.43,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_23",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.04,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_19",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.96,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_31",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_32",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.22,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_1",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.05,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.0,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 7.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_30",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.18,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_39",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.36,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_7",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.5,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.5556,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.1667,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_6",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.425,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.463,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.0306,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingJarl_5",
        "descriptionLocaKey": "hero_battle_ability_description.VikingJarl_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "range": {
                "value": 5.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_37",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.32,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_9",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.65,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.7407,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.4389,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingJarl_4",
        "descriptionLocaKey": "hero_battle_ability_description.VikingJarl_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.475,
                "type": "percentage"
            },
            "range": {
                "value": 5.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_38",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.34,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_8",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.575,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.6481,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.3028,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingJarl_3",
        "descriptionLocaKey": "hero_battle_ability_description.VikingJarl_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.45,
                "type": "percentage"
            },
            "range": {
                "value": 5.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_35",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.28,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_3",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.2,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.1852,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 7.6222,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingJarl_2",
        "descriptionLocaKey": "hero_battle_ability_description.VikingJarl_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.425,
                "type": "percentage"
            },
            "range": {
                "value": 5.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_36",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.3,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_2",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.125,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.0926,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 7.4861,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingJarl_1",
        "descriptionLocaKey": "hero_battle_ability_description.VikingJarl_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4,
                "type": "percentage"
            },
            "range": {
                "value": 5.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_33",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.24,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_5",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.35,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.3704,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 7.8944,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_34",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_20",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.26,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "atk_speed_percentage_buff": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_4",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.275,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.2778,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 7.7583,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_9",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4486,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.49,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple3_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.BenjaminFranklin_21",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_20",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.205,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_25",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_24",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.225,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_23",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_22",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.215,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_9",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_29",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_8",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.91,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_28",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.245,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_7",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.78,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_27",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_6",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_26",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.235,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_5",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_4",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_3",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.26,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_2",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.13,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_1",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 7.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_25",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 18.275,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_17",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_12",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.06,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 17.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_18",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.175,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.49,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_13",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.13,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 17.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_19",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_10",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.94,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_20",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 16.9,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_11",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.0,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 17.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_16",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.32,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 18.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_22",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 17.45,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_10",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_17",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.38,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 18.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_21",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 17.175,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.07,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_11",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_14",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.19,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 17.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_24",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 18.0,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_15",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.26,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 17.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_23",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 17.725,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_10",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.155,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_10",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.975,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.41,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_11",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_18",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.45,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 18.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_12",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.025,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.43,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_19",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.51,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 18.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_13",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.05,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_14",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_14",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.075,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.45,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_13",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_15",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_12",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.165,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_16",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.125,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_11",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_18",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.195,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_17",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen4_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.BenjaminFranklin_16",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.185,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_15",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_19",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_15",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 15.525,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_14",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_14",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 15.2375,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.415,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_15",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_17",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 16.075,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.03,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_12",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_16",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 15.8,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamTell_13",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamTell_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.9352,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_19",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 16.625,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_18",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_16",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 16.35,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_23",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.03,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_24",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.045,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_21",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_22",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.015,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_27",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_11",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 14.375,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_28",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.105,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_10",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 14.0875,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.395,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_25",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_13",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 14.95,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.41,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_26",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.075,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_12",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 14.6625,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.405,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_20",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.225,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.51,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_20",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.175,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LouisXIV_21",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_21",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LouisXIV_22",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.275,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.53,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_29",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_22",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_23",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.3,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.54,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_40",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.305,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_24",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.325,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.55,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_24",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.195,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LouisXIV_25",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 2.35,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_25",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_22",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.185,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_23",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_20",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1723,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8021,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_21",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.184,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8101,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_22",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.1957,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8181,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_23",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.2074,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8261,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_20",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.58,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 18.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_24",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.2191,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8341,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_25",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.2308,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.8421,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_34",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.195,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.075,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_35",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_32",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.165,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.045,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_33",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_38",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.255,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.135,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_39",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_36",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.225,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.105,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_37",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_32",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.265,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_31",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_30",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.255,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_36",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.285,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_35",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_34",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.275,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_33",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_1",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.2,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_39",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_3",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.3926,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_38",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.295,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_2",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.2963,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.BenjaminFranklin_37",
        "descriptionLocaKey": "hero_battle_ability_description.BenjaminFranklin_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "block_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_30",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.135,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.015,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_31",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.03,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_40",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.8033,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.22,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_9",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.9704,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_8",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.8741,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_5",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.5852,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_4",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.4889,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_7",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.7778,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.OttoVonBismarck_6",
        "descriptionLocaKey": "hero_battle_ability_description.OttoVonBismarck_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 5.6815,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 4.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_18",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_19",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_16",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_17",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_14",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.196,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_15",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_12",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.192,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_13",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.194,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_10",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.188,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_11",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.19,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_40",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 6.64,
                "type": "percentage"
            },
            "buff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 19.0,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.285,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.165,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_17",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_18",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.165,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_15",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_16",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.155,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_19",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_10",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_10",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.74,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_13",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_14",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_12",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.86,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_11",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_11",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_12",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_8",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.42,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_14",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.98,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.62,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_9",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.43,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_13",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.92,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_16",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.9,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_15",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.04,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.76,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_18",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.22,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_17",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.16,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_19",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.28,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_25",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.34,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_23",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.32,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_24",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_21",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_22",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.31,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Plato_20",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.198,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "dmg_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Achilles_26",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7002,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_1",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_27",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7163,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_2",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.36,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_24",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6682,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_3",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.37,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_25",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6842,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_4",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_21",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_22",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.14,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6362,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_5",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.39,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_20",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.34,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_23",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6522,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_6",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.4,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_23",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.52,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.88,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_20",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6042,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_7",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.41,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_22",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.46,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_21",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.6202,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_25",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.64,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 13.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AttilaTheHun_24",
        "descriptionLocaKey": "hero_battle_ability_description.AttilaTheHun_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.58,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 13.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_28",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7323,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_29",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_10",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7483,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_30",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7533,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_37",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7883,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_38",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7933,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_35",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7783,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_36",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7833,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.14,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_33",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7683,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_34",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7733,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_31",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7583,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_32",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7633,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_39",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_30",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 1.7983,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow14_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple7_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.LeonardoDaVinci_32",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2073,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.76,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1382,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_33",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2091,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.79,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1394,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_30",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2036,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.71,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1357,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_31",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2054,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.74,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1369,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_36",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2147,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.86,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1431,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_37",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2165,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.89,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1443,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_34",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.211,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.81,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1406,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_35",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2128,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.84,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1419,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_38",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2183,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.91,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1456,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_39",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2202,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.94,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1468,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_40",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.95,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 14.43,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_37",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.92,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 14.07,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_36",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.92,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_39",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.94,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 14.31,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_38",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.93,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 14.19,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_40",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.222,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.96,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.148,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_10",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1666,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.22,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1111,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_11",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1685,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.25,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1123,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_31",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.88,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.35,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_30",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.87,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.23,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_33",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.89,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.59,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_14",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.174,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.32,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.116,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_32",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.88,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.47,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_15",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1759,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.34,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1172,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_35",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.91,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.83,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_12",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1703,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.27,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1135,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_34",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.9,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.71,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_13",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1722,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.3,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1148,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_18",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1814,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.42,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1209,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_19",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1833,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1222,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_16",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1777,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.37,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1185,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_17",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1796,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.39,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1197,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_5",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.4,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_4",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.3,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_7",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.6,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_6",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_1",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_3",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.2,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_2",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.1,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_26",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.84,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.75,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_25",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.83,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.63,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_28",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.85,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.99,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_27",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.84,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.87,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_9",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.8,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_29",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.86,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 13.11,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_8",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.7,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_21",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1869,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.49,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1246,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_22",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1888,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.52,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1259,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_20",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.79,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.03,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_20",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1851,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.47,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1234,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_22",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.81,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.27,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_25",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1943,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.59,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1296,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_21",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.8,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_26",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1962,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.62,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1308,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_24",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.82,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.51,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_23",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1906,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.54,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1271,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_23",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.81,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 12.39,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_24",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1925,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.57,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1283,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_29",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.2017,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.69,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1345,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_27",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.198,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.64,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.132,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_28",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1999,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.67,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1333,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_15",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.75,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.43,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_14",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.74,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.31,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_17",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.77,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.67,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_16",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.76,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.55,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_19",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.78,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.91,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_18",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.77,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.79,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_11",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.72,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_10",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.71,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.83,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_13",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.73,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.19,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_12",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.73,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 11.07,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_17",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.76,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_18",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.77,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_15",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.74,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_16",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.75,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_13",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.72,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_14",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.73,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_11",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.7,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_12",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.71,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_10",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.69,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_19",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.78,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_28",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.87,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_29",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_26",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.85,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_27",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.86,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_24",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.83,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_25",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.84,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_22",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.81,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_23",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.82,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_20",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.79,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_21",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_14",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.415,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_13",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_15",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_10",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.085,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_12",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.245,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_11",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_10",
        "descriptionParameters": {
            "duration": {
                "value": 2.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.16,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_39",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.98,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_1",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 4.5,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_37",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.96,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_38",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.97,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_35",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.94,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_36",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.95,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_33",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.92,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_34",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.93,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_31",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.9,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_32",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.91,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RamsesII_30",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.89,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_8",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.76,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_9",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.94,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_6",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.4,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_7",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.58,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_4",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.04,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_5",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 5.22,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_2",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 4.68,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_3",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 4.86,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen5_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.MayanAstronomer_1",
        "descriptionLocaKey": "hero_battle_ability_description.MayanAstronomer_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MayanAstronomer_2",
        "descriptionLocaKey": "hero_battle_ability_description.MayanAstronomer_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.845,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RamsesII_40",
        "descriptionLocaKey": "hero_battle_ability_description.RamsesII_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.99,
                "type": "percentage"
            },
            "move_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "crit_dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MayanAstronomer_5",
        "descriptionLocaKey": "hero_battle_ability_description.MayanAstronomer_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MayanAstronomer_3",
        "descriptionLocaKey": "hero_battle_ability_description.MayanAstronomer_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.89,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MayanAstronomer_4",
        "descriptionLocaKey": "hero_battle_ability_description.MayanAstronomer_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.935,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_10",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.44,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_16",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_15",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.01,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_18",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_17",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.03,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_12",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.46,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_11",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.45,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_14",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.48,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_13",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.47,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow2_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.MargaretIOfDenmark_21",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.07,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_20",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_23",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_22",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_25",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_24",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MargaretIOfDenmark_19",
        "descriptionLocaKey": "hero_battle_ability_description.MargaretIOfDenmark_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.49,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue8_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple1_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen12_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Pythagoras_10",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.64,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_11",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_14",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.87,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_15",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 4.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_12",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.76,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Pythagoras_13",
        "descriptionLocaKey": "hero_battle_ability_description.Pythagoras_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 3.81,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KingMinos_1",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 9.5,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_2",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 9.7,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_3",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 9.9,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_8",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 10.9,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.KingMinos_9",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_9",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 11.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Lagertha_9",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.7882,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1205,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_9",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.36,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Lagertha_7",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.5911,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0954,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_8",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.34,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KingMinos_4",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 10.1,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Lagertha_8",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.6897,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1079,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_7",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KingMinos_5",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 10.3,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Lagertha_5",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.3941,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0702,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_6",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KingMinos_6",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 10.5,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossTeslaImmunity_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossTeslaImmunity_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Lagertha_6",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.4926,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0828,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_5",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.28,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KingMinos_7",
        "descriptionLocaKey": "hero_battle_ability_description.KingMinos_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 10.7,
                "type": "percentage"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Lagertha_3",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.197,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0451,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_4",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.26,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Lagertha_4",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.2956,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0577,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_3",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.24,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Lagertha_1",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.0,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_2",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.22,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Lagertha_2",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.0985,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.0326,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_1",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Inanna_7",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.82,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen9_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Inanna_6",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_9",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 12.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_8",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.99,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue11_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed6_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Inanna_3",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.14,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_2",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.97,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_5",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_4",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.31,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Inanna_1",
        "descriptionLocaKey": "hero_battle_ability_description.Inanna_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_11",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.24,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_12",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.243,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_10",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.237,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_19",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.264,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_17",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.258,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_18",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.261,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_15",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.252,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_32",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.093,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_16",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.255,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_31",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0915,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_13",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.246,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_30",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.09,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_14",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.249,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_25",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0825,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_24",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.081,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_23",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0795,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_22",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.078,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_29",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0885,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_28",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.087,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_27",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0855,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_26",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.084,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_25",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.82,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.11,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_24",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.64,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.11,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_10",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.08,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_21",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.1,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_11",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.26,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_20",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.92,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_12",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.44,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_23",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.46,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.11,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_13",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.62,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_22",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 8.28,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_14",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.8,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeifErikson_22",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.273,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_23",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.276,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple12_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.LeifErikson_20",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.267,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_21",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.27,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_24",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.279,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeifErikson_25",
        "descriptionLocaKey": "hero_battle_ability_description.LeifErikson_1",
        "descriptionParameters": {
            "defense_debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "defense_debuff": {
                "value": 0.282,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_40",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.105,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_36",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.099,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_35",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0975,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_34",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.096,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_33",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0945,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_39",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.1035,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_38",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.102,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_37",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_25",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.1005,
                "type": "percentage"
            },
            "evasion_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple6_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AbrahamLincoln_10",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_9",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.54,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.74,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_8",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.54,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.71,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_7",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.53,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.68,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_6",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.53,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.65,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_5",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.52,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.62,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_4",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.52,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.59,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_3",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.51,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_2",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.51,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.53,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow7_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.HuaMulan_1",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_21",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0765,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_20",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.075,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_14",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.066,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_13",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0645,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_12",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.063,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_11",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0615,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_18",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.072,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_17",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0705,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_16",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.069,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_15",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0675,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AbrahamLincoln_19",
        "descriptionLocaKey": "hero_battle_ability_description.AbrahamLincoln_10",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "buff_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.0735,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue3_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed1_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow11_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.JoanOfArc_10",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4996,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.11,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_12",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5329,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.14,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_11",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5163,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_14",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_13",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5495,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_16",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.05,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_15",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.03,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_9",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_18",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_17",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_19",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.13,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Geisha_3",
        "descriptionLocaKey": "hero_battle_ability_description.Geisha_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "poison_dmg_percentage": {
                "value": 2.96,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.75,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_5",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Geisha_2",
        "descriptionLocaKey": "hero_battle_ability_description.Geisha_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "poison_dmg_percentage": {
                "value": 2.88,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.625,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_6",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.25,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Geisha_5",
        "descriptionLocaKey": "hero_battle_ability_description.Geisha_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "poison_dmg_percentage": {
                "value": 3.12,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_7",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Geisha_4",
        "descriptionLocaKey": "hero_battle_ability_description.Geisha_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "poison_dmg_percentage": {
                "value": 3.04,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.875,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_8",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.55,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Geisha_1",
        "descriptionLocaKey": "hero_battle_ability_description.Geisha_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "poison_dmg_percentage": {
                "value": 2.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_1",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_21",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_2",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_20",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_3",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_23",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_4",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.95,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_22",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_25",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_24",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_27",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_26",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_29",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_28",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_30",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.005,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_32",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.015,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_31",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.01,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_34",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.025,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_33",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_36",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.035,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_35",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.03,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue15_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.JoanOfArc_38",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.045,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_37",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_39",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.05,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MaoriChief_4",
        "descriptionLocaKey": "hero_battle_ability_description.MaoriChief_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 1.2458,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MaoriChief_5",
        "descriptionLocaKey": "hero_battle_ability_description.MaoriChief_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 1.2778,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed12_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.VincentVanGogh_18",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.56,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MaoriChief_2",
        "descriptionLocaKey": "hero_battle_ability_description.MaoriChief_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 1.1819,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_17",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.38,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MaoriChief_3",
        "descriptionLocaKey": "hero_battle_ability_description.MaoriChief_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 1.2139,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_19",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.74,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MaoriChief_1",
        "descriptionLocaKey": "hero_battle_ability_description.MaoriChief_1",
        "descriptionParameters": {
            "shield_percentage": {
                "value": 1.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_40",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_30",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5661,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "atkspeed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 1.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_14",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 6.84,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_13",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 6.66,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_16",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.2,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_15",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 7.02,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_10",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 6.12,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_12",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 6.48,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VincentVanGogh_11",
        "descriptionLocaKey": "hero_battle_ability_description.VincentVanGogh_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 6.3,
                "type": "percentage"
            },
            "defense_debuff": {
                "value": -0.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_5",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4165,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_4",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3999,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_3",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3833,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_2",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3666,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.01,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_9",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.483,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_8",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4664,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.09,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_7",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4498,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.07,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_6",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4331,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JoanOfArc_1",
        "descriptionLocaKey": "hero_battle_ability_description.JoanOfArc_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.35,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "hp_percentage": {
                "value": 1.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_13",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.062,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.48,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.112,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_12",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.061,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.465,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.111,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_15",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_15",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.064,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.114,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.51,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.114,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_14",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.063,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.495,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.113,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_11",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.45,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.11,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.NapoleonBonaparte_10",
        "descriptionLocaKey": "hero_battle_ability_description.NapoleonBonaparte_1",
        "descriptionParameters": {
            "accuracy_percentage": {
                "value": 0.059,
                "type": "percentage"
            },
            "debuff_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.435,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.109,
                "type": "percentage"
            },
            "evasion_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossMechSweepStrong_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossMechSweep_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossLightningStormVeryFast_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossLightningStorm_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Hatshepsut_22",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.484,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_23",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.618,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_24",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.752,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_25",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.886,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_20",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.216,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_21",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow15_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.LeonardoDaVinci_2",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1518,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.02,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1012,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_1",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SunTzu_3",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.64,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_2",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.62,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_1",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.6,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_7",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.72,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_6",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.7,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_5",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.68,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_4",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.66,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBotBlue_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBotBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AdaLovelace_15",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 1.98,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_9",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.76,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SunTzu_8",
        "descriptionLocaKey": "hero_battle_ability_description.SunTzu_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.74,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_6",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1592,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.12,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1062,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_5",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1574,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.1,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1049,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_4",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1555,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.07,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1037,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_3",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1537,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.05,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1025,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen1_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.LeonardoDaVinci_9",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1648,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.2,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1099,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_8",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1629,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.17,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1086,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LeonardoDaVinci_7",
        "descriptionLocaKey": "hero_battle_ability_description.LeonardoDaVinci_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_buff": {
                "value": 0.1611,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.15,
                "type": "percentage"
            },
            "attack_speed_buff": {
                "value": 0.1074,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBotGreen_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBotGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.ThomasAlvaEdison_25",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.7457,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.59,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_40",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_36",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "duration_debuff": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 2.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_21",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.6714,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.57,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_22",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.69,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.57,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_23",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.7086,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.58,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_24",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.7271,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.58,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_20",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.6529,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_12",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3843,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_11",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.363,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_10",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3417,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_15",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_14",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4269,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_13",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4056,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarieCurie_11",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.12,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_10",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_13",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.17,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_12",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.14,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_18",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.6157,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.55,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_15",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.21,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_19",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.6343,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.55,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_14",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.54,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_17",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.26,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_16",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.23,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.58,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_14",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5414,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_19",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.64,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_15",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.53,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_18",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.28,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.62,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_16",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5786,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.53,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_17",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5971,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.54,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_10",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4671,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_11",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4857,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_12",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5043,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.51,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ThomasAlvaEdison_13",
        "descriptionLocaKey": "hero_battle_ability_description.ThomasAlvaEdison_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.5229,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "attack_speed_buff": {
                "value": 0.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_10",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_20",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.66,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_22",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.37,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_17",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_21",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.35,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.68,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_18",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_24",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.41,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_15",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_23",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.39,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.72,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_16",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_26",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.46,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.78,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_13",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_25",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.44,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.76,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_14",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_28",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.82,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_11",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MarieCurie_27",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.48,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_12",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_3",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.35,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 5.9111,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_20",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.36,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_29",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.53,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.84,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_4",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.365,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.0167,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_5",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.38,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.1222,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_6",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.395,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.2278,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_23",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.4,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_7",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.41,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.3333,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_24",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.41,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.5,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_8",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.425,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.4389,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_21",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.37,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.2,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_9",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.44,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 6.5444,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_22",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.38,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.3,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_27",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.45,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.8,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_28",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.47,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.9,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_25",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.43,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_26",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.7,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_29",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.48,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.0,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_1",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 5.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HermannTheCheruscan_2",
        "descriptionLocaKey": "hero_battle_ability_description.HermannTheCheruscan_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.335,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "hp_percentage": {
                "value": 5.8056,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_11",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.01,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_12",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.144,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_13",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.278,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_14",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.412,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_31",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.88,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_10",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.876,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_30",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.55,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.86,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_19",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 7.082,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_33",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.62,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_32",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.59,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.9,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_35",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.66,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.96,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_34",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.64,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.94,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_15",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.546,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_37",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.71,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 2.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_16",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.68,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_36",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.68,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.98,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_17",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.814,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_39",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.75,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 2.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_18",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 6.948,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_38",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.73,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 2.02,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_30",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.8879,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4929,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.3931,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_31",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.51,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.2,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_34",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.55,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.5,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_35",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_32",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.52,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.3,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_33",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.53,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.4,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_38",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_36",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "duration_debuff": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 2.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_39",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_36",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "duration_debuff": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 2.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_36",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_36",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "duration_debuff": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_37",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_36",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "duration_debuff": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.57,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 2.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 2.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_40",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 5.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.77,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 2.06,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_8",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2687,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1557,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_9",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.275,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1557,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_6",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2625,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1448,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_7",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2687,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1448,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_4",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2562,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1339,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_5",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2625,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1339,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_2",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.123,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_3",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2562,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.123,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_1",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_22",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3125,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2321,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_12",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.25,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.3,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_23",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3187,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2321,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_13",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.26,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.4,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_20",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3062,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2212,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_10",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.22,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_21",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3125,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2212,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_11",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.24,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.2,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_16",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.7,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_17",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.8,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_14",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.28,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.5,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_15",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.29,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.6,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_18",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.33,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 0.9,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_19",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_10",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.89,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.34,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "shield_percentage_friendly": {
                "value": 1.0,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingRaider_1",
        "descriptionLocaKey": "hero_battle_ability_description.VikingRaider_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen6_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Confucius_24",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3187,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.243,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_25",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_15",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3077,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.243,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingRaider_5",
        "descriptionLocaKey": "hero_battle_ability_description.VikingRaider_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.67,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingRaider_4",
        "descriptionLocaKey": "hero_battle_ability_description.VikingRaider_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingRaider_3",
        "descriptionLocaKey": "hero_battle_ability_description.VikingRaider_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.33,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VikingRaider_2",
        "descriptionLocaKey": "hero_battle_ability_description.VikingRaider_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.17,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.LouisXIV_3",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_18",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2125,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4914,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_9",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.76,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_2",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.775,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.33,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_17",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4743,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_8",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.74,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_5",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_16",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2075,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4571,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_7",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.72,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_4",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.825,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_15",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.205,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.44,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_6",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.7,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_7",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.9,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_11",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2812,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1666,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_14",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2025,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4229,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_9",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.0896,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_6",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.875,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.37,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_12",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2812,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1775,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_13",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.4057,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_9",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_12",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1975,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3886,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_7",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.0222,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LouisXIV_8",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.925,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_10",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.275,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1666,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_11",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.195,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3714,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_8",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.0559,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_10",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1925,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3543,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_1",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.6,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_5",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.68,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_4",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.66,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_3",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.64,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.LudwigVanBeethoven_2",
        "descriptionLocaKey": "hero_battle_ability_description.LudwigVanBeethoven_1",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.25,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.62,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SirLancelot_9",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.87,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_15",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2937,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1884,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_8",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.81,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_16",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2937,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1994,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_7",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.74,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_13",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2875,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1775,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_6",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.68,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 16.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_14",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.2875,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1884,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_5",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.62,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 15.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_19",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3062,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2103,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_4",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.55,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 15.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_3",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.49,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 15.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_17",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.1994,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_2",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.42,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 15.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Confucius_18",
        "descriptionLocaKey": "hero_battle_ability_description.Confucius_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2103,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SirLancelot_1",
        "descriptionLocaKey": "hero_battle_ability_description.SirLancelot_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "dmg_percentage_bleed": {
                "value": 5.36,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 15.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_19",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.215,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5086,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen15_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.LouisXIV_1",
        "descriptionLocaKey": "hero_battle_ability_description.LouisXIV_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 1.75,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue10_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed7_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Leonidas_1",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_3",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.244,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_10",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 1.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Leonidas_2",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.222,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_5",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.288,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_4",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.266,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_13",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 1.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_14",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 2.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_11",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 1.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_12",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 1.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossThunderStrike_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossThunderStrike_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AshokaTheGreat_15",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 2.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_5",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.9548,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_6",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.9885,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_3",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.8874,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_4",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.9211,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_1",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.82,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MarcoPolo_2",
        "descriptionLocaKey": "hero_battle_ability_description.MarcoPolo_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.8537,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_7",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.9532,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.4498,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_4",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.19,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.545,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_8",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.9871,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.4664,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_3",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.53,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_5",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.8855,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.4165,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_2",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.17,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.515,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_6",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.9193,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.4331,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_1",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_3",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.8177,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.3833,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_8",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.605,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_4",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.8516,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.3999,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_7",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.59,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_1",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.75,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_6",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.575,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Dracula_2",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 2.7839,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.3666,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.KarlMarx_5",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.KarlMarx_9",
        "descriptionLocaKey": "hero_battle_ability_description.KarlMarx_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.62,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossThunderStrikeStrongFront_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossThunderStrike_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Dracula_9",
        "descriptionLocaKey": "hero_battle_ability_description.Dracula_1",
        "descriptionParameters": {
            "heal_per_unit": {
                "value": 3.0209,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 1.483,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple15_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossMinionHealBomb_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossMinionHealBomb_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.MiyamotoMusashi_2",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 32.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_3",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 32.98,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_4",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 33.22,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_5",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 33.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_1",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 32.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow3_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.MiyamotoMusashi_6",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 33.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_7",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 33.94,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_8",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 34.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_9",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 34.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue7_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AlbertEinstein_40",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.2532,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_3",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.17,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.0,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_4",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.26,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.19,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_1",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.0,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 14.64,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_2",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.09,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 14.82,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_9",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_8",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.41,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_7",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_6",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_5",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_9",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.69,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_4",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.29,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_3",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.26,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanCenturion_5",
        "descriptionLocaKey": "hero_battle_ability_description.RomanCenturion_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.7778,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 5.5556,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_7",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.52,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.76,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_2",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.23,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_8",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.6,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.95,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_1",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_5",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.34,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.37,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_6",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.43,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 15.56,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_1",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_3",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.6115,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_2",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.5554,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_5",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.7258,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_4",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.6683,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_7",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.8429,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_6",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.784,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_9",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.9629,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_8",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 4.9026,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.QinShiHuang_7",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0202,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.698,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_6",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0085,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.69,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_5",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.9968,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.682,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_4",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.9851,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.674,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_9",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0436,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.714,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_8",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 1.0319,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.706,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Leonidas_7",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.332,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_6",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.31,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_9",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.376,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_8",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.354,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen11_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.QinShiHuang_3",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.9734,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.666,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanCenturion_4",
        "descriptionLocaKey": "hero_battle_ability_description.RomanCenturion_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.5833,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 5.4167,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_2",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.9617,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.658,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanCenturion_3",
        "descriptionLocaKey": "hero_battle_ability_description.RomanCenturion_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.3889,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 5.2778,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QinShiHuang_1",
        "descriptionLocaKey": "hero_battle_ability_description.QinShiHuang_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 0.95,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 0.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanCenturion_2",
        "descriptionLocaKey": "hero_battle_ability_description.RomanCenturion_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.1944,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 5.1389,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanCenturion_1",
        "descriptionLocaKey": "hero_battle_ability_description.RomanCenturion_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.0,
                "type": "percentage"
            },
            "hp_percentage": {
                "value": 5.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Shaolin_4",
        "descriptionLocaKey": "hero_battle_ability_description.Shaolin_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Shaolin_3",
        "descriptionLocaKey": "hero_battle_ability_description.Shaolin_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Shaolin_2",
        "descriptionLocaKey": "hero_battle_ability_description.Shaolin_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Shaolin_1",
        "descriptionLocaKey": "hero_battle_ability_description.Shaolin_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_20",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_21",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_21",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            },
            "stun_chance": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_22",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_21",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            },
            "stun_chance": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_23",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_21",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            },
            "stun_chance": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_24",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_21",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            },
            "stun_chance": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_25",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_21",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            },
            "stun_chance": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue14_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TomoeGozen_10",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_11",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_12",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_13",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_14",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_15",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_16",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.7,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_17",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_18",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 0.9,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_19",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 6.0,
                "type": "duration"
            },
            "bleed_percentage": {
                "value": 1.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "bleed_chance": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed11_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossThunderStrikeStrong_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossThunderStrike_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple11_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AztecTlacateccatl_3",
        "descriptionLocaKey": "hero_battle_ability_description.AztecTlacateccatl_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.72,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AztecTlacateccatl_4",
        "descriptionLocaKey": "hero_battle_ability_description.AztecTlacateccatl_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.83,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.NubianMercenary_4",
        "descriptionLocaKey": "hero_battle_ability_description.NubianMercenary_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.8333,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AztecTlacateccatl_1",
        "descriptionLocaKey": "hero_battle_ability_description.AztecTlacateccatl_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.5,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.NubianMercenary_5",
        "descriptionLocaKey": "hero_battle_ability_description.NubianMercenary_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 11.1111,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AztecTlacateccatl_2",
        "descriptionLocaKey": "hero_battle_ability_description.AztecTlacateccatl_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 4.61,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.NubianMercenary_2",
        "descriptionLocaKey": "hero_battle_ability_description.NubianMercenary_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.2778,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.NubianMercenary_3",
        "descriptionLocaKey": "hero_battle_ability_description.NubianMercenary_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.5556,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AztecTlacateccatl_5",
        "descriptionLocaKey": "hero_battle_ability_description.AztecTlacateccatl_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 5.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.NubianMercenary_1",
        "descriptionLocaKey": "hero_battle_ability_description.NubianMercenary_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 10.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RomanPraetor_1",
        "descriptionLocaKey": "hero_battle_ability_description.RomanPraetor_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.5,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RomanPraetor_2",
        "descriptionLocaKey": "hero_battle_ability_description.RomanPraetor_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.525,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple5_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RomanPraetor_5",
        "descriptionLocaKey": "hero_battle_ability_description.RomanPraetor_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.6,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RomanPraetor_3",
        "descriptionLocaKey": "hero_battle_ability_description.RomanPraetor_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.55,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RomanPraetor_4",
        "descriptionLocaKey": "hero_battle_ability_description.RomanPraetor_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.575,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Shaolin_5",
        "descriptionLocaKey": "hero_battle_ability_description.Shaolin_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GreekMathematician_4",
        "descriptionLocaKey": "hero_battle_ability_description.GreekMathematician_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.81,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.GreekMathematician_5",
        "descriptionLocaKey": "hero_battle_ability_description.GreekMathematician_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.83,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.GreekMathematician_2",
        "descriptionLocaKey": "hero_battle_ability_description.GreekMathematician_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.77,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.GreekMathematician_3",
        "descriptionLocaKey": "hero_battle_ability_description.GreekMathematician_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.79,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.GreekMathematician_1",
        "descriptionLocaKey": "hero_battle_ability_description.GreekMathematician_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.75,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Achilles_4",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.348,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.525,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_5",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.364,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_2",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.316,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.275,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_3",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.332,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_8",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.4121,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.025,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_9",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.4281,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 11.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_6",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.38,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.775,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_7",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.3961,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.9,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Achilles_1",
        "descriptionLocaKey": "hero_battle_ability_description.Achilles_1",
        "descriptionParameters": {
            "atk_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 10.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow4_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue2_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed2_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RagnarLodbrok_9",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.7,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.71,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_7",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.69,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.47,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_8",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.7,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.59,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_5",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.67,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.23,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_6",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.68,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.35,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_3",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.66,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 9.99,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_4",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.66,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 10.11,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_1",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.64,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 9.75,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RagnarLodbrok_2",
        "descriptionLocaKey": "hero_battle_ability_description.RagnarLodbrok_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "burn_dmg": {
                "value": 0.65,
                "type": "percentage"
            },
            "shield_percentage": {
                "value": 9.87,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JaguarWarrior_3",
        "descriptionLocaKey": "hero_battle_ability_description.JaguarWarrior_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JaguarWarrior_4",
        "descriptionLocaKey": "hero_battle_ability_description.JaguarWarrior_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.975,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_29",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.64,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.34,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaguarWarrior_5",
        "descriptionLocaKey": "hero_battle_ability_description.JaguarWarrior_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.0,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_28",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.64,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.31,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_27",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.63,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.28,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_11",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_26",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.63,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_10",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_25",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.62,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.22,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaguarWarrior_1",
        "descriptionLocaKey": "hero_battle_ability_description.JaguarWarrior_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.9,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_24",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.62,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.19,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaguarWarrior_2",
        "descriptionLocaKey": "hero_battle_ability_description.JaguarWarrior_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 0.925,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_34",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.67,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.49,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_15",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.62,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_33",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.66,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.46,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_14",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.59,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_32",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.66,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.43,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_13",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_31",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.65,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.4,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_12",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.53,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_30",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.65,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.37,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_19",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_18",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.71,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_17",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.68,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_16",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Charlemagne_25",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.23,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.6114,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_24",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2275,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5943,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_23",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.225,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5771,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_22",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2225,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.56,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_21",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5429,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Charlemagne_20",
        "descriptionLocaKey": "hero_battle_ability_description.Charlemagne_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.2175,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.5257,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_39",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.69,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.64,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_38",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.69,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.61,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_22",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.83,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_37",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.68,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.58,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_21",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_36",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.675,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.55,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_20",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.77,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_35",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.67,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.52,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JaneAusten_25",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_24",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.89,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JaneAusten_23",
        "descriptionLocaKey": "hero_battle_ability_description.JaneAusten_5",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 1.86,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.HuaMulan_40",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.7,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.67,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed15_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Medusa_39",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 5.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_38",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.96,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_37",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.88,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_36",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_35",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.72,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_34",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.64,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_33",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_32",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_31",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_30",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_10",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_11",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 2.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_12",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 3.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_13",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 4.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Medusa_40",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 5.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow12_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple9_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RobinHood_25",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 19.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_26",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 20.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_27",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 21.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_28",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 22.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Medusa_19",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_29",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 24.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Medusa_18",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_17",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.28,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_16",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_15",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_14",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.04,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_13",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.96,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_12",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.88,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_11",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_10",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.72,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_30",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 25.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_31",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 26.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_32",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 27.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_33",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 28.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_34",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 30.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_35",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 31.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_14",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 6.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_15",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 7.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_16",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 8.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_17",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 9.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_8",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 0.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_18",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 10.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Medusa_29",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.24,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_9",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 1.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.RobinHood_19",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 12.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Medusa_28",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_27",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_26",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 4.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_4",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_25",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_5",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 0.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_24",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.84,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_6",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_23",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.76,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow8_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AshokaTheGreat_7",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_5",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.4,
                "type": "percentage"
            },
            "heal_percentage_2": {
                "value": 0.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_22",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.68,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_21",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_1",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 10.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_20",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_15",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 3.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_2",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AshokaTheGreat_3",
        "descriptionLocaKey": "hero_battle_ability_description.AshokaTheGreat_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 11.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen2_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RobinHood_20",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 13.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_21",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 14.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_22",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 15.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_23",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 16.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_24",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 18.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_12",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.56,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.83,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_11",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.55,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_10",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.55,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.77,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBotYellow_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBotYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.RobinHood_36",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 32.4,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_37",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 33.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_38",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 34.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.RobinHood_39",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 36.0,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MansaMusa_2",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.67,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_1",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.65,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_4",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.71,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_3",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.69,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.HuaMulan_19",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.59,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.04,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_18",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.59,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.01,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_17",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.58,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.98,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_16",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.58,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.95,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_15",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.57,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.92,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_14",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.57,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.89,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_13",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.56,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.86,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_23",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.61,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.16,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_22",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.61,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.13,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_21",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.HuaMulan_20",
        "descriptionLocaKey": "hero_battle_ability_description.HuaMulan_1",
        "descriptionParameters": {
            "dmg_percentage_burn": {
                "value": 0.6,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.07,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.15,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MansaMusa_6",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.75,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_5",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.73,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.RobinHood_40",
        "descriptionLocaKey": "hero_battle_ability_description.RobinHood_10",
        "descriptionParameters": {
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "chance": {
                "value": 0.5,
                "type": "percentage"
            },
            "bleed_percentage": {
                "value": 37.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.78,
                "type": "percentage"
            },
            "burn_percentage": {
                "value": 16.34,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.MansaMusa_8",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.79,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_7",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.77,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.MansaMusa_9",
        "descriptionLocaKey": "hero_battle_ability_description.MansaMusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.81,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen14_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AdaLovelace_2",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.85,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_3",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.9,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_1",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.8,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_8",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.72,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_9",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.9,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_6",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.36,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_7",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.54,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_4",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.95,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.AdaLovelace_5",
        "descriptionLocaKey": "hero_battle_ability_description.AdaLovelace_5",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.18,
                "type": "percentage"
            },
            "chance_percentage": {
                "value": 0.33,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Lagertha_40",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.8424,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.5099,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_9",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.64,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_8",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_5",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_4",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.24,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_7",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_6",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_1",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_3",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Medusa_2",
        "descriptionLocaKey": "hero_battle_ability_description.Medusa_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "stun_duration": {
                "value": 2.5,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 2.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_32",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.0542,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4094,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_33",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.1527,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.422,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_34",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.2512,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4345,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_35",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.3498,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4471,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_30",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.8571,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3843,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_31",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.9557,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3968,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossMechSweep_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossMechSweep_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen7_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Lagertha_36",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.4483,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4597,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_37",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.5468,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4722,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_38",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.6453,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4848,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_39",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 11.7438,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.4973,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_1",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.17,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_2",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.172,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossThunderStrikeFast_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossThunderStrike_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Cleopatra_30",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_31",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_10",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.02,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Cleopatra_32",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_11",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.04,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Cleopatra_33",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_14",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 35.62,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.125,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_34",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_13",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 35.38,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_35",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_36",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_15",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 35.86,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.15,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_3",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.174,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_37",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_10",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 34.66,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.025,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_4",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.176,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_38",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.48,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_5",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.178,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_39",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_12",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 35.14,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.075,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_6",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MiyamotoMusashi_11",
        "descriptionLocaKey": "hero_battle_ability_description.MiyamotoMusashi_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 34.9,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.05,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_7",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.182,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_8",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.184,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_9",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.08,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.44,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Plato_9",
        "descriptionLocaKey": "hero_battle_ability_description.Plato_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.186,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_6",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.01,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_5",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.99,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_8",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.05,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_7",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 1.03,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_2",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.92,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_1",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.9,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.28,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_4",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.96,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.MarieCurie_3",
        "descriptionLocaKey": "hero_battle_ability_description.MarieCurie_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "no_vials": {
                "value": 4.0,
                "type": "number"
            },
            "dmg_percentage": {
                "value": 0.94,
                "type": "percentage"
            },
            "range": {
                "value": 1.0,
                "type": "number"
            },
            "debuff_percentage": {
                "value": 1.32,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_3",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 4.938,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_16",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.14,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_2",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 4.804,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_17",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.16,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_1",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 4.67,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_18",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.18,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_19",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_12",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.06,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_13",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.08,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_14",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_15",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.12,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_20",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_9",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.742,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_20",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_21",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.24,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_8",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.608,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_21",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.14,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_22",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_7",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.474,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_22",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.16,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_6",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_23",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.18,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_5",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.206,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_24",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hatshepsut_4",
        "descriptionLocaKey": "hero_battle_ability_description.Hatshepsut_1",
        "descriptionParameters": {
            "focus_duration": {
                "value": 1.6,
                "type": "duration"
            },
            "heal_percentage": {
                "value": 5.072,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_25",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.22,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_26",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.24,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_27",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.26,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_28",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.28,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_29",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Menes_23",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_24",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Menes_25",
        "descriptionLocaKey": "hero_battle_ability_description.Menes_10",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage_burn": {
                "value": 0.88,
                "type": "percentage"
            },
            "move_speed_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "atk_percentage": {
                "value": 0.32,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Cleopatra_10",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.9,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_11",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.92,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_12",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.94,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_13",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.96,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_14",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.98,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_15",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.0,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_16",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.02,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_17",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.04,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_18",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_19",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_8",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_9",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_4",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_5",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_6",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_7",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.22,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_1",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_2",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_3",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.21,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 5.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen10_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed8_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Lagertha_21",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.9704,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2712,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_22",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.069,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2838,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_23",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.1675,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2964,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_24",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.266,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3089,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_20",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.8719,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2587,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_29",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.7586,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3717,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_25",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.3645,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3215,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_26",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.4631,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.334,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_27",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.5616,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3466,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_28",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 10.6601,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.3592,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_10",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.8867,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1331,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_11",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 8.9852,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1456,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_12",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.0837,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1582,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_13",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.1823,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1707,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple14_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Lagertha_18",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.6749,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2335,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_19",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.7734,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2461,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue6_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Lagertha_14",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.2808,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1833,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_15",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.3793,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.1959,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_16",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.4778,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.2084,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Lagertha_17",
        "descriptionLocaKey": "hero_battle_ability_description.Lagertha_1",
        "descriptionParameters": {
            "damage": {
                "value": 9.5764,
                "type": "percentage"
            },
            "attack_speed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "attack_speed_buff": {
                "value": 1.221,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_5",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2352,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_4",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2139,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_9",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.124,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.124,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.SiouxShaman_5",
        "descriptionLocaKey": "hero_battle_ability_description.SiouxShaman_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.42,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_3",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.1926,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_8",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.121,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.121,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_2",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.1713,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SiouxShaman_3",
        "descriptionLocaKey": "hero_battle_ability_description.SiouxShaman_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_9",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.3204,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SiouxShaman_4",
        "descriptionLocaKey": "hero_battle_ability_description.SiouxShaman_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_8",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2991,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SiouxShaman_1",
        "descriptionLocaKey": "hero_battle_ability_description.SiouxShaman_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_7",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2778,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.SiouxShaman_2",
        "descriptionLocaKey": "hero_battle_ability_description.SiouxShaman_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_6",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.2565,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_3",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.106,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.106,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.106,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.106,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_2",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.103,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.103,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.103,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.103,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_1",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.1,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_7",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.118,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.118,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.ArtemisiaIOfCaria_1",
        "descriptionLocaKey": "hero_battle_ability_description.ArtemisiaIOfCaria_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_6",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.115,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.115,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_5",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.112,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.112,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.112,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.112,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenElizabethI_4",
        "descriptionLocaKey": "hero_battle_ability_description.QueenElizabethI_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "atk_percentage": {
                "value": 0.109,
                "type": "percentage"
            },
            "atk_speed_percentage": {
                "value": 0.109,
                "type": "percentage"
            },
            "crit_dmg_percentage": {
                "value": 0.109,
                "type": "percentage"
            },
            "crit_chance_percentage": {
                "value": 0.109,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_8",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.8,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_7",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.76,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.18,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_6",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.72,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.17,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.37,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_5",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.67,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.15,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.37,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_9",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.84,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.21,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Ninja_5",
        "descriptionLocaKey": "hero_battle_ability_description.Ninja_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 6.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_4",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.63,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.14,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Ninja_4",
        "descriptionLocaKey": "hero_battle_ability_description.Ninja_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 5.85,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_3",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.59,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.13,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Ninja_3",
        "descriptionLocaKey": "hero_battle_ability_description.Ninja_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 5.7,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_2",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.54,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.11,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Ninja_2",
        "descriptionLocaKey": "hero_battle_ability_description.Ninja_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 5.55,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.WilliamWallace_1",
        "descriptionLocaKey": "hero_battle_ability_description.WilliamWallace_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "shield_percentage": {
                "value": 3.5,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            },
            "def_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Ninja_1",
        "descriptionLocaKey": "hero_battle_ability_description.Ninja_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 5.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed3_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.GeorgeWashington_40",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.34,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TigerLily_14",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.69,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_13",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_16",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.95,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_15",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.82,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_10",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.17,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_12",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.43,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_11",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 8.3,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_9",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.78,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.4571,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hoplit_5",
        "descriptionLocaKey": "hero_battle_ability_description.Hoplit_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 8.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_18",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.21,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_8",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.77,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_17",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.08,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_19",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.34,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_5",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.74,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.2286,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hoplit_1",
        "descriptionLocaKey": "hero_battle_ability_description.Hoplit_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.2,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_4",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.73,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.1714,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hoplit_2",
        "descriptionLocaKey": "hero_battle_ability_description.Hoplit_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_7",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.76,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.3429,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hoplit_3",
        "descriptionLocaKey": "hero_battle_ability_description.Hoplit_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_6",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.75,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.2857,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Hoplit_4",
        "descriptionLocaKey": "hero_battle_ability_description.Hoplit_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 7.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_1",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.7,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.0,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_3",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.72,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.1143,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.VanHelsing_2",
        "descriptionLocaKey": "hero_battle_ability_description.VanHelsing_1",
        "descriptionParameters": {
            "damage": {
                "value": 0.71,
                "type": "percentage"
            },
            "bleed_duration": {
                "value": 8.0,
                "type": "duration"
            },
            "bleed_damage": {
                "value": 4.0571,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_25",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 10.12,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_24",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.99,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_21",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.6,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_20",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.47,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_23",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.86,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TigerLily_22",
        "descriptionLocaKey": "hero_battle_ability_description.TigerLily_1",
        "descriptionParameters": {
            "heal_percentage": {
                "value": 9.73,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_15",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_15",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.48,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            },
            "evasion_percentage": {
                "value": 0.1,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_14",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.46,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue13_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.CyrusTheGreat_11",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.4,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_10",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.38,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_13",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.44,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.CyrusTheGreat_12",
        "descriptionLocaKey": "hero_battle_ability_description.CyrusTheGreat_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 1.42,
                "type": "percentage"
            },
            "range": {
                "value": 1.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed10_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.UlyssesSGrant_14",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 5.025,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 6.2037,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 9.1194,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_13",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.95,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 6.1111,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.9833,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_15",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 5.1,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 6.2963,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 9.2556,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_10",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.725,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.8333,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.575,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_37",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.9917,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_4",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.3625,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.365,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_38",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.0778,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_3",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.075,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.36,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_12",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.875,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 6.0185,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.8472,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_39",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 7.1649,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_2",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.7875,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.355,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.UlyssesSGrant_11",
        "descriptionLocaKey": "hero_battle_ability_description.UlyssesSGrant_1",
        "descriptionParameters": {
            "dmg_percentage_current": {
                "value": 4.8,
                "type": "percentage"
            },
            "heal_percentage": {
                "value": 5.9259,
                "type": "percentage"
            },
            "dmg_percentage_def": {
                "value": 8.7111,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_1",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 11.5,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_8",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 13.5125,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.385,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_7",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 13.225,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.38,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_6",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.9375,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.375,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_5",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.65,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.37,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Spartacus_9",
        "descriptionLocaKey": "hero_battle_ability_description.Spartacus_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 13.8,
                "type": "percentage"
            },
            "crit_percentage": {
                "value": 0.39,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_33",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.6576,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_34",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.7396,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_35",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.8226,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_36",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.9066,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_30",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.4176,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_31",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.4966,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_32",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.5766,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_26",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.1109,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_27",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.1862,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_28",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.2624,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_29",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.3395,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple10_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AlbertEinstein_22",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.8189,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_23",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.8906,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple4_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AlbertEinstein_24",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.9631,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_25",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 6.0366,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_20",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.6782,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_21",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.7482,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_19",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.6091,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttomanGeneral_5",
        "descriptionLocaKey": "hero_battle_ability_description.OttomanGeneral",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_15",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.3411,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_16",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.4069,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_17",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.4735,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_18",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.5409,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttomanGeneral_2",
        "descriptionLocaKey": "hero_battle_ability_description.OttomanGeneral",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.43,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttomanGeneral_1",
        "descriptionLocaKey": "hero_battle_ability_description.OttomanGeneral",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.4,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttomanGeneral_4",
        "descriptionLocaKey": "hero_battle_ability_description.OttomanGeneral",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.49,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.OttomanGeneral_3",
        "descriptionLocaKey": "hero_battle_ability_description.OttomanGeneral",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "def_percentage": {
                "value": 0.46,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBotPurple_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBotPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.AlbertEinstein_11",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.0859,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_12",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.1486,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_13",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.212,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_14",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.2762,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.AlbertEinstein_10",
        "descriptionLocaKey": "hero_battle_ability_description.AlbertEinstein_1",
        "descriptionParameters": {
            "duration": {
                "value": 6.0,
                "type": "duration"
            },
            "atk_speed_slow": {
                "value": 0.99,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 5.0241,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow5_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnBlue1_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnBlue_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow13_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.TomoeGozen_9",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.44,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_8",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.41,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_1",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.2,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_3",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.26,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_2",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.23,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_5",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.32,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_4",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.29,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_7",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.38,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.TomoeGozen_6",
        "descriptionLocaKey": "hero_battle_ability_description.TomoeGozen_1",
        "descriptionParameters": {
            "dmg_percentage": {
                "value": 2.35,
                "type": "percentage"
            },
            "range": {
                "value": 3.0,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Cleopatra_1",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_3",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.5,
                "type": "duration"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_2",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_1",
        "descriptionParameters": {
            "duration": {
                "value": 4.0,
                "type": "duration"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_40",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_20",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            },
            "def_percentage": {
                "value": 0.52,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_9",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.88,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_8",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.86,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_5",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.8,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.Cleopatra_4",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_1",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnRed14_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnRed_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Cleopatra_7",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.84,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnGreen3_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnGreen_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.Cleopatra_6",
        "descriptionLocaKey": "hero_battle_ability_description.Cleopatra_5",
        "descriptionParameters": {
            "duration": {
                "value": 5.0,
                "type": "duration"
            },
            "attack_percentage": {
                "value": 0.82,
                "type": "percentage"
            },
            "attack_speed_percentage": {
                "value": 0.35,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_12",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_1",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.56,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_13",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.59,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_10",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.5,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_11",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.53,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_16",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.68,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_17",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.71,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_14",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.62,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_15",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.65,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_18",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.74,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_19",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.77,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_40",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_20",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.8,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_23",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.89,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_24",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.92,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_21",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.83,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_22",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.86,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_12",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.06,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_11",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.05,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.QueenBoudicca_25",
        "descriptionLocaKey": "hero_battle_ability_description.QueenBoudicca_10",
        "descriptionParameters": {
            "duration": {
                "value": 3.5,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 12.85,
                "type": "percentage"
            },
            "dmg_percentage_around": {
                "value": 0.95,
                "type": "percentage"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_14",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.08,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_13",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.07,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_10",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.04,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_19",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.13,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_16",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.1,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_15",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.09,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_18",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.12,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_17",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.11,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnPurple8_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnPurple_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.JuliusCaesar_19",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_23",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.17,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_22",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.16,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_25",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.19,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_24",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.18,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_21",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.15,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_20",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.14,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_20",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_21",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_27",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.21,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_26",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.2,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_29",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.23,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_28",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.22,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_28",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_29",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_26",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_27",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_24",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_25",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_22",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.25,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_23",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.26,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossLightningStormFast_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossLightningStorm_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.GeorgeWashington_34",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.28,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_33",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.27,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.TeslaBossPlasmaSpawnYellow9_1",
        "descriptionLocaKey": "hero_battle_ability_description.TeslaBossPlasmaSpawnYellow_1",
        "descriptionParameters": null
    },
    {
        "id": "ability.GeorgeWashington_36",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.3,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_35",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.29,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_30",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.24,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_32",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.26,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_31",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.25,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_31",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_32",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_30",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.27,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_38",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.32,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_37",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.31,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Leonidas_10",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.398,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_11",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.42,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.GeorgeWashington_39",
        "descriptionLocaKey": "hero_battle_ability_description.GeorgeWashington_1",
        "descriptionParameters": {
            "duration": {
                "value": 8.0,
                "type": "duration"
            },
            "accuracy_percentage": {
                "value": 0.2,
                "type": "percentage"
            },
            "dmg_percentage": {
                "value": 1.33,
                "type": "percentage"
            },
            "range": {
                "value": 2.0,
                "type": "number"
            },
            "duration_link": {
                "value": 6.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Leonidas_12",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.442,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_39",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.3,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Leonidas_13",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.464,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.Leonidas_14",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.486,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_37",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.Leonidas_15",
        "descriptionLocaKey": "hero_battle_ability_description.Leonidas_5",
        "descriptionParameters": {
            "stun_duration": {
                "value": 1.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 1.508,
                "type": "percentage"
            },
            "range": {
                "value": 2.5,
                "type": "number"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_38",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_35",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_36",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.29,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_33",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    },
    {
        "id": "ability.JuliusCaesar_34",
        "descriptionLocaKey": "hero_battle_ability_description.JuliusCaesar_1",
        "descriptionParameters": {
            "invincibility_duration": {
                "value": 4.0,
                "type": "duration"
            },
            "dmg_percentage": {
                "value": 0.28,
                "type": "percentage"
            },
            "burn_duration": {
                "value": 2.0,
                "type": "number"
            },
            "taunt_duration": {
                "value": 8.0,
                "type": "duration"
            }
        }
    }
]

export default abilities;