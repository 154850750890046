const customizationsApplyTo = {
    "littleCulture": "Little Culture Site",
    "moderateCulture": "Moderate Culture Site",
    "ruralFarm": "Rural Farm",
    "compactCulture": "Compact Culture Site",
    "averageHome": "Average Home",
    "smallHome": "Small Home",
    "domesticFarm": "Domestic Farm",
}

export default customizationsApplyTo;