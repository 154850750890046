const workersIds = {
    "Egypt": {
        "default": "egypt_worker"
    },
    "China": {
        "default": "china_worker"
    },
    "Vikings": {
        "default": "viking_worker",
        "WorkerType_SAILOR_VIKINGS": "viking_sailor",
    },
    "capitalCity": {
        "default": "worker"
    },
}

export { workersIds };