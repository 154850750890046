const allBuildings = [
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_FeudalAge_CultureSite_Moderate_1",
                "range": 2,
                "points": 1080
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_CultureSite_Moderate_1",
                "duration": "10500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Axemen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1400000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_CultureSite_Moderate_1",
                "target": "Building_IberianEra_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-540000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "moderateCulture",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_RomanEmpire_CultureSite_Large_1",
                "range": 3,
                "points": 640
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_CultureSite_Large_1",
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_CircusMaximus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-860000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_CultureSite_Large_1",
                "target": "Building_ByzantineEra_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Architekton"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-590000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "largeCulture",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Farm_Rural_2",
                "target": "Building_KingdomOfSicily_Farm_Rural_3",
                "autoFinish": true,
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Conservation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-340000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-885",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "10000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "370000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effects": {
                "food": 0.98
            }
        },
        "level": 32
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "EarlyRome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1575",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Premium_1",
                "target": "Building_RomanEmpire_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "10000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "49000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 1450,
            "effects": {
                "food": 0.921
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Farm_Rural_1",
                "duration": "11400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_VegetableGardens"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3000000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-2350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-2350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Farm_Rural_1",
                "target": "Building_KingdomOfSicily_Farm_Rural_2",
                "autoFinish": true,
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_RefinedTools"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-310000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-875",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "9650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "340000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 3230,
            "effects": {
                "food": 0.98
            }
        },
        "level": 31
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "10500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "410000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 3600,
            "effects": {
                "food": 0.98
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ClassicGreece_CultureSite_Premium_1",
                "range": 2,
                "points": 960
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ClassicGreece"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-590",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_CultureSite_Premium_1",
                "target": "Building_EarlyRome_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "premiumCulture",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Infantry_3",
                "target": "Building_ByzantineEra_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "19500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Bucellarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-775",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-775",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-775",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Princeps",
                    "level": 40
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effect": 0.028
        },
        "level": 16,
        "assetId": "Building_RomanEmpire_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "RomanEmpire"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Premium_1",
                "target": "Building_ByzantineEra_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "13000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 1670,
            "effects": {
                "food": 1.058
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_CultureSite_Compact_1",
                "range": 1,
                "points": 190
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_CultureSite_Compact_1",
                "duration": "480s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SacredStones"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_CultureSite_Compact_1",
                "target": "Building_MinoanEra_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_BasketWeaving"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "compactCulture",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Infantry_2",
                "target": "Building_RomanEmpire_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "18300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_ImprovedGladius"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-880",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Princeps",
                    "level": 37
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2780,
            "effect": 0.027
        },
        "level": 15,
        "assetId": "Building_RomanEmpire_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Barracks_Infantry_1",
                "duration": "25500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Princeps"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-760000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1905",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1905",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1905",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Infantry_1",
                "target": "Building_RomanEmpire_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "17100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_FieldMedicines"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-820",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1225",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Princeps",
                    "level": 34
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "92000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2590,
            "effect": 0.027
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_RomanEmpire_CultureSite_Premium_1",
                "range": 2,
                "points": 1360
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "RomanEmpire"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-770",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_CultureSite_Premium_1",
                "target": "Building_ByzantineEra_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "85000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "premiumCulture",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Siege_3",
                "target": "Building_AgeOfTheFranks_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "28800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_CarolingianCatapult"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-340000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Catapult",
                    "level": 51
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effect": 0.028
        },
        "level": 19,
        "assetId": "Building_ByzantineEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Siege_2",
                "target": "Building_ByzantineEra_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "27300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_MobilityInnovations"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-310000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-705",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Catapult",
                    "level": 48
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "220000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3210,
            "effect": 0.028
        },
        "level": 18,
        "assetId": "Building_ByzantineEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Average_2",
                "target": "Building_FeudalAge_Home_Average_3",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Castle"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-79000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1030",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2500,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Average_3",
                "target": "Building_IberianEra_Home_Average_1",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Masia"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-84000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-580",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "85000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2570,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_FeudalAge_CultureSite_Compact_1",
                "range": 1,
                "points": 580
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_CultureSite_Compact_1",
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_MythsAndLegends"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-430000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_CultureSite_Compact_1",
                "target": "Building_IberianEra_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Masia"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-166000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "compactCulture",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Home_Average_1",
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_MythsAndLegends"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-610000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Average_1",
                "target": "Building_FeudalAge_Home_Average_2",
                "autoFinish": true,
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_PeakedRoofs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-73000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1020",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "68000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2430,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 25
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "EarlyRome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-690",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Premium_1",
                "target": "Building_RomanEmpire_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "52000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1150,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Farm_Domestic_2",
                "target": "Building_KingdomOfSicily_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "9900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ImprovedSails"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "25900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "460000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3800,
            "effects": {
                "food": 1.154
            }
        },
        "level": 32
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Farm_Domestic_1",
                "duration": "14100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3800000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-3275",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-3275",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-3275",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Farm_Domestic_1",
                "target": "Building_KingdomOfSicily_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Windmill"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-390000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1825",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-1220",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "24700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "420000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3720,
            "effects": {
                "food": 1.154
            }
        },
        "level": 31
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "27200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "510000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3940,
            "effects": {
                "food": 1.154
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Siege_2",
                "target": "Building_AgeOfTheFranks_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "31800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_SiegeEngineTactics"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-390000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2075",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_CarolingianCatapult",
                    "level": 61
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "330000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3750,
            "effect": 0.028
        },
        "level": 21,
        "assetId": "Building_AgeOfTheFranks_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Siege_3",
                "target": "Building_FeudalAge_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "33300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Trebuchets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1370",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1370",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1370",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_CarolingianCatapult",
                    "level": 66
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "370000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3930,
            "effect": 0.028
        },
        "level": 22,
        "assetId": "Building_AgeOfTheFranks_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_IberianEra_CultureSite_Moderate_1",
                "range": 2,
                "points": 1280
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_CultureSite_Moderate_1",
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1900000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_CultureSite_Moderate_1",
                "target": "Building_KingdomOfSicily_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Orchards"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-670000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "210000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "moderateCulture",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_StoneAge_CultureSite_Compact_1",
                "range": 1,
                "points": 160
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_CultureSite_Compact_1",
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_BearCult"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-15",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_CultureSite_Compact_1",
                "target": "Building_BronzeAge_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SacredStones"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "compactCulture",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Barracks_Siege_1",
                "duration": "45300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_CarolingianCatapult"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2500000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-1420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-1420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-1420",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Siege_1",
                "target": "Building_AgeOfTheFranks_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "30300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_BallisticInnovation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-370000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1050",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_CarolingianCatapult",
                    "level": 56
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "290000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3570,
            "effect": 0.028
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Infantry_3",
                "target": "Building_IberianEra_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "30300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Almogavars"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Axemen",
                    "level": 84
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "420000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4460,
            "effect": 0.028
        },
        "level": 25,
        "assetId": "Building_FeudalAge_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Infantry_2",
                "target": "Building_FeudalAge_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "29100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Wingguards"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-390000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2065",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Axemen",
                    "level": 79
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "380000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4290,
            "effect": 0.028
        },
        "level": 24,
        "assetId": "Building_FeudalAge_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Barracks_Infantry_1",
                "duration": "41700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Axemen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3000000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-3055",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-3055",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-3055",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Infantry_1",
                "target": "Building_FeudalAge_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "27900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_SocketLangets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-370000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1360",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Axemen",
                    "level": 73
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "340000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4110,
            "effect": 0.028
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_StoneAge_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "cityHall",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_10",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_10"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_10",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_10"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_10",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "5"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_4"
                                            },
                                            "resource": "research_points",
                                            "amount": "7"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10_5"
                                            },
                                            "resource": "research_points",
                                            "amount": "10"
                                        }
                                    ],
                                    "chances": [
                                        20,
                                        40,
                                        25,
                                        10,
                                        5
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_10"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_10",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Workshop_Carpenter_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "duration": "17100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "wardrobe"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-6455",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "planks",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "cartwheel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "barrel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "door",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wardrobe",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Workshop_Carpenter_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "790000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "carpenter",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_KingdomOfSicily_CultureSite_Moderate_1",
                "range": 2,
                "points": 1520
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_CultureSite_Moderate_1",
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Orchards"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "moderateCulture",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_FeudalAge_CultureSite_Large_1",
                "range": 3,
                "points": 1080
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_CultureSite_Large_1",
                "duration": "16800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_RepurposedRuins"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3300000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_CultureSite_Large_1",
                "target": "Building_IberianEra_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "14400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Patios"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1290000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "370000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "largeCulture",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Barracks_Siege_1",
                "duration": "38700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Catapult"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2945",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2945",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2945",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Siege_1",
                "target": "Building_ByzantineEra_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "25800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_AdvancedCounterweights"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-280000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-575",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Catapult",
                    "level": 45
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 3040,
            "effect": 0.028
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_EarlyRome_CultureSite_Compact_1",
                "range": 1,
                "points": 290
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_CultureSite_Compact_1",
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Hastati"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-53000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_CultureSite_Compact_1",
                "target": "Building_RomanEmpire_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "3540s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Forums"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-57000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "compactCulture",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4050",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "cityHall",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Home_Small_1",
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-480",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-480",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-480",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Small_1",
                "target": "Building_ByzantineEra_Home_Small_2",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_TheodosianWalls"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-19000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-62",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-93",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "13000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 19
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_HeavyInfantry_2",
                "target": "Building_RomanEmpire_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "21600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_CenturionCommand"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1460",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-975",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Legionary",
                    "level": 37
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2780,
            "effect": 0.027
        },
        "level": 15,
        "assetId": "Building_RomanEmpire_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_HeavyInfantry_3",
                "target": "Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "23100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Jovians"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-230000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-865",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-865",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-865",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Legionary",
                    "level": 40
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effect": 0.028
        },
        "level": 16,
        "assetId": "Building_RomanEmpire_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_TreasureHunt_Evolving_FountainOfYouth_1",
        "type": "evolving",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "dynamicPointsDefinitionId": "Dv_Building_TreasureHunt_Evolving_FountainOfYouth_1_CultureValues",
                "dynamicRangeDefinitionId": "Dv_Building_TreasureHunt_Evolving_FountainOfYouth_1_CultureRange"
            },
            {
                "@type": "type.googleapis.com/PinnedAgeComponentDTO",
                "id": "PinnedAge_Building_TreasureHunt_Evolving_FountainOfYouth_1"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_TreasureHunt_Evolving_FountainOfYouth_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/LevelUpComponentDTO",
                "id": "LevelUp_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "duration": "0s",
                "start": {
                    "dynamicChangeDefinitionId": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_UpgradeCosts"
                },
                "starLevels": [
                    10,
                    20,
                    30,
                    40
                ],
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_TreasureHunt_Evolving_FountainOfYouth_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "auto": true,
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "dynamicChangeDefinitionId": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_RP"
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "auto": true,
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "dynamicChangeDefinitionId": "Dac_Building_TreasureHunt_Evolving_FountainOfYouth_1_RiftTokens"
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_TreasureHunt_Evolving_FountainOfYouth_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "EvolutionToken|Building_TreasureHunt_Evolving_FountainOfYouth_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true,
                "keepLevel": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "group": "evolvingFountainOfYouth",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Special_HeroAcademy_1",
                "duration": "15600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-310000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-855",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-855",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-855",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Special_HeroAcademy_1",
                "target": "Building_RomanEmpire_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "13500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-323000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-770",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_EarlyRome_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "19200s",
                "minCollectionPeriod": "19200s",
                "skipPricePerMinute": 0.1563,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "500",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "40000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "EarlyRome",
        "group": "heroAcademy",
        "level": 3,
        "assetId": "Building_ClassicGreece_Special_HeroAcademy_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "duration": "30300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Legionary"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-910000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "target": "Building_RomanEmpire_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "20400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_ShieldwallFortification"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-190000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-910",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1360",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Legionary",
                    "level": 34
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2590,
            "effect": 0.027
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Small_3",
                "target": "Building_AgeOfTheFranks_Home_Small_1",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-23000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-95",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "17000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1880,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "AgeOfTheFranks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-840",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Premium_1",
                "target": "Building_FeudalAge_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1850,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Small_2",
                "target": "Building_ByzantineEra_Home_Small_3",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Mortar"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-21000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-76",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-115",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1820,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_CultureSite_Premium_1",
                "range": 2,
                "points": 760
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "MinoanEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_CultureSite_Premium_1",
                "target": "Building_ClassicGreece_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "17000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "premiumCulture",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Rural_3",
                "target": "Building_ByzantineEra_Farm_Rural_1",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-465",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "70000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1780,
            "effects": {
                "food": 0.48
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Workshop_Scribe_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Workshop_Scribe_1",
                "duration": "13500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "manuscript"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3800000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-2530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Workshop_Scribe_1",
                "target": "Building_IberianEra_Workshop_Scribe_1",
                "autoFinish": true,
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "wax_seal"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-2065",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Workshop_Scribe_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "parchment",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_FeudalAge_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ink",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_FeudalAge_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "manuscript",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Workshop_Scribe_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "430000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "scribe",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "range": 3,
                "points": 1450
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-75",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Domestic_2",
                "target": "Building_ClassicGreece_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Math"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-54000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-545",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-360",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "19000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1420,
            "effects": {
                "food": 0.438
            }
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "range": 3,
                "points": 1200
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_9",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-70",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Farm_Domestic_1",
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_DomesticPigs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-92000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-710",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-710",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-710",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Domestic_1",
                "target": "Building_ClassicGreece_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_CropRotation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-40000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-485",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-320",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "13000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1330,
            "effects": {
                "food": 0.438
            }
        },
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "range": 3,
                "points": 1000
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_8",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-65",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Farm_Rural_1",
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-410000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Rural_1",
                "target": "Building_RomanEmpire_Farm_Rural_2",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_TenantFarming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-87000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-735",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-490",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "50000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1650,
            "effects": {
                "food": 0.48
            }
        },
        "level": 16
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Home_Small_1",
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-785",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-785",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-785",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Home_Small_1",
                "target": "Building_KingdomOfSicily_Home_Small_2",
                "autoFinish": true,
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_DomedSanctuary"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-49000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-290",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-440",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "53000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2970,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 31
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "range": 3,
                "points": 850
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_7",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Domestic_3",
                "target": "Building_EarlyRome_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "3300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_RearLivestock"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-64000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-390",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3860",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "25000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1470,
            "effects": {
                "food": 0.438
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Rural_2",
                "target": "Building_RomanEmpire_Farm_Rural_3",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_PublicGardens"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-93000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-790",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "59000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1700,
            "effects": {
                "food": 0.48
            }
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Home_Small_2",
                "target": "Building_KingdomOfSicily_Home_Small_3",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_MarbleDecorations"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-53000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-295",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-445",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "58000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 3050,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 32
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "range": 3,
                "points": 720
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_6",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-55",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Infantry_2",
                "target": "Building_ByzantineEra_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "21900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_TrenchWarfare"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-250000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-385",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-575",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Bucellarii",
                    "level": 48
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3210,
            "effect": 0.028
        },
        "level": 18,
        "assetId": "Building_ByzantineEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "range": 3,
                "points": 600
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_5",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Infantry_3",
                "target": "Building_AgeOfTheFranks_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "23100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Skirmishers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-280000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-475",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-475",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-475",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Bucellarii",
                    "level": 51
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "210000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effect": 0.028
        },
        "level": 19,
        "assetId": "Building_ByzantineEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "range": 3,
                "points": 470
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_4",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-45",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "range": 3,
                "points": 420
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_3",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "range": 3,
                "points": 350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "duration": "10800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "BronzeAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "target": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_2",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-35",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "IberianEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-2450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Premium_1",
                "target": "Building_KingdomOfSicily_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "260000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 2950,
            "effects": {
                "food": 1.881
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Barracks_Ranged_1",
                "duration": "50100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Crossbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-3955",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-3955",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-3955",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Ranged_1",
                "target": "Building_IberianEra_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "33300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_LatchMechanism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1475",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2210",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Crossbowmen",
                    "level": 91
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "490000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 4640,
            "effect": 0.028
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Ranged_2",
                "target": "Building_IberianEra_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "34500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_SafetyMechanisms"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-520000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1490",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Crossbowmen",
                    "level": 96
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "540000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 4820,
            "effect": 0.028
        },
        "level": 27,
        "assetId": "Building_IberianEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Ranged_3",
                "target": "Building_KingdomOfSicily_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "36000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ArmoredCrossbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-570000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1255",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1255",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1255",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Crossbowmen",
                    "level": 101
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 5000,
            "effect": 0.028
        },
        "level": 28,
        "assetId": "Building_IberianEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ClassicGreece"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Premium_1",
                "target": "Building_EarlyRome_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "32000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 1200,
            "effects": {
                "food": 0.838
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Rural_3",
                "target": "Building_MinoanEra_Farm_Rural_1",
                "autoFinish": true,
                "duration": "1140s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_OatProduction"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-11000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-68",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-68",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-68",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 950,
            "effects": {
                "food": 0.35
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Workshop_Scribe_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Workshop_Scribe_1",
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "ink"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Workshop_Scribe_1",
                "target": "Building_FeudalAge_Workshop_Scribe_1",
                "autoFinish": true,
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "manuscript"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1665",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Workshop_Scribe_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "parchment",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_AgeOfTheFranks_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ink",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Workshop_Scribe_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "scribe",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Rural_2",
                "target": "Building_IberianEra_Farm_Rural_3",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Botany"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-260000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-855",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "7650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2950,
            "effects": {
                "food": 0.85
            }
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Rural_3",
                "target": "Building_KingdomOfSicily_Farm_Rural_1",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_VegetableGardens"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-290000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-720",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-720",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-720",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "8050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 3030,
            "effects": {
                "food": 0.85
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Farm_Rural_1",
                "duration": "10500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-2270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-2270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Rural_1",
                "target": "Building_IberianEra_Farm_Rural_2",
                "autoFinish": true,
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_OrangePlantation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-240000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-845",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "7300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2880,
            "effects": {
                "food": 0.85
            }
        },
        "level": 28
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Barracks_Infantry_1",
                "duration": "30900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Bucellarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2405",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2405",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2405",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Infantry_1",
                "target": "Building_ByzantineEra_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "20700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_ImprovedLorica"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-230000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-310",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Bucellarii",
                    "level": 45
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3040,
            "effect": 0.028
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Rural_2",
                "target": "Building_BronzeAge_Farm_Rural_3",
                "autoFinish": true,
                "duration": "840s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Plough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-24",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-16",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 820,
            "effects": {
                "food": 0.35
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "64000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 3130,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Farm_Rural_1",
                "duration": "960s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Village"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Rural_1",
                "target": "Building_BronzeAge_Farm_Rural_2",
                "autoFinish": true,
                "duration": "540s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_FarmUtensils"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-6",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-4",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 760,
            "effects": {
                "food": 0.35
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Barracks_Infantry_1",
                "duration": "20100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Hastati"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-370000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Infantry_1",
                "target": "Building_EarlyRome_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "13500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_SwordMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-945",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-630",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Hastati",
                    "level": 27
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2290,
            "effect": 0.024
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Domestic_2",
                "target": "Building_AgeOfTheFranks_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Goatherds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-210000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-940",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "11200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2610,
            "effects": {
                "food": 0.727
            }
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Average_3",
                "target": "Building_ByzantineEra_Home_Average_1",
                "autoFinish": true,
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_PendentiveDome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-40000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-390",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "26000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1710,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Domestic_3",
                "target": "Building_FeudalAge_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_DomesticSheep"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-230000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-935",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-935",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-935",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "11800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2680,
            "effects": {
                "food": 0.727
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Infantry_3",
                "target": "Building_RomanEmpire_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "15900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Princeps"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-630",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-630",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-630",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Hastati",
                    "level": 31
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2410,
            "effect": 0.027
        },
        "level": 13,
        "assetId": "Building_EarlyRome_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Infantry_2",
                "target": "Building_EarlyRome_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "14700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_RationsEfficiency"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-130000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Hastati",
                    "level": 29
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "61000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2310,
            "effect": 0.026
        },
        "level": 12,
        "assetId": "Building_EarlyRome_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "MinoanEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-590",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Premium_1",
                "target": "Building_ClassicGreece_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "19000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 790,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "duration": "40800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Caballarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-1265",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-1265",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-1265",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "target": "Building_AgeOfTheFranks_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "27300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_ImprovedReins"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-330000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-935",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1405",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Caballarii",
                    "level": 56
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "260000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3570,
            "effect": 0.028
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Cavalry_2",
                "target": "Building_AgeOfTheFranks_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "28500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_EliteRiderTraining"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-360000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Caballarii",
                    "level": 61
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3750,
            "effect": 0.028
        },
        "level": 21,
        "assetId": "Building_AgeOfTheFranks_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Cavalry_3",
                "target": "Building_FeudalAge_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "30000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Knights"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-380000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1220",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1220",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1220",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Caballarii",
                    "level": 66
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "340000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3930,
            "effect": 0.028
        },
        "level": 22,
        "assetId": "Building_AgeOfTheFranks_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Farm_Domestic_1",
                "duration": "9900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Villers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-965",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-965",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-965",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Domestic_1",
                "target": "Building_AgeOfTheFranks_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_AdvancedFodder"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1075",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-715",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "10700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "160000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2520,
            "effects": {
                "food": 0.727
            }
        },
        "level": 22
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Small_3",
                "target": "Building_ByzantineEra_Home_Small_1",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-17000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-155",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-155",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-155",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1620,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Small_2",
                "target": "Building_RomanEmpire_Home_Small_3",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_VillaUrbana"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-15000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-175",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-265",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1550,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Home_Small_1",
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-65000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Small_1",
                "target": "Building_RomanEmpire_Home_Small_2",
                "autoFinish": true,
                "duration": "3360s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Forums"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-14000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-165",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-245",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1550",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1480,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 16
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Barracks_Ranged_1",
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Archery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Ranged_1",
                "target": "Building_BronzeAge_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SilentDraw"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-7",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-11",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Archers",
                    "level": 12
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effect": 0.009
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Average_3",
                "target": "Building_RomanEmpire_Home_Average_1",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Swinery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-31000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-315",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1450,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Ranged_2",
                "target": "Building_BronzeAge_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_TribalMarksmanship"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-15000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-42",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-28",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Archers",
                    "level": 14
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effect": 0.011
        },
        "level": 3,
        "assetId": "Building_BronzeAge_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Average_2",
                "target": "Building_EarlyRome_Home_Average_3",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Domus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-27000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-520",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1410,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Ranged_3",
                "target": "Building_MinoanEra_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_CompositeBow"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-21000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Archers",
                    "level": 16
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1430,
            "effect": 0.014
        },
        "level": 4,
        "assetId": "Building_BronzeAge_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Home_Average_1",
                "duration": "11400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Masia"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-850000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1890",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1890",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1890",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Average_1",
                "target": "Building_IberianEra_Home_Average_2",
                "autoFinish": true,
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_HorseshoeArches"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-91000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-705",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1060",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "94000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2750,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 28
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "salt"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "target": "Building_FeudalAge_Workshop_SpiceMerchant_1",
                "autoFinish": true,
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "herbs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1665",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pepper",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "salt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "spiceMerchant",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_HeavyInfantry_3",
                "target": "Building_MinoanEra_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_HeavyInfantry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-23000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-125",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_HeavyInfantry",
                    "level": 16
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1430,
            "effect": 0.014
        },
        "level": 4,
        "assetId": "Building_BronzeAge_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Barracks_Infantry_1",
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_AbilityTraining"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Infantry_1",
                "target": "Building_BronzeAge_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_ReinforcedFootwear"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-7",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Swordsmen",
                    "level": 12
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effect": 0.009
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Average_3",
                "target": "Building_KingdomOfSicily_Home_Average_1",
                "autoFinish": true,
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ImprovedRoofTiles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 3030,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Home_Average_1",
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Insulae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-75000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-635",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Average_1",
                "target": "Building_EarlyRome_Home_Average_2",
                "autoFinish": true,
                "duration": "3300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_TributumCapitis"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-23000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-475",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 13
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_HeavyInfantry_2",
                "target": "Building_BronzeAge_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_WarPaint"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-17000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-45",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_HeavyInfantry",
                    "level": 14
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effect": 0.011
        },
        "level": 3,
        "assetId": "Building_BronzeAge_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Infantry_2",
                "target": "Building_BronzeAge_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_AncestralValor"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-14000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-41",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-27",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Swordsmen",
                    "level": 14
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effect": 0.011
        },
        "level": 3,
        "assetId": "Building_BronzeAge_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Average_2",
                "target": "Building_IberianEra_Home_Average_3",
                "autoFinish": true,
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Destillation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-99000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-715",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1070",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2890,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Barracks_HeavyInfantry_1",
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_HeavyInfantry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_HeavyInfantry_1",
                "target": "Building_BronzeAge_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_BronzePlating"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-8",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-11",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_HeavyInfantry",
                    "level": 12
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effect": 0.009
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Infantry_3",
                "target": "Building_MinoanEra_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Arenas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-20000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-115",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-115",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-115",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Swordsmen",
                    "level": 16
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1430,
            "effect": 0.014
        },
        "level": 4,
        "assetId": "Building_BronzeAge_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Home_Average_1",
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Swinery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-955",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-955",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-955",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Average_1",
                "target": "Building_RomanEmpire_Home_Average_2",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_VillaRustica"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-33000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-410",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-610",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "19000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1570,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 16
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ByzantineEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-790",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Premium_1",
                "target": "Building_AgeOfTheFranks_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "8100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1600,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Average_2",
                "target": "Building_RomanEmpire_Home_Average_3",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UnderfloorHeating"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-35000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-440",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-655",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1640,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_HeavyInfantry_3",
                "target": "Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "40200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_FamiliaRegis"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-630000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1335",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1335",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1335",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_OrderOfCalatrava",
                    "level": 101
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "670000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5000,
            "effect": 0.028
        },
        "level": 28,
        "assetId": "Building_IberianEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_HeavyInfantry_2",
                "target": "Building_IberianEra_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "38700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_GambesonPadding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-580000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1585",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_OrderOfCalatrava",
                    "level": 96
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4820,
            "effect": 0.028
        },
        "level": 27,
        "assetId": "Building_IberianEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Barracks_HeavyInfantry_1",
                "duration": "55800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_OrderOfCalatrava"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4900000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4210",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_HeavyInfantry_1",
                "target": "Building_IberianEra_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "37200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_ShieldBoss"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-530000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1570",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_OrderOfCalatrava",
                    "level": 91
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "550000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4640,
            "effect": 0.028
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_CultureSite_Moderate_1",
                "range": 2,
                "points": 320
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_CultureSite_Moderate_1",
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SocialChange"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_CultureSite_Moderate_1",
                "target": "Building_MinoanEra_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "420s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Masonry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-17000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "moderateCulture",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_EarlyRome_CultureSite_Large_1",
                "range": 3,
                "points": 520
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_CultureSite_Large_1",
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Watchtowers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_CultureSite_Large_1",
                "target": "Building_RomanEmpire_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_CircusMaximus"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-440000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "54000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "largeCulture",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Domestic_3",
                "target": "Building_ByzantineEra_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Forquier"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-130000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-645",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-645",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-645",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "6750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "88000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2020,
            "effects": {
                "food": 0.56
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_RomanEmpire_CultureSite_Little_1",
                "range": 1,
                "points": 190
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_CultureSite_Little_1",
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_TenantFarming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-52000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_CultureSite_Little_1",
                "target": "Building_ByzantineEra_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "3360s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_GregorianCalendar"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-36000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "littleCulture",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Farm_Domestic_1",
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Swinery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-520000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1590",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1590",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1590",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Domestic_1",
                "target": "Building_RomanEmpire_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_VillaRustica"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1025",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "6120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "63000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1860,
            "effects": {
                "food": 0.56
            }
        },
        "level": 16
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Farm_Domestic_2",
                "target": "Building_RomanEmpire_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_PastureImprovement"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-730",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "6430",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "75000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effects": {
                "food": 0.56
            }
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Barracks_Siege_1",
                "duration": "32100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Ballistas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-950000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2330",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Siege_1",
                "target": "Building_RomanEmpire_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "21300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_EnhancedTorsion"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Ballista",
                    "level": 34
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "120000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2590,
            "effect": 0.027
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Siege_2",
                "target": "Building_RomanEmpire_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "22800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_QuickReloadSystem"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-210000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1610",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-1070",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Ballista",
                    "level": 37
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2780,
            "effect": 0.027
        },
        "level": 15,
        "assetId": "Building_RomanEmpire_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Siege_3",
                "target": "Building_ByzantineEra_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "24300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Catapult"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-250000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-950",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-950",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-950",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Ballista",
                    "level": 40
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "160000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effect": 0.028
        },
        "level": 16,
        "assetId": "Building_RomanEmpire_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Rural_2",
                "target": "Building_EarlyRome_Farm_Rural_3",
                "autoFinish": true,
                "duration": "3240s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_SoilConservation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-71000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-625",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "33000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1450,
            "effects": {
                "food": 0.43
            }
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_AgeOfTheFranks_CultureSite_Moderate_1",
                "range": 2,
                "points": 880
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_CultureSite_Moderate_1",
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-930000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_CultureSite_Moderate_1",
                "target": "Building_FeudalAge_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Axemen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "moderateCulture",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Rural_3",
                "target": "Building_RomanEmpire_Farm_Rural_1",
                "autoFinish": true,
                "duration": "3480s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-81000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-375",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-375",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-375",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "41000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1520,
            "effects": {
                "food": 0.43
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Farm_Rural_1",
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-760",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-760",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-760",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Rural_1",
                "target": "Building_EarlyRome_Farm_Rural_2",
                "autoFinish": true,
                "duration": "2940s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Sewerage"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-61000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-565",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "26000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1400,
            "effects": {
                "food": 0.43
            }
        },
        "level": 13
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Workshop_Artisan_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Workshop_Artisan_1",
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "goblet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-560000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Workshop_Artisan_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "bronze_bracelet",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_RomanEmpire_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "iron_pendant",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_RomanEmpire_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silver_ring",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_RomanEmpire_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_laurel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_RomanEmpire_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "goblet",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Workshop_Artisan_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "68000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "artisan",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_FeudalAge_CultureSite_Little_1",
                "range": 1,
                "points": 300
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_CultureSite_Little_1",
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Manorialism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_CultureSite_Little_1",
                "target": "Building_IberianEra_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Door"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-78000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "23000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "littleCulture",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "10500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "10500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "cityHall",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ByzantineEra_CultureSite_Little_1",
                "range": 1,
                "points": 220
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_CultureSite_Little_1",
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_GregorianCalendar"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-88000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_CultureSite_Little_1",
                "target": "Building_AgeOfTheFranks_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Aristocracy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-51000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "10000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "littleCulture",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_CultureSite_Moderate_1",
                "range": 2,
                "points": 400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_CultureSite_Moderate_1",
                "duration": "2400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Masonry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-18000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_CultureSite_Moderate_1",
                "target": "Building_ClassicGreece_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "2040s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_DomesticPigs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-44000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "moderateCulture",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Domestic_3",
                "target": "Building_IberianEra_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_DomesticatedDonkeys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-280000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-970",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-970",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-970",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "15600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3050,
            "effects": {
                "food": 0.848
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Domestic_2",
                "target": "Building_FeudalAge_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Transhumance"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-260000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1720",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "14900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2960,
            "effects": {
                "food": 0.848
            }
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Farm_Domestic_1",
                "duration": "11400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_DomesticSheep"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2000000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-2550",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2550",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-2550",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Domestic_1",
                "target": "Building_FeudalAge_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_SheepHerds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-240000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1135",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "14200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "230000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effects": {
                "food": 0.848
            }
        },
        "level": 25
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
        "type": "collectable",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
                "amount": 3
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
                "range": 3,
                "points": 1750
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10"
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
                "duration": "10800s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Collectable_MinoanWatchtowerV2_10",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_MinoanEra_Collectable_MinoanWatchtower_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "collectableMinoanWatchtowerV2",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "duration": "17700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Hoplites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-940",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-940",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-940",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "target": "Building_ClassicGreece_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_BulwarkFormation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-69000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-430",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-645",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Hoplites",
                    "level": 22
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "23000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1900,
            "effect": 0.021
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ClassicGreece_CultureSite_Little_1",
                "range": 1,
                "points": 140
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_CultureSite_Little_1",
                "duration": "2220s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Philosophy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-9200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_CultureSite_Little_1",
                "target": "Building_EarlyRome_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "1920s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_MarksOfHistory"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-15800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "littleCulture",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_HeavyInfantry_2",
                "target": "Building_ClassicGreece_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_IntimidatingPresence"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-94000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-725",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-480",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Hoplites",
                    "level": 23
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "33000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effect": 0.023
        },
        "level": 9,
        "assetId": "Building_ClassicGreece_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ByzantineEra_CultureSite_Moderate_1",
                "range": 2,
                "points": 760
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_CultureSite_Moderate_1",
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-590000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_CultureSite_Moderate_1",
                "target": "Building_AgeOfTheFranks_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-340000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "69000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "moderateCulture",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_HeavyInfantry_3",
                "target": "Building_EarlyRome_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "14700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Triarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-520",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-520",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-520",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Hoplites",
                    "level": 25
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "44000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effect": 0.024
        },
        "level": 10,
        "assetId": "Building_ClassicGreece_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_EarlyRome_CultureSite_Premium_1",
                "range": 2,
                "points": 1160
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "EarlyRome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_CultureSite_Premium_1",
                "target": "Building_RomanEmpire_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "54000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "premiumCulture",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_SiegeTower",
                    "level": 125
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "930000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 5540,
            "effect": 0.028
        },
        "level": 31,
        "assetId": "Building_KingdomOfSicily_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Small_2",
                "target": "Building_ClassicGreece_Home_Small_3",
                "autoFinish": true,
                "duration": "2160s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_PhilosophicalDebates"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-86",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1220",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1120,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Home_Small_1",
                "duration": "2880s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-12000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-170",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Small_1",
                "target": "Building_ClassicGreece_Home_Small_2",
                "autoFinish": true,
                "duration": "1920s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Philosophy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-78",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-115",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1070,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Home_Small_1",
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-350000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-755",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-755",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-755",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Small_1",
                "target": "Building_IberianEra_Home_Small_2",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Eyeglasses"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-38000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-280",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-425",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "39000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2600,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 28
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Small_3",
                "target": "Building_KingdomOfSicily_Home_Small_1",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-45000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-240",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2770,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_KingdomOfSicily_CultureSite_Little_1",
                "range": 1,
                "points": 420
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_CultureSite_Little_1",
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_PolychromeInlays"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-380000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "littleCulture",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Small_2",
                "target": "Building_IberianEra_Home_Small_3",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_ToledoSteel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-41000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-430",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "43000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2680,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Small_3",
                "target": "Building_EarlyRome_Home_Small_1",
                "autoFinish": true,
                "duration": "2400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-8000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-93",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-93",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-93",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1170,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Ranged_2",
                "target": "Building_KingdomOfSicily_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "38400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_HeavyCrossbowDesigns"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-670000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1545",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2315",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_ArmoredCrossbowmen",
                    "level": 117
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "730000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 5360,
            "effect": 0.028
        },
        "level": 30,
        "assetId": "Building_KingdomOfSicily_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Barracks_Ranged_1",
                "duration": "55800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ArmoredCrossbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6000000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-4100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-4100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-4100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Ranged_1",
                "target": "Building_KingdomOfSicily_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "37200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ReinforcedNasalGuards"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-610000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2290",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_ArmoredCrossbowmen",
                    "level": 109
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "660000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 5180,
            "effect": 0.028
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_StoneAge_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Barracks_Infantry_1",
                "duration": "10s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_SpearFighting"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-8",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Barracks_Infantry_1",
                "target": "Building_BronzeAge_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_AbilityTraining"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_StoneAge_Player_Spearfighters",
                    "level": 5
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "21",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "infantryBarracks",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "KingdomOfSicily"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-2625",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "55000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "340000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 3350,
            "effects": {
                "food": 2.2
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_ArmoredCrossbowmen",
                    "level": 125
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "800000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 5540,
            "effect": 0.028
        },
        "level": 31,
        "assetId": "Building_KingdomOfSicily_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Barracks_Siege_1",
                "duration": "65700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SiegeTower"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7000000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-4795",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-4795",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-4795",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Siege_1",
                "target": "Building_KingdomOfSicily_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "43800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ArmoredPanels"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-710000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1785",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_SiegeTower",
                    "level": 109
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "770000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 5180,
            "effect": 0.028
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Siege_2",
                "target": "Building_KingdomOfSicily_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "45300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_TacticalChambers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-770000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1805",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2710",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_SiegeTower",
                    "level": 117
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "850000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 5360,
            "effect": 0.028
        },
        "level": 30,
        "assetId": "Building_KingdomOfSicily_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Small_3",
                "target": "Building_MinoanEra_Home_Small_1",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Township"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-23",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-23",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-23",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "880",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 780,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Small_2",
                "target": "Building_BronzeAge_Home_Small_3",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Stakes"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1200",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-6",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-8",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "820",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 750,
            "effects": {
                "coins": 0.299
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Home_Small_1",
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Village"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Small_1",
                "target": "Building_BronzeAge_Home_Small_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_BuildingTools"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-1",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 670,
            "effects": {
                "coins": 0.299
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_RomanEmpire_CultureSite_Moderate_1",
                "range": 2,
                "points": 640
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_CultureSite_Moderate_1",
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Fountains"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-350000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_CultureSite_Moderate_1",
                "target": "Building_ByzantineEra_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-243000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "moderateCulture",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Rural_2",
                "target": "Building_ByzantineEra_Farm_Rural_3",
                "autoFinish": true,
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Tillage"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "96000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effects": {
                "food": 0.55
            }
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Rural_3",
                "target": "Building_AgeOfTheFranks_Farm_Rural_1",
                "autoFinish": true,
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-285",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effects": {
                "food": 0.55
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Farm_Rural_1",
                "duration": "6900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1445",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1445",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-1445",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Rural_1",
                "target": "Building_ByzantineEra_Farm_Rural_2",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Polyculture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-280",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "82000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1920,
            "effects": {
                "food": 0.55
            }
        },
        "level": 19
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Siege_2",
                "target": "Building_MinoanEra_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_BronzeAutomata"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-39000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-385",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Siege",
                    "level": 19
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effect": 0.018
        },
        "level": 6,
        "assetId": "Building_MinoanEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Siege_3",
                "target": "Building_ClassicGreece_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "10800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-50000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Siege",
                    "level": 20
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "17000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effect": 0.02
        },
        "level": 7,
        "assetId": "Building_MinoanEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_StoneAge_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Barracks_Cavalry_1",
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Cavalry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-70",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Barracks_Cavalry_1",
                "target": "Building_BronzeAge_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_CavalrySpearmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_StoneAge_Player_Cavalry",
                    "level": 5
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "21",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "cavalryBarracks",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Barracks_Siege_1",
                "duration": "12000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-49000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Siege_1",
                "target": "Building_MinoanEra_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "7800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_OraclesGuidance"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-33000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-495",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-330",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Siege",
                    "level": 18
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "8200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1560,
            "effect": 0.016
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
                "target": "Building_FeudalAge_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "31500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_KingsGuard"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1245",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1245",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1245",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Halberds",
                    "level": 66
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "350000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3930,
            "effect": 0.028
        },
        "level": 22,
        "assetId": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_CultureSite_Compact_1",
                "range": 1,
                "points": 220
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_CultureSite_Compact_1",
                "duration": "1920s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_BasketWeaving"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_CultureSite_Compact_1",
                "target": "Building_ClassicGreece_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "1620s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Education"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-14000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "compactCulture",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "duration": "43200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Halberds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-1295",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-1295",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-1295",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "target": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "28800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_BannerStandardization"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-350000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-955",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1435",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Halberds",
                    "level": 56
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3570,
            "effect": 0.028
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
                "target": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "30300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_UrbanCombatProwess"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-370000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1885",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1255",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Halberds",
                    "level": 61
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "310000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3750,
            "effect": 0.028
        },
        "level": 21,
        "assetId": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Workshop_Tailor_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Workshop_Tailor_1",
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "tunic"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-270000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1365",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Workshop_Tailor_1",
                "target": "Building_RomanEmpire_Workshop_Tailor_1",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "cape"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-288000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Workshop_Tailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wool",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_EarlyRome_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "linen_shirt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_EarlyRome_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "toga",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_EarlyRome_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "tunic",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Workshop_Tailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "tailor",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Special_HeroAcademy_1",
                "duration": "11400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-345",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Special_HeroAcademy_1",
                "target": "Building_EarlyRome_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "9900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-194000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "20400s",
                "minCollectionPeriod": "20400s",
                "skipPricePerMinute": 0.1471,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "ClassicGreece",
        "group": "heroAcademy",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Workshop_SpiceMerchant_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Workshop_SpiceMerchant_1",
                "duration": "15300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "saffron"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Workshop_SpiceMerchant_1",
                "target": "Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "autoFinish": true,
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "chili"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1860000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-2140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pepper",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_IberianEra_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "salt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_IberianEra_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "herbs",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_IberianEra_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "saffron",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Workshop_SpiceMerchant_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "580000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "spiceMerchant",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_HeavyInfantry_3",
                "target": "Building_IberianEra_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "36000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_OrderOfCalatrava"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-490000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1290",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1290",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_KingsGuard",
                    "level": 84
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "490000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4460,
            "effect": 0.028
        },
        "level": 25,
        "assetId": "Building_FeudalAge_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Workshop_Scribe_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Workshop_Scribe_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "parchment"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2355",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2355",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2355",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Workshop_Scribe_1",
                "target": "Building_AgeOfTheFranks_Workshop_Scribe_1",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "ink"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-960000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-635",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Workshop_Scribe_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "parchment",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Workshop_Scribe_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "scribe",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Barracks_HeavyInfantry_1",
                "duration": "49500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_KingsGuard"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3500000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-3395",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-3395",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-3395",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_HeavyInfantry_1",
                "target": "Building_FeudalAge_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "33000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_HalberdMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-430000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_KingsGuard",
                    "level": 73
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4110,
            "effect": 0.028
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_HeavyInfantry_2",
                "target": "Building_FeudalAge_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "34500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_PolearmManeuvers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-460000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2295",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_KingsGuard",
                    "level": 79
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "440000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4290,
            "effect": 0.028
        },
        "level": 24,
        "assetId": "Building_FeudalAge_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_KingdomOfSicily_CultureSite_Large_1",
                "range": 3,
                "points": 1520
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_CultureSite_Large_1",
                "duration": "21000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Piazza"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6300000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "690000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "largeCulture",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Home_Average_2",
                "target": "Building_KingdomOfSicily_Home_Average_3",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Monastries"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-130000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-740",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1110",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 3440,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 32
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Home_Average_1",
                "duration": "12900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ImprovedRoofTiles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1960",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1960",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1960",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Home_Average_1",
                "target": "Building_KingdomOfSicily_Home_Average_2",
                "autoFinish": true,
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Pavillons"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-730",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1095",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 3300,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 31
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 3580,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Workshop_StoneMason_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Workshop_StoneMason_1",
                "duration": "1800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "marble_bust"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-28000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-95",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Workshop_StoneMason_1",
                "target": "Building_ClassicGreece_Workshop_StoneMason_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "column"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-72000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-465",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Workshop_StoneMason_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "alabaster_idol",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_MinoanEra_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "marble_bust",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Workshop_StoneMason_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "stoneMason",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Rural_2",
                "target": "Building_MinoanEra_Farm_Rural_3",
                "autoFinish": true,
                "duration": "1680s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Winch"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-17000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1050,
            "effects": {
                "food": 0.35
            }
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_StoneAge_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Barracks_Ranged_1",
                "duration": "30s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Slingshots"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Barracks_Ranged_1",
                "target": "Building_BronzeAge_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "60s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Archery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-750",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_StoneAge_Player_Slingers",
                    "level": 5
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "90",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "rangedBarracks",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Rural_3",
                "target": "Building_ClassicGreece_Farm_Rural_1",
                "autoFinish": true,
                "duration": "1920s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-22000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-170",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effects": {
                "food": 0.35
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_KingdomOfSicily_CultureSite_Compact_1",
                "range": 1,
                "points": 800
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_CultureSite_Compact_1",
                "duration": "10500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-810000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "89000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "compactCulture",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Farm_Rural_1",
                "duration": "3300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_OatProduction"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-22000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-95",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Rural_1",
                "target": "Building_MinoanEra_Farm_Rural_2",
                "autoFinish": true,
                "duration": "1380s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Millstone"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-14000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-245",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1020,
            "effects": {
                "food": 0.35
            }
        },
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Melites",
                    "level": 125
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "850000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 5540,
            "effect": 0.028
        },
        "level": 31,
        "assetId": "Building_KingdomOfSicily_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Domestic_2",
                "target": "Building_IberianEra_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_DonkeyFarms"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-330000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1785",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "19600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "340000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3360,
            "effects": {
                "food": 0.981
            }
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Cavalry_2",
                "target": "Building_KingdomOfSicily_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "40800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_TerrainMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1610",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Melites",
                    "level": 117
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "770000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 5360,
            "effect": 0.028
        },
        "level": 30,
        "assetId": "Building_KingdomOfSicily_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Domestic_3",
                "target": "Building_KingdomOfSicily_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-360000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1005",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1005",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1005",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "20600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "380000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3450,
            "effects": {
                "food": 0.981
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ByzantineEra_CultureSite_Premium_1",
                "range": 2,
                "points": 1640
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ByzantineEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-860",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_CultureSite_Premium_1",
                "target": "Building_AgeOfTheFranks_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "120000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "premiumCulture",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Barracks_Cavalry_1",
                "duration": "59100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Melites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-4275",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-4275",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-4275",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Cavalry_1",
                "target": "Building_KingdomOfSicily_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "39300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_CouchedSpearTechnique"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-650000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1590",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Melites",
                    "level": 109
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "700000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 5180,
            "effect": 0.028
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Special_HeroAcademy_1",
                "duration": "36300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-3235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-3235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-3235",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Special_HeroAcademy_1",
                "target": "Building_KingdomOfSicily_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "31500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1210000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1585",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1585",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1585",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_IberianEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "12000s",
                "minCollectionPeriod": "12000s",
                "skipPricePerMinute": 0.25,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_IberianEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "12000s",
                "minCollectionPeriod": "12000s",
                "skipPricePerMinute": 0.5,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_IberianEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "12000s",
                "minCollectionPeriod": "12000s",
                "skipPricePerMinute": 0.75,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "2700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "380000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "IberianEra",
        "group": "heroAcademy",
        "level": 8,
        "assetId": "Building_KingdomOfSicily_Special_HeroAcademy_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Barracks_Ranged_1",
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_CompositeBow"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-42000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-165",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-165",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-165",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Ranged_1",
                "target": "Building_MinoanEra_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_HawkeyeVision"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-29000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-425",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CompositeArchers",
                    "level": 18
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1560,
            "effect": 0.016
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Ranged_2",
                "target": "Building_MinoanEra_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_CretanQuivers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-34000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-495",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CompositeArchers",
                    "level": 19
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effect": 0.018
        },
        "level": 6,
        "assetId": "Building_MinoanEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Ranged_3",
                "target": "Building_ClassicGreece_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Toxotai"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-43000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CompositeArchers",
                    "level": 20
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effect": 0.02
        },
        "level": 7,
        "assetId": "Building_MinoanEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ByzantineEra_CultureSite_Large_1",
                "range": 3,
                "points": 760
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_CultureSite_Large_1",
                "duration": "12300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Architekton"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1400000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_CultureSite_Large_1",
                "target": "Building_AgeOfTheFranks_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "10800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_ProductionBoom"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-840000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "170000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "largeCulture",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "duration": "27900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-2215",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-2215",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-2215",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "target": "Building_FeudalAge_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "24300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-780000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1235",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "15000s",
                "minCollectionPeriod": "15000s",
                "skipPricePerMinute": 0.2,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "650",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "15000s",
                "minCollectionPeriod": "15000s",
                "skipPricePerMinute": 0.4,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "AgeOfTheFranks",
        "group": "heroAcademy",
        "level": 6,
        "assetId": "Building_FeudalAge_Special_HeroAcademy_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Workshop_SpiceMerchant_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "duration": "13500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "herbs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3800000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-2530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "target": "Building_IberianEra_Workshop_SpiceMerchant_1",
                "autoFinish": true,
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "saffron"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-2065",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pepper",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "salt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "herbs",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Workshop_SpiceMerchant_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "430000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "spiceMerchant",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Farm_Domestic_1",
                "duration": "12600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_DomesticatedDonkeys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2800000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-3160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-3160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-3160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Farm_Domestic_1",
                "target": "Building_IberianEra_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_DonkeyHerds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1765",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "18700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "310000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 3270,
            "effects": {
                "food": 0.981
            }
        },
        "level": 28
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "cityHall",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Barracks_Infantry_1",
                "duration": "9600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Arenas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-40000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Infantry_1",
                "target": "Building_MinoanEra_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_GlyphicWarding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-27000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-405",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CopisWarriors",
                    "level": 18
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1560,
            "effect": 0.016
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_9",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_9"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_9",
                "target": "Building_BronzeAge_Collectable_SchoolV2_10",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-75",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_9",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_9"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_9",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "5"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9_4"
                                            },
                                            "resource": "research_points",
                                            "amount": "7"
                                        }
                                    ],
                                    "chances": [
                                        30,
                                        40,
                                        25,
                                        5
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_9"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_9",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_DawnAge_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_DawnAge_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_DawnAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_DawnAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_DawnAge_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "50",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "DawnAge",
        "group": "cityHall",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_10",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_10"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_10",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_10"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_10",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_10",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_4",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_4",
                "target": "Building_BronzeAge_Collectable_SchoolV2_5",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_4",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_4",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-30000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "2"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        }
                                    ],
                                    "chances": [
                                        65,
                                        25,
                                        10
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_4"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Siege_3",
                "target": "Building_IberianEra_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "37800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_AdvancedTrebuchet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-510000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1420",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Trebuchet",
                    "level": 84
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "520000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 4460,
            "effect": 0.028
        },
        "level": 25,
        "assetId": "Building_FeudalAge_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Home_Average_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Carpentry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-28000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-430",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-430",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-430",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Average_1",
                "target": "Building_ClassicGreece_Home_Average_2",
                "autoFinish": true,
                "duration": "2400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Philosophy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-12000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-195",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1370",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1140,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_3",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_3",
                "target": "Building_BronzeAge_Collectable_SchoolV2_4",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-45",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_3",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_3",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-15000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_3",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_3_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_3_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "2"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_3_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        }
                                    ],
                                    "chances": [
                                        75,
                                        25
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_3"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Siege_2",
                "target": "Building_FeudalAge_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "36300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_TrebuchetCommand"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1685",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Trebuchet",
                    "level": 79
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "470000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 4290,
            "effect": 0.028
        },
        "level": 24,
        "assetId": "Building_FeudalAge_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Average_2",
                "target": "Building_ClassicGreece_Home_Average_3",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Concrete"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-16000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-215",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-325",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1460",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1200,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_2",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_2",
                "target": "Building_BronzeAge_Collectable_SchoolV2_3",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_2",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_2",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_2",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_2_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_2_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "2"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_2_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        }
                                    ],
                                    "chances": [
                                        90,
                                        10
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_2"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_AgeOfTheFranks_CultureSite_Little_1",
                "range": 1,
                "points": 260
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_CultureSite_Little_1",
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Aristocracy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_CultureSite_Little_1",
                "target": "Building_FeudalAge_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Manorialism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-64000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "littleCulture",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Average_3",
                "target": "Building_EarlyRome_Home_Average_1",
                "autoFinish": true,
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Insulae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-19000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-235",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-235",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1570",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1250,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_1",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_SchoolV2_1",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_1",
                "target": "Building_BronzeAge_Collectable_SchoolV2_2",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-35",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_1",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_1",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_1",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_1_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_1_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "1"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_1_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "2"
                                        }
                                    ],
                                    "chances": [
                                        95,
                                        5
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_1"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_8",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_8"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_8",
                "target": "Building_BronzeAge_Collectable_SchoolV2_9",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-70",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_8",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_8"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_8",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "5"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8_4"
                                            },
                                            "resource": "research_points",
                                            "amount": "7"
                                        }
                                    ],
                                    "chances": [
                                        40,
                                        35,
                                        20,
                                        5
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_8"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_8",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_7",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_7"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_7",
                "target": "Building_BronzeAge_Collectable_SchoolV2_8",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-65",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_7",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_7"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_7",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-75000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "5"
                                        }
                                    ],
                                    "chances": [
                                        50,
                                        30,
                                        20
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_7"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_7",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_6",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_6",
                "target": "Building_BronzeAge_Collectable_SchoolV2_7",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_6",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_6",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-55000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "5"
                                        }
                                    ],
                                    "chances": [
                                        60,
                                        25,
                                        15
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_6"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Barracks_Siege_1",
                "duration": "52200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Trebuchets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-3740",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-3740",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-3740",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Siege_1",
                "target": "Building_FeudalAge_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "34800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_BattlefieldArtillery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-450000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2495",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1660",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Trebuchet",
                    "level": 73
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "420000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 4110,
            "effect": 0.028
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Infantry_2",
                "target": "Building_MinoanEra_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_LionheartedInfantry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-32000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-475",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CopisWarriors",
                    "level": 19
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effect": 0.018
        },
        "level": 6,
        "assetId": "Building_MinoanEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_SchoolV2_5",
        "type": "collectable",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_SchoolV2_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_SchoolV2_5",
                "target": "Building_BronzeAge_Collectable_SchoolV2_6",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-55",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_SchoolV2_5",
                "duration": "7200s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_SchoolV2_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_BronzeAge_Collectable_SchoolV2_5",
                "type": "ProductionType_WORKER",
                "duration": "28800s",
                "minCollectionPeriod": "28800s",
                "skipPricePerMinute": 0.3,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-40000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/RewardDefinitionDTO",
                            "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5",
                            "rewards": [
                                {
                                    "@type": "type.googleapis.com/MysteryChestRewardDTO",
                                    "baseData": {
                                        "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5_Id"
                                    },
                                    "rewards": [
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5_1"
                                            },
                                            "resource": "research_points",
                                            "amount": "2"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5_2"
                                            },
                                            "resource": "research_points",
                                            "amount": "3"
                                        },
                                        {
                                            "@type": "type.googleapis.com/ResourceRewardDTO",
                                            "baseData": {
                                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5_3"
                                            },
                                            "resource": "research_points",
                                            "amount": "4"
                                        }
                                    ],
                                    "chances": [
                                        60,
                                        25,
                                        15
                                    ],
                                    "assetId": "icon_chest_rp",
                                    "randomSeedCounter": "default"
                                }
                            ],
                            "baseData": {
                                "id": "Reward_Building_BronzeAge_Collectable_SchoolV2_1_5"
                            }
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_SchoolV2_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_School_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "BronzeAge",
        "group": "collectableSchoolV2",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Infantry_3",
                "target": "Building_ClassicGreece_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Psiloi"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-41000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-285",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-285",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_CopisWarriors",
                    "level": 20
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effect": 0.02
        },
        "level": 7,
        "assetId": "Building_MinoanEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "12500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "cityHall",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Cavalry_3",
                "target": "Building_EarlyRome_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "13800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_AuxillaRiders"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Cataphract",
                    "level": 25
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effect": 0.024
        },
        "level": 10,
        "assetId": "Building_ClassicGreece_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Cavalry_2",
                "target": "Building_ClassicGreece_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "12600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_FlankAssault"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-89000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-710",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Cataphract",
                    "level": 23
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "31000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effect": 0.023
        },
        "level": 9,
        "assetId": "Building_ClassicGreece_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_StoneAge_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "StoneAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-490",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Home_Premium_1",
                "target": "Building_BronzeAge_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 330,
            "effects": {
                "coins": 0.126
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Barracks_Cavalry_1",
                "duration": "16800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Cataphract"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-925",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-925",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-925",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Cavalry_1",
                "target": "Building_ClassicGreece_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "11100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_ImprovedSaddles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-66000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-635",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Cataphract",
                    "level": 22
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1900,
            "effect": 0.021
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "duration": "17100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "chili"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-6455",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pepper",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "salt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "herbs",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "saffron",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "chili",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Workshop_SpiceMerchant_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "790000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "spiceMerchant",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Workshop_StoneMason_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Workshop_StoneMason_1",
                "duration": "960s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "alabaster_idol"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Workshop_StoneMason_1",
                "target": "Building_MinoanEra_Workshop_StoneMason_1",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "marble_bust"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-26600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-85",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Workshop_StoneMason_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "alabaster_idol",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Workshop_StoneMason_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "stoneMason",
        "happinessEffect": {
            "maxHappiness": 600,
            "effect": 0.015
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "BronzeAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1050",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Premium_1",
                "target": "Building_MinoanEra_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 750,
            "effects": {
                "food": 0.727
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Ranged_2",
                "target": "Building_ClassicGreece_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_ImprovedFletching"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-85000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Toxotai",
                    "level": 23
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "30000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effect": 0.023
        },
        "level": 9,
        "assetId": "Building_ClassicGreece_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Barracks_Ranged_1",
                "duration": "15900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Toxotai"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-890",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-890",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-890",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Ranged_1",
                "target": "Building_ClassicGreece_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "10500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_WindPrediction"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-63000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-405",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-605",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Toxotai",
                    "level": 22
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "21000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 1900,
            "effect": 0.021
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Workshop_Artisan_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Workshop_Artisan_1",
                "duration": "1800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "iron_pendant"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-28000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-95",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Workshop_Artisan_1",
                "target": "Building_ClassicGreece_Workshop_Artisan_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "silver_ring"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-72000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-465",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Workshop_Artisan_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "bronze_bracelet",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_MinoanEra_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "iron_pendant",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Workshop_Artisan_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "artisan",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Barracks_HeavyInfantry_1",
                "duration": "11400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_HeavyInfantry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-46000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-170",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_HeavyInfantry_1",
                "target": "Building_MinoanEra_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_MysticBattleChants"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-32000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-450",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_HeavyInfantry",
                    "level": 18
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1560,
            "effect": 0.016
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_HeavyInfantry_2",
                "target": "Building_MinoanEra_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_CeramicArmorcraft"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-37000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_HeavyInfantry",
                    "level": 19
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effect": 0.018
        },
        "level": 6,
        "assetId": "Building_MinoanEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_HeavyInfantry_2",
                "target": "Building_EarlyRome_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "17400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_TerrainMapping"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1155",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Triarii",
                    "level": 29
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "73000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2310,
            "effect": 0.026
        },
        "level": 12,
        "assetId": "Building_EarlyRome_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Barracks_HeavyInfantry_1",
                "duration": "24000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Triarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-440000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1410",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1410",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1410",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_HeavyInfantry_1",
                "target": "Building_EarlyRome_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "15900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_FormationDrill"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-130000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1050",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Triarii",
                    "level": 27
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "57000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2290,
            "effect": 0.024
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Ranged_3",
                "target": "Building_EarlyRome_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Velites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-490",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-490",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-490",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Toxotai",
                    "level": 25
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "40000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effect": 0.024
        },
        "level": 10,
        "assetId": "Building_ClassicGreece_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_HeavyInfantry_3",
                "target": "Building_RomanEmpire_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "18900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Legionary"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Triarii",
                    "level": 31
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "91000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2410,
            "effect": 0.027
        },
        "level": 13,
        "assetId": "Building_EarlyRome_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_AgeOfTheFranks_CultureSite_Premium_1",
                "range": 2,
                "points": 1920
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "AgeOfTheFranks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-950",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_CultureSite_Premium_1",
                "target": "Building_FeudalAge_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "160000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "premiumCulture",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_HeavyInfantry_3",
                "target": "Building_ClassicGreece_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Hoplites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-47000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-315",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-315",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_HeavyInfantry",
                    "level": 20
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effect": 0.02
        },
        "level": 7,
        "assetId": "Building_MinoanEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Special_HeroAcademy_1",
                "duration": "23700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2255",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2255",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2255",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Special_HeroAcademy_1",
                "target": "Building_AgeOfTheFranks_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "20700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-630000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ByzantineEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "16200s",
                "minCollectionPeriod": "16200s",
                "skipPricePerMinute": 0.1852,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_ByzantineEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "16200s",
                "minCollectionPeriod": "16200s",
                "skipPricePerMinute": 0.3704,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1250",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "ByzantineEra",
        "group": "heroAcademy",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_IberianEra_CultureSite_Premium_1",
                "range": 2,
                "points": 2800
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "IberianEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_CultureSite_Premium_1",
                "target": "Building_KingdomOfSicily_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "premiumCulture",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Workshop_Carpenter_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "cartwheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "target": "Building_FeudalAge_Workshop_Carpenter_1",
                "autoFinish": true,
                "duration": "10200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "barrel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1665",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1665",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "planks",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "cartwheel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "carpenter",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Domestic_2",
                "target": "Building_MinoanEra_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "2040s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Winch"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-21000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-395",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2780",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1170,
            "effects": {
                "food": 0.404
            }
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "BronzeAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-540",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Premium_1",
                "target": "Building_MinoanEra_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 610,
            "effects": {
                "coins": 0.546
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Domestic_3",
                "target": "Building_ClassicGreece_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "2340s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_DomesticPigs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-27000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-240",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2920",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1220,
            "effects": {
                "food": 0.404
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Farm_Domestic_1",
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Shepherding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-26000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-130",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-130",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Domestic_1",
                "target": "Building_MinoanEra_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "1680s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_AnimalHusbandry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-18000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-340",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-225",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2650",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1110,
            "effects": {
                "food": 0.404
            }
        },
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Home_Small_1",
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Township"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-30",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-30",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Small_1",
                "target": "Building_MinoanEra_Home_Small_2",
                "autoFinish": true,
                "duration": "1260s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_BasketWeaving"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2300",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-54",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-81",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "950",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 870,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Rural_2",
                "target": "Building_StoneAge_Farm_Rural_3",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_TheWheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "24",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 440,
            "effects": {
                "food": 0.35
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Rural_3",
                "target": "Building_BronzeAge_Farm_Rural_1",
                "autoFinish": true,
                "duration": "240s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Village"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "94",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 540,
            "effects": {
                "food": 0.35
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_StoneAge_CultureSite_Moderate_1",
                "range": 2,
                "points": 240
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_CultureSite_Moderate_1",
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_CavePaintings"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-25",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_CultureSite_Moderate_1",
                "target": "Building_BronzeAge_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SocialChange"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "8",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "moderateCulture",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Farm_Rural_1",
                "duration": "10s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Cultivation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Rural_1",
                "target": "Building_StoneAge_Farm_Rural_2",
                "autoFinish": true,
                "duration": "35s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Agriculture"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "45s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 350,
            "effects": {
                "food": 0.35
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Cavalry_2",
                "target": "Building_RomanEmpire_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "20400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_HorseshoeAlloys"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-190000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1435",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-955",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Turmae",
                    "level": 37
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "120000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2780,
            "effect": 0.027
        },
        "level": 15,
        "assetId": "Building_RomanEmpire_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Cavalry_3",
                "target": "Building_ByzantineEra_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "21900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Trapezites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-220000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-845",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-845",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-845",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Turmae",
                    "level": 40
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effect": 0.028
        },
        "level": 16,
        "assetId": "Building_RomanEmpire_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Workshop_SpiceMerchant_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Workshop_SpiceMerchant_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "pepper"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2355",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2355",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2355",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Workshop_SpiceMerchant_1",
                "target": "Building_AgeOfTheFranks_Workshop_SpiceMerchant_1",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "salt"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-960000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-635",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Workshop_SpiceMerchant_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Workshop_SpiceMerchant_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "pepper",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Workshop_SpiceMerchant_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "spiceMerchant",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Barracks_Cavalry_1",
                "duration": "28800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Turmae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-870000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2075",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2075",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2075",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Cavalry_1",
                "target": "Building_RomanEmpire_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "19200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_ImprovedStirrups"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-890",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1335",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Turmae",
                    "level": 34
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2590,
            "effect": 0.027
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Siege_2",
                "target": "Building_ClassicGreece_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "13800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_AdvancedProjectiles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-98000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-795",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Siege",
                    "level": 23
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "34000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effect": 0.023
        },
        "level": 9,
        "assetId": "Building_ClassicGreece_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Barracks_Siege_1",
                "duration": "18600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Siege_1",
                "target": "Building_ClassicGreece_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "12300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_SwiftReload"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-73000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-475",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-710",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Siege",
                    "level": 22
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "24000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1900,
            "effect": 0.021
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Cavalry_2",
                "target": "Building_MinoanEra_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_DivinatoryStrategy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-35000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-515",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Hippeis",
                    "level": 19
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effect": 0.018
        },
        "level": 6,
        "assetId": "Building_MinoanEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Home_Small_1",
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-31000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Small_1",
                "target": "Building_EarlyRome_Home_Small_2",
                "autoFinish": true,
                "duration": "2640s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Marketplaces"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-9500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1270,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 13
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Barracks_Cavalry_1",
                "duration": "10800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Stables"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-44000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-170",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Cavalry_1",
                "target": "Building_MinoanEra_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_EquestrianArtistry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-30000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-440",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-295",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Hippeis",
                    "level": 18
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1560,
            "effect": 0.016
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Small_3",
                "target": "Building_RomanEmpire_Home_Small_1",
                "autoFinish": true,
                "duration": "3120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-13000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-125",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1370,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Siege_3",
                "target": "Building_EarlyRome_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "15300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-575",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-575",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-575",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Siege",
                    "level": 25
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "46000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effect": 0.024
        },
        "level": 10,
        "assetId": "Building_ClassicGreece_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_MinoanEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_MinoanEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Barracks_Cavalry_3",
                "target": "Building_ClassicGreece_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "9900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Cataphract"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-45000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-310",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-310",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-310",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_MinoanEra_Player_Hippeis",
                    "level": 20
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1750,
            "effect": 0.02
        },
        "level": 7,
        "assetId": "Building_MinoanEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Home_Small_2",
                "target": "Building_EarlyRome_Home_Small_3",
                "autoFinish": true,
                "duration": "2880s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_RomanProvidence"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-11000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-205",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 1320,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Special_HeroAcademy_1",
                "duration": "19800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_UrbsAeterna"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-630000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1625",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1625",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1625",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Special_HeroAcademy_1",
                "target": "Building_ByzantineEra_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "17100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Byzantium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-430000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-975",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-975",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-975",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_RomanEmpire_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "18000s",
                "minCollectionPeriod": "18000s",
                "skipPricePerMinute": 0.1667,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "540",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_RomanEmpire_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "18000s",
                "minCollectionPeriod": "18000s",
                "skipPricePerMinute": 0.3334,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "RomanEmpire",
        "group": "heroAcademy",
        "level": 4,
        "assetId": "Building_ByzantineEra_Special_HeroAcademy_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Barracks_Infantry_1",
                "duration": "36300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Skirmishers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-1160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-1160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-1160",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Infantry_1",
                "target": "Building_AgeOfTheFranks_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "24300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_ArmorAdaptation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-860",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Skirmishers",
                    "level": 56
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "240000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3570,
            "effect": 0.028
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Infantry_2",
                "target": "Building_AgeOfTheFranks_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "25500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_BattleAxeMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-320000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1130",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Skirmishers",
                    "level": 61
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "270000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3750,
            "effect": 0.028
        },
        "level": 21,
        "assetId": "Building_AgeOfTheFranks_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Infantry_3",
                "target": "Building_FeudalAge_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "26700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Axemen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-340000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1120",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_Skirmishers",
                    "level": 66
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3930,
            "effect": 0.028
        },
        "level": 22,
        "assetId": "Building_AgeOfTheFranks_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Ranged_2",
                "target": "Building_FeudalAge_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "30900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_SinewBacking"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-410000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1440",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2155",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Longbowmen",
                    "level": 79
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 4290,
            "effect": 0.028
        },
        "level": 24,
        "assetId": "Building_FeudalAge_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Domestic_3",
                "target": "Building_BronzeAge_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "240s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_FeedTrough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 580,
            "effects": {
                "food": 0.404
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Ranged_3",
                "target": "Building_IberianEra_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "32100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Crossbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-440000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1215",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1215",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1215",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Longbowmen",
                    "level": 84
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "440000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 4460,
            "effect": 0.028
        },
        "level": 25,
        "assetId": "Building_FeudalAge_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "FeudalAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-2275",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Premium_1",
                "target": "Building_IberianEra_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "31000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 2600,
            "effects": {
                "food": 1.601
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Domestic_2",
                "target": "Building_StoneAge_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "150s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_DomesticatedGoats"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "830",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "30",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 490,
            "effects": {
                "food": 0.404
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Farm_Domestic_1",
                "duration": "15s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_GoatPens"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-20",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Domestic_1",
                "target": "Building_StoneAge_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "45s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Herding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 400,
            "effects": {
                "food": 0.404
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Barracks_Ranged_1",
                "duration": "44400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Longbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-3190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-3190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-3190",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Ranged_1",
                "target": "Building_FeudalAge_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "29700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_HeavyDrawWeight"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-390000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2130",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1420",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Longbowmen",
                    "level": 73
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "360000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 4110,
            "effect": 0.028
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Farm_Rural_1",
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-695",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Rural_1",
                "target": "Building_AgeOfTheFranks_Farm_Rural_2",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Spades"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-775",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-515",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "130000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2230,
            "effects": {
                "food": 0.62
            }
        },
        "level": 22
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Rural_3",
                "target": "Building_FeudalAge_Farm_Rural_1",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-670",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-670",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-670",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "160000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2360,
            "effects": {
                "food": 0.62
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Rural_2",
                "target": "Building_AgeOfTheFranks_Farm_Rural_3",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Scythes"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1015",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2300,
            "effects": {
                "food": 0.62
            }
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_HeavyInfantry_2",
                "target": "Building_ByzantineEra_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "25800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_ChainmailReinforcement"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-430",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-640",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Jovians",
                    "level": 48
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "210000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3210,
            "effect": 0.028
        },
        "level": 18,
        "assetId": "Building_ByzantineEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "14500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "cityHall",
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "duration": "36900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Jovians"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1500000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2675",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2675",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2675",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "target": "Building_ByzantineEra_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "24600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_CounterCavalryTactics"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-270000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-520",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Jovians",
                    "level": 45
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3040,
            "effect": 0.028
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_HeavyInfantry_3",
                "target": "Building_AgeOfTheFranks_Barracks_HeavyInfantry_1",
                "autoFinish": true,
                "duration": "27300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Halberds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-330000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Jovians",
                    "level": 51
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "240000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effect": 0.028
        },
        "level": 19,
        "assetId": "Building_ByzantineEra_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Workshop_Tailor_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Workshop_Tailor_1",
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "cape"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-560000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Workshop_Tailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wool",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_RomanEmpire_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "linen_shirt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_RomanEmpire_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "toga",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_RomanEmpire_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "tunic",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_RomanEmpire_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "cape",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Workshop_Tailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "68000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "tailor",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Workshop_Scribe_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Workshop_Scribe_1",
                "duration": "15300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "wax_seal"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Workshop_Scribe_1",
                "target": "Building_KingdomOfSicily_Workshop_Scribe_1",
                "autoFinish": true,
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "tome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1860000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-2140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Workshop_Scribe_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "parchment",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_IberianEra_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ink",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_IberianEra_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "manuscript",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_IberianEra_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wax_seal",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Workshop_Scribe_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "580000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "scribe",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ClassicGreece_CultureSite_Moderate_1",
                "range": 2,
                "points": 440
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_CultureSite_Moderate_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_DomesticPigs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-62000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_CultureSite_Moderate_1",
                "target": "Building_EarlyRome_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "3240s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Insulae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-106000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "8900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "moderateCulture",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_StoneAge_CultureSite_Premium_1",
                "range": 2,
                "points": 560
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_CavePaintings"
                        },
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "StoneAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-320",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_CultureSite_Premium_1",
                "target": "Building_BronzeAge_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "premiumCulture",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Cavalry_3",
                "target": "Building_IberianEra_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "33900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Jinete"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-470000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1265",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1265",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1265",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Knights",
                    "level": 84
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "470000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 4460,
            "effect": 0.028
        },
        "level": 25,
        "assetId": "Building_FeudalAge_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Cavalry_2",
                "target": "Building_FeudalAge_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "32700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_KnightTraining"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-440000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1500",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Knights",
                    "level": 79
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "420000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 4290,
            "effect": 0.028
        },
        "level": 24,
        "assetId": "Building_FeudalAge_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "StoneAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-875",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Farm_Premium_1",
                "target": "Building_BronzeAge_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "StoneAge",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 430,
            "effects": {
                "food": 0.727
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_FeudalAge_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_FeudalAge_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Barracks_Cavalry_1",
                "duration": "46800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Knights"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-3330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-3330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-3330",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Barracks_Cavalry_1",
                "target": "Building_FeudalAge_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "31200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_PlateBarding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-410000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2225",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1480",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_FeudalAge_Player_Knights",
                    "level": 73
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "380000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 4110,
            "effect": 0.028
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Farm_Rural_1",
                "duration": "3300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-75000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Rural_1",
                "target": "Building_ClassicGreece_Farm_Rural_2",
                "autoFinish": true,
                "duration": "2160s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_CropRotation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-32000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1180,
            "effects": {
                "food": 0.38
            }
        },
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Rural_2",
                "target": "Building_ClassicGreece_Farm_Rural_3",
                "autoFinish": true,
                "duration": "2460s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_HerbalAromas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-43000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "15000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1240,
            "effects": {
                "food": 0.38
            }
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Barracks_Infantry_1",
                "duration": "47100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Almogavars"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-3785",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-3785",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-3785",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Infantry_1",
                "target": "Building_IberianEra_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "31500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_LightweightShields"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-460000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1410",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2115",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Almogavars",
                    "level": 91
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "470000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4640,
            "effect": 0.028
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Farm_Rural_3",
                "target": "Building_EarlyRome_Farm_Rural_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Municipium"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-51000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-280",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-280",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "20000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 1300,
            "effects": {
                "food": 0.38
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Infantry_3",
                "target": "Building_KingdomOfSicily_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "33900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Pedites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-540000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1205",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1205",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1205",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Almogavars",
                    "level": 101
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "570000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5000,
            "effect": 0.028
        },
        "level": 28,
        "assetId": "Building_IberianEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Infantry_2",
                "target": "Building_IberianEra_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "32700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_LeatherLappets"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-490000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1430",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Almogavars",
                    "level": 96
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "510000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 4820,
            "effect": 0.028
        },
        "level": 27,
        "assetId": "Building_IberianEra_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Home_Average_1",
                "duration": "9000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Villers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-585",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-585",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-585",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Average_1",
                "target": "Building_AgeOfTheFranks_Home_Average_2",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_PotteryWheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-60000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-430",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-645",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "48000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2130,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 22
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Average_3",
                "target": "Building_FeudalAge_Home_Average_1",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_MythsAndLegends"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-69000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-560",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-560",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-560",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "61000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2270,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Average_2",
                "target": "Building_AgeOfTheFranks_Home_Average_3",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_LegesSalica"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-64000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-565",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "54000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 2200,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "6850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "6850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "cityHall",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_IberianEra_CultureSite_Little_1",
                "range": 1,
                "points": 350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_CultureSite_Little_1",
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Door"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-280000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_CultureSite_Little_1",
                "target": "Building_KingdomOfSicily_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_PolychromeInlays"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-99000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "31000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "littleCulture",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Workshop_Tailor_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Workshop_Tailor_1",
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "toga"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-605",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Workshop_Tailor_1",
                "target": "Building_EarlyRome_Workshop_Tailor_1",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "tunic"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-171000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-690",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Workshop_Tailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wool",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_ClassicGreece_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "linen_shirt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_ClassicGreece_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "toga",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Workshop_Tailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "tailor",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Workshop_Artisan_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Workshop_Artisan_1",
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "silver_ring"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-605",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Workshop_Artisan_1",
                "target": "Building_EarlyRome_Workshop_Artisan_1",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "gold_laurel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-171000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-690",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Workshop_Artisan_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "bronze_bracelet",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_ClassicGreece_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "iron_pendant",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_ClassicGreece_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silver_ring",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Workshop_Artisan_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "artisan",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Workshop_Tailor_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Workshop_Tailor_1",
                "duration": "960s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "wool"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Workshop_Tailor_1",
                "target": "Building_MinoanEra_Workshop_Tailor_1",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "linen_shirt"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-26600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-85",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Workshop_Tailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wool",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Workshop_Tailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "tailor",
        "happinessEffect": {
            "maxHappiness": 600,
            "effect": 0.015
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Workshop_StoneMason_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Workshop_StoneMason_1",
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "stone_tablet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-270000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1365",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Workshop_StoneMason_1",
                "target": "Building_RomanEmpire_Workshop_StoneMason_1",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "mosaic"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-288000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Workshop_StoneMason_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "alabaster_idol",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_EarlyRome_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "marble_bust",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_EarlyRome_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "column",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_EarlyRome_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stone_tablet",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Workshop_StoneMason_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "stoneMason",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Barracks_Ranged_1",
                "duration": "38700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_FrancArchers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-1210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-1210",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-1210",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Ranged_1",
                "target": "Building_AgeOfTheFranks_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "25800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_TacticalHurling"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-320000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1345",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_FrancArchers",
                    "level": 56
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "250000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3570,
            "effect": 0.028
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Ranged_3",
                "target": "Building_ByzantineEra_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "20700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_SaracenArchers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-210000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-810",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-810",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-810",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Sagittarii",
                    "level": 40
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2860,
            "effect": 0.028
        },
        "level": 16,
        "assetId": "Building_RomanEmpire_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Ranged_3",
                "target": "Building_FeudalAge_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "28200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Longbowmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-360000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1170",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_FrancArchers",
                    "level": 66
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "320000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3930,
            "effect": 0.028
        },
        "level": 22,
        "assetId": "Building_AgeOfTheFranks_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Ranged_2",
                "target": "Building_RomanEmpire_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "19500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_CenturionsAim"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1375",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-915",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Sagittarii",
                    "level": 37
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "120000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2780,
            "effect": 0.027
        },
        "level": 15,
        "assetId": "Building_RomanEmpire_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_AgeOfTheFranks_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_AgeOfTheFranks_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Barracks_Ranged_2",
                "target": "Building_AgeOfTheFranks_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "27000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_ReinforcedHandles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-340000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_AgeOfTheFranks_Player_FrancArchers",
                    "level": 61
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3750,
            "effect": 0.028
        },
        "level": 21,
        "assetId": "Building_AgeOfTheFranks_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_RomanEmpire_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_RomanEmpire_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Barracks_Ranged_1",
                "duration": "27300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Sagittarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-820000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1985",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1985",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1985",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Barracks_Ranged_1",
                "target": "Building_RomanEmpire_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "18000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_AlloyedArrowheads"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-855",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_RomanEmpire_Player_Sagittarii",
                    "level": 34
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "99000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2590,
            "effect": 0.027
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Workshop_Carpenter_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Workshop_Carpenter_1",
                "duration": "15300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "door"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4800",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Workshop_Carpenter_1",
                "target": "Building_KingdomOfSicily_Workshop_Carpenter_1",
                "autoFinish": true,
                "duration": "13200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "wardrobe"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1860000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-2140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Workshop_Carpenter_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "planks",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_IberianEra_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "cartwheel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_IberianEra_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "barrel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_IberianEra_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "door",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Workshop_Carpenter_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "580000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "IberianEra",
        "group": "carpenter",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Barracks_Cavalry_1",
                "duration": "53100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Jinete"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4125",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Cavalry_1",
                "target": "Building_IberianEra_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "35400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_ShockCharges"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-510000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1535",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2305",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Jinete",
                    "level": 91
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "520000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 4640,
            "effect": 0.028
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Cavalry_3",
                "target": "Building_MinoanEra_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Stables"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-22000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-125",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-125",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_CavalrySpearmen",
                    "level": 16
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1430,
            "effect": 0.014
        },
        "level": 4,
        "assetId": "Building_BronzeAge_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Cavalry_2",
                "target": "Building_IberianEra_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "36600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_EquestrianExcellence"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-550000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2330",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1555",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Jinete",
                    "level": 96
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "580000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 4820,
            "effect": 0.028
        },
        "level": 27,
        "assetId": "Building_IberianEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Cavalry_2",
                "target": "Building_BronzeAge_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_ThunderingGallop"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-16000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-44",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-30",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_CavalrySpearmen",
                    "level": 14
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effect": 0.011
        },
        "level": 3,
        "assetId": "Building_BronzeAge_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Workshop_Artisan_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Workshop_Artisan_1",
                "duration": "960s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "bronze_bracelet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Workshop_Artisan_1",
                "target": "Building_MinoanEra_Workshop_Artisan_1",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "iron_pendant"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-26600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-85",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-85",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Workshop_Artisan_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "bronze_bracelet",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Workshop_Artisan_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "artisan",
        "happinessEffect": {
            "maxHappiness": 600,
            "effect": 0.015
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Cavalry_3",
                "target": "Building_KingdomOfSicily_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "38100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Melites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1310",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1310",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1310",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_Jinete",
                    "level": 101
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "640000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 5000,
            "effect": 0.028
        },
        "level": 28,
        "assetId": "Building_IberianEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Barracks_Cavalry_1",
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_CavalrySpearmen"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Cavalry_1",
                "target": "Building_BronzeAge_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_BronzeTippedSpears"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-7",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-11",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_CavalrySpearmen",
                    "level": 12
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effect": 0.009
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Small_3",
                "target": "Building_ClassicGreece_Home_Small_1",
                "autoFinish": true,
                "duration": "1680s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-57",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-57",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-57",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1070",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1200",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 970,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Small_2",
                "target": "Building_MinoanEra_Home_Small_3",
                "autoFinish": true,
                "duration": "1440s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Columns"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-64",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-96",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 920,
            "effects": {
                "coins": 0.355
            }
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_CultureSite_Premium_1",
                "range": 2,
                "points": 680
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "BronzeAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-410",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_CultureSite_Premium_1",
                "target": "Building_MinoanEra_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "premiumCulture",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_SeasonPass_Evolving_GreatGarden_1",
        "type": "evolving",
        "width": 6,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_SeasonPass_Evolving_GreatGarden_1",
                "dynamicAmountDefinitionId": "Dv_Building_SeasonPass_Evolving_GreatGarden_1_Workers"
            },
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_SeasonPass_Evolving_GreatGarden_1",
                "dynamicPointsDefinitionId": "Dv_Building_SeasonPass_Evolving_GreatGarden_1_CultureValues",
                "dynamicRangeDefinitionId": "Dv_Building_SeasonPass_Evolving_GreatGarden_1_CultureRange"
            },
            {
                "@type": "type.googleapis.com/PinnedAgeComponentDTO",
                "id": "PinnedAge_Building_SeasonPass_Evolving_GreatGarden_1"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_SeasonPass_Evolving_GreatGarden_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_SeasonPass_Evolving_GreatGarden_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/LevelUpComponentDTO",
                "id": "LevelUp_Building_SeasonPass_Evolving_GreatGarden_1",
                "duration": "0s",
                "start": {
                    "dynamicChangeDefinitionId": "Dac_Building_SeasonPass_Evolving_GreatGarden_1_UpgradeCosts"
                },
                "starLevels": [
                    10,
                    20,
                    30,
                    40
                ],
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_SeasonPass_Evolving_GreatGarden_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_SeasonPass_Evolving_GreatGarden_1",
                "auto": true,
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "dynamicChangeDefinitionId": "Dac_Building_SeasonPass_Evolving_GreatGarden_1_RP_Chest"
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_SeasonPass_Evolving_GreatGarden_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "EvolutionToken|Building_SeasonPass_Evolving_GreatGarden_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true,
                "keepLevel": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "group": "evolvingGarden",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_CultureSite_Moderate_1",
        "type": "cultureSite",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_EarlyRome_CultureSite_Moderate_1",
                "range": 2,
                "points": 520
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_CultureSite_Moderate_1",
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Insulae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_CultureSite_Moderate_1",
                "target": "Building_RomanEmpire_CultureSite_Moderate_1",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Fountains"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-180000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_CultureSite_Moderate_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_CultureSite_Moderate_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "moderateCulture",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ByzantineEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1925",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Premium_1",
                "target": "Building_AgeOfTheFranks_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "17500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "110000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effects": {
                "food": 1.2
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Workshop_StoneMason_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Workshop_StoneMason_1",
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "mosaic"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-560000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-2415",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-2415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Workshop_StoneMason_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "alabaster_idol",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_RomanEmpire_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "marble_bust",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_RomanEmpire_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "column",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_RomanEmpire_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "stone_tablet",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_RomanEmpire_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "mosaic",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Workshop_StoneMason_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "68000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "RomanEmpire",
        "group": "stoneMason",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ClassicGreece_CultureSite_Compact_1",
                "range": 1,
                "points": 260
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_CultureSite_Compact_1",
                "duration": "3000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Education"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-20000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_CultureSite_Compact_1",
                "target": "Building_EarlyRome_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "2580s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Hastati"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-33400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "compactCulture",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Pedites",
                    "level": 125
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "760000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5540,
            "effect": 0.028
        },
        "level": 31,
        "assetId": "Building_KingdomOfSicily_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Infantry_2",
                "target": "Building_KingdomOfSicily_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "36300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ReinforcedKiteShield"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-630000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1480",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2215",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Pedites",
                    "level": 117
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "690000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5360,
            "effect": 0.028
        },
        "level": 30,
        "assetId": "Building_KingdomOfSicily_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Barracks_Infantry_1",
                "duration": "52500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Pedites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-3925",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-3925",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-3925",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_Infantry_1",
                "target": "Building_KingdomOfSicily_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "35100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_CloseFormationTactics"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-580000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2195",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1460",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_Pedites",
                    "level": 109
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "630000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5180,
            "effect": 0.028
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Barracks_Siege_1",
                "duration": "58800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_AdvancedTrebuchet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-4630",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-4630",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-4630",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Siege_1",
                "target": "Building_IberianEra_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "39300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_BraceMechanism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-560000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1725",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2585",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_AdvancedTrebuchet",
                    "level": 91
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "570000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 4640,
            "effect": 0.028
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Average_2",
                "target": "Building_BronzeAge_Home_Average_3",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_StrongerFrames"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2900",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-14",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-20",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "960",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 780,
            "effects": {
                "coins": 0.208
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Home_Average_1",
                "duration": "840s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SacredStones"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Average_1",
                "target": "Building_BronzeAge_Home_Average_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SocialChange"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-700",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-3",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-5",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 750,
            "effects": {
                "coins": 0.201
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_KingdomOfSicily_CultureSite_Premium_1",
                "range": 2,
                "points": 3360
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "KingdomOfSicily"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1220",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "390000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "premiumCulture",
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Home_Average_3",
                "target": "Building_MinoanEra_Home_Average_1",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Masonry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-57",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-57",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-57",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1030",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 830,
            "effects": {
                "coins": 0.212
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Siege_2",
                "target": "Building_IberianEra_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "40800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_ImprovedCrewArrangement"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-2615",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1745",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_AdvancedTrebuchet",
                    "level": 96
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "630000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 4820,
            "effect": 0.028
        },
        "level": 27,
        "assetId": "Building_IberianEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_RomanEmpire_CultureSite_Compact_1",
                "range": 1,
                "points": 350
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_CultureSite_Compact_1",
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Forums"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-110000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_CultureSite_Compact_1",
                "target": "Building_ByzantineEra_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "4500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Bucellarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-76000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "13000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "compactCulture",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_IberianEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_IberianEra_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Barracks_Siege_3",
                "target": "Building_KingdomOfSicily_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "42300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SiegeTower"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-660000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-1470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-1470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-1470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_IberianEra_Player_AdvancedTrebuchet",
                    "level": 101
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "700000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 5000,
            "effect": 0.028
        },
        "level": 28,
        "assetId": "Building_IberianEra_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Workshop_Carpenter_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Workshop_Carpenter_1",
                "duration": "13500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "barrel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3800000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-2530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Workshop_Carpenter_1",
                "target": "Building_IberianEra_Workshop_Carpenter_1",
                "autoFinish": true,
                "duration": "11700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "door"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1480000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-2065",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-2065",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Workshop_Carpenter_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "planks",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_FeudalAge_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "cartwheel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_FeudalAge_Workshop_Carpenter_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "barrel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Workshop_Carpenter_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "430000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "carpenter",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_BronzeAge_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "3350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "cityHall",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Cavalry_3",
                "target": "Building_RomanEmpire_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "18000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Turmae"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-685",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-685",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-685",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_AuxiliaRiders",
                    "level": 31
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "87000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2410,
            "effect": 0.027
        },
        "level": 13,
        "assetId": "Building_EarlyRome_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_DawnAge_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_DawnAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_DawnAge_Farm_Rural_1",
                "duration": "5s",
                "start": {},
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_DawnAge_Farm_Rural_1",
                "target": "Building_StoneAge_Farm_Rural_1",
                "autoFinish": true,
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Cultivation"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_DawnAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_DawnAge_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "7200s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 1
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "DawnAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 240,
            "effects": {
                "food": 0.35
            }
        }
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Special_HeroAcademy_1",
                "duration": "40200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-4350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-4350",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-4350",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_KingdomOfSicily_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "10800s",
                "minCollectionPeriod": "10800s",
                "skipPricePerMinute": 0.2778,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_KingdomOfSicily_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "10800s",
                "minCollectionPeriod": "10800s",
                "skipPricePerMinute": 0.5556,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1900",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_KingdomOfSicily_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "10800s",
                "minCollectionPeriod": "10800s",
                "skipPricePerMinute": 0.8334,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "3000",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "510000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "KingdomOfSicily",
        "group": "heroAcademy",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "duration": "62400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_FamiliaRegis"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6700000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-4360",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-4360",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-4360",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "target": "Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
                "autoFinish": true,
                "duration": "41400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_RefinedChainmailCoifs"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-680000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2435",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "chili",
                            "amount": "-1625",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_HeavyInfantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_FamiliaRegis",
                    "level": 109
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_HeavyInfantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "730000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5180,
            "effect": 0.028
        },
        "level": 29
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
                "target": "Building_KingdomOfSicily_Barracks_HeavyInfantry_3",
                "autoFinish": true,
                "duration": "42900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_ImprovedHeaterShield"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-740000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wardrobe",
                            "amount": "-1640",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tome",
                            "amount": "-2465",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_HeavyInfantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_FamiliaRegis",
                    "level": 117
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_HeavyInfantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "810000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5360,
            "effect": 0.028
        },
        "level": 30,
        "assetId": "Building_KingdomOfSicily_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Barracks_HeavyInfantry_3",
        "type": "barracks",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_KingdomOfSicily_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_KingdomOfSicily_Barracks_HeavyInfantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Barracks_HeavyInfantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_KingdomOfSicily_Player_FamiliaRegis",
                    "level": 125
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Barracks_HeavyInfantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "890000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "heavyInfantryBarracks",
        "happinessEffect": {
            "maxHappiness": 5540,
            "effect": 0.028
        },
        "level": 31,
        "assetId": "Building_KingdomOfSicily_Barracks_HeavyInfantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ClassicGreece_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "ClassicGreece"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-640",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Home_Premium_1",
                "target": "Building_EarlyRome_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 970,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 12
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "9250",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "9250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "cityHall",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Cavalry_2",
                "target": "Building_EarlyRome_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "16500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_LanceMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-755",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1135",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_AuxiliaRiders",
                    "level": 29
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "70000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2310,
            "effect": 0.026
        },
        "level": 12,
        "assetId": "Building_EarlyRome_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Barracks_Cavalry_1",
                "duration": "22800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_AuxillaRiders"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-420000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1380",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1380",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Cavalry_1",
                "target": "Building_EarlyRome_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "15300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_TacticalCharge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-130000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1030",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-685",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_AuxiliaRiders",
                    "level": 27
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "55000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 2290,
            "effect": 0.024
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Workshop_Tailor_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Workshop_Tailor_1",
                "duration": "1800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "linen_shirt"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-28000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-95",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-95",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Workshop_Tailor_1",
                "target": "Building_ClassicGreece_Workshop_Tailor_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "toga"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-72000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-465",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-465",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Workshop_Tailor_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Workshop_Tailor_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wool",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_MinoanEra_Workshop_Tailor_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "linen_shirt",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Workshop_Tailor_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "tailor",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Home_Small_1",
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-250000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-610",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-610",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-610",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Small_1",
                "target": "Building_FeudalAge_Home_Small_2",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_RepurposedRuins"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-31000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-270",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-410",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "29000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2300,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 25
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Small_2",
                "target": "Building_FeudalAge_Home_Small_3",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_TimberFraming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-33000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-275",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-415",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "32000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2370,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Small_3",
                "target": "Building_IberianEra_Home_Small_1",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-35000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-230",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-230",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2430,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_10",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-75",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_9",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-70",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1500",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_8",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-65",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_7",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-60",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1150",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_6",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-55",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "1000",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_5",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "800",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "MinoanEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1225",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Farm_Premium_1",
                "target": "Building_ClassicGreece_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "5700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "16000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "MinoanEra",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 1050,
            "effects": {
                "food": 0.727
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_4",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-45",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "600",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Cavalry_1",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Cavalry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Barracks_Cavalry_1",
                "duration": "34800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Trapezites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1500000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2620",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2620",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2620",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Cavalry_1",
                "target": "Building_ByzantineEra_Barracks_Cavalry_2",
                "autoFinish": true,
                "duration": "23100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_ByzantineSteeds"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-260000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-510",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-340",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Cavalry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Trapezites",
                    "level": 45
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Cavalry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "170000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3040,
            "effect": 0.028
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Domestic_2",
                "target": "Building_ByzantineEra_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_WaterCistern"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-480",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-320",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "8510",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "120000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2270,
            "effects": {
                "food": 0.635
            }
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Cavalry_2",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Cavalry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Cavalry_2",
                "target": "Building_ByzantineEra_Barracks_Cavalry_3",
                "autoFinish": true,
                "duration": "24600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_ShieldedSaddles"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-280000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-420",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-625",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Cavalry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Trapezites",
                    "level": 48
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Cavalry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3210,
            "effect": 0.028
        },
        "level": 18,
        "assetId": "Building_ByzantineEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Infantry_3",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Infantry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Infantry_3",
                "target": "Building_EarlyRome_Barracks_Infantry_1",
                "autoFinish": true,
                "duration": "12300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Hastati"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-96000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-470",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-470",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Infantry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Psiloi",
                    "level": 25
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Infantry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "37000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effect": 0.024
        },
        "level": 10,
        "assetId": "Building_ClassicGreece_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Domestic_3",
                "target": "Building_AgeOfTheFranks_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Villers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-190000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-395",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-395",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-395",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "8940",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2360,
            "effects": {
                "food": 0.635
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Cavalry_3",
        "type": "barracks",
        "width": 4,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Cavalry_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Cavalry_3",
                "target": "Building_AgeOfTheFranks_Barracks_Cavalry_1",
                "autoFinish": true,
                "duration": "25800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Caballarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-310000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-520",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-520",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-520",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Cavalry_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_Trapezites",
                    "level": 51
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Cavalry_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "230000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "cavalryBarracks",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effect": 0.028
        },
        "level": 19,
        "assetId": "Building_ByzantineEra_Barracks_Cavalry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Infantry_2",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Infantry_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Infantry_2",
                "target": "Building_ClassicGreece_Barracks_Infantry_3",
                "autoFinish": true,
                "duration": "11100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_ReinforcedShields"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-80000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-650",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-435",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Infantry_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Psiloi",
                    "level": 23
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Infantry_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "28000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1950,
            "effect": 0.023
        },
        "level": 9,
        "assetId": "Building_ClassicGreece_Barracks_Infantry_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Domestic_2",
                "target": "Building_BronzeAge_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "1080s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SmallHolding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-9600",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-34",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-22",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1950",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 930,
            "effects": {
                "food": 0.404
            }
        },
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Barracks_Infantry_1",
        "type": "barracks",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ClassicGreece_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ClassicGreece_Barracks_Infantry_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Barracks_Infantry_1",
                "duration": "15000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Psiloi"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Barracks_Infantry_1",
                "target": "Building_ClassicGreece_Barracks_Infantry_2",
                "autoFinish": true,
                "duration": "9900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_AmbushTechniques"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-59000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Barracks_Infantry_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ClassicGreece_Player_Psiloi",
                    "level": 22
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Barracks_Infantry_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "20000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "infantryBarracks",
        "happinessEffect": {
            "maxHappiness": 1900,
            "effect": 0.021
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Farm_Domestic_1",
                "duration": "1080s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_FeedTrough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Domestic_1",
                "target": "Building_BronzeAge_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Barn"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-8",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-6",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1700",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 750,
            "effects": {
                "food": 0.404
            }
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Farm_Premium_1",
        "type": "farm",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Farm_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "AgeOfTheFranks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-2100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Farm_Premium_1",
                "target": "Building_FeudalAge_Farm_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-175",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Farm_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Farm_Premium_1",
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "23500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Farm_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "140000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "AgeOfTheFranks",
        "group": "premiumFarm",
        "happinessEffect": {
            "maxHappiness": 2300,
            "effects": {
                "food": 1.356
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Farm_Domestic_1",
                "duration": "8400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Forquier"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-880000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2005",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2005",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2005",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Farm_Domestic_1",
                "target": "Building_ByzantineEra_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_WheeledPlough"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-390",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "8100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "100000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 2190,
            "effects": {
                "food": 0.635
            }
        },
        "level": 19
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Domestic_2",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Domestic_2",
                "target": "Building_EarlyRome_Farm_Domestic_3",
                "autoFinish": true,
                "duration": "3900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Compost"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-89000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-865",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-580",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Domestic_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Domestic_2",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4860",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Domestic_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1660,
            "effects": {
                "food": 0.495
            }
        },
        "level": 14
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Domestic_3",
                "target": "Building_RomanEmpire_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Swinery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-525",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-525",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "5100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "52000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1720,
            "effects": {
                "food": 0.495
            }
        },
        "level": 15
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ByzantineEra_CultureSite_Compact_1",
                "range": 1,
                "points": 420
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_CultureSite_Compact_1",
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_Bucellarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-190000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_CultureSite_Compact_1",
                "target": "Building_AgeOfTheFranks_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Skirmishers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-109000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "compactCulture",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Farm_Domestic_1",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Farm_Domestic_1",
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_RearLivestock"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-250000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1055",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1055",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1055",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Farm_Domestic_1",
                "target": "Building_EarlyRome_Farm_Domestic_2",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_TributumCapitis"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-76000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-790",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-525",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Farm_Domestic_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Farm_Domestic_1",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "4630",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Farm_Domestic_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "33000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1590,
            "effects": {
                "food": 0.495
            }
        },
        "level": 13
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Farm_Domestic_3",
        "type": "farm",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Farm_Domestic_3",
                "target": "Building_MinoanEra_Farm_Domestic_1",
                "autoFinish": true,
                "duration": "1380s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Shepherding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-13000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-93",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-93",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-93",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Farm_Domestic_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_BronzeAge_Farm_Domestic_3",
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 4
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Farm_Domestic_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2600",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "BronzeAge",
        "group": "domesticFarm",
        "happinessEffect": {
            "maxHappiness": 1040,
            "effects": {
                "food": 0.404
            }
        },
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_ClassicGreece_CultureSite_Large_1",
                "range": 3,
                "points": 440
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_CultureSite_Large_1",
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Temples"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-150000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_CultureSite_Large_1",
                "target": "Building_EarlyRome_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Watchtowers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-263000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "22000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "largeCulture",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_3",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-40",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
        "type": "collectable",
        "width": 2,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 1
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
                "duration": "14400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "BronzeAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
                "target": "Building_ClassicGreece_Collectable_ArchitectsStudioV2_2",
                "autoFinish": true,
                "duration": "3600s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-35",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
                "duration": "14400s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "-10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 5
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
                "type": "ProductionType_WORKER",
                "duration": "86400s",
                "minCollectionPeriod": "86400s",
                "skipPricePerMinute": 0.20835,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.mastery_points",
                            "amount": "200",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Collectable_ArchitectsStudioV2_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_ClassicGreece_Collectable_ArchitectsStudio_1",
                            "amount": "10",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 1,
        "age": "ClassicGreece",
        "group": "collectableArchitectsStudioV2",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Rural_3",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Rural_3",
                "target": "Building_IberianEra_Farm_Rural_1",
                "autoFinish": true,
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-220000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-695",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-695",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Rural_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Rural_3",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "6100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Rural_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "230000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2680,
            "effects": {
                "food": 0.75
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Rural_2",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Rural_2",
                "target": "Building_FeudalAge_Farm_Rural_3",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_RidgeAndFurrow"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-210000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-825",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Rural_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Rural_2",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "5800",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Rural_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "200000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2600,
            "effects": {
                "food": 0.75
            }
        },
        "level": 26
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Farm_Rural_1",
        "type": "farm",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Farm_Rural_1",
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-1830",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-1830",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-1830",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Farm_Rural_1",
                "target": "Building_FeudalAge_Farm_Rural_2",
                "autoFinish": true,
                "duration": "6000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_OpenFieldSystem"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-200000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1225",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-815",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Farm_Rural_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Farm_Rural_1",
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 2
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "5500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Farm_Rural_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "FeudalAge",
        "group": "ruralFarm",
        "happinessEffect": {
            "maxHappiness": 2520,
            "effects": {
                "food": 0.75
            }
        },
        "level": 25
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Special_HeroAcademy_1",
                "duration": "32100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2500000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-2680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-2680",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-2680",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Special_HeroAcademy_1",
                "target": "Building_IberianEra_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "27900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Castillos"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-960000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "barrel",
                            "amount": "-1530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "manuscript",
                            "amount": "-1530",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "herbs",
                            "amount": "-1530",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_FeudalAge_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "13800s",
                "minCollectionPeriod": "13800s",
                "skipPricePerMinute": 0.2174,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "720",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production3_Building_FeudalAge_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "13800s",
                "minCollectionPeriod": "13800s",
                "skipPricePerMinute": 0.4348,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-2",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "1550",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production4_Building_FeudalAge_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "13800s",
                "minCollectionPeriod": "13800s",
                "skipPricePerMinute": 0.6522,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-3",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "2400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "280000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "FeudalAge",
        "group": "heroAcademy",
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_AgeOfTheFranks_CultureSite_Large_1",
                "range": 3,
                "points": 880
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_CultureSite_Large_1",
                "duration": "14400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_ProductionBoom"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2300000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_CultureSite_Large_1",
                "target": "Building_FeudalAge_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "12600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_RepurposedRuins"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1060000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "260000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "largeCulture",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Special_HeroAcademy_1",
        "type": "special",
        "width": 4,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/SubscriptionSlotComponentDTO",
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_SLOTS",
                "slots": 2
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Special_HeroAcademy_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_HeroAcademy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-32000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-25",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-25",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-25",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Special_HeroAcademy_1",
                "target": "Building_ClassicGreece_Special_HeroAcademy_1",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Agora"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-81000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-345",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-345",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Special_HeroAcademy_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production2_Building_MinoanEra_Special_HeroAcademy_1",
                "type": "ProductionType_WORKER",
                "duration": "21600s",
                "minCollectionPeriod": "21600s",
                "skipPricePerMinute": 0.1389,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "research_points",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "resource.hero_xp",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Special_HeroAcademy_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 2,
        "age": "MinoanEra",
        "group": "heroAcademy",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_Amphitheatre_2",
        "type": "collectable",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_Collectable_Amphitheatre_2",
                "range": 4,
                "points": 550
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_Amphitheatre_2"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_Amphitheatre_2",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_Amphitheatre_2",
                "target": "Building_BronzeAge_Collectable_Amphitheatre_3",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_Amphitheatre_2",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_Amphitheatre_2"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_Amphitheatre_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ClassicGreece",
        "group": "collectableAmphitheatre",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_Amphitheatre_1",
        "type": "collectable",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_Collectable_Amphitheatre_1",
                "range": 4,
                "points": 400
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_Amphitheatre_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_Amphitheatre_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_Amphitheatre_1",
                "target": "Building_BronzeAge_Collectable_Amphitheatre_2",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_Amphitheatre_1",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_Amphitheatre_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_Amphitheatre_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "collectableAmphitheatre",
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Ranged_2",
                "target": "Building_EarlyRome_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "15600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_SpearThrowMastery"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-725",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1085",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Velites",
                    "level": 29
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "66000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2310,
            "effect": 0.026
        },
        "level": 12,
        "assetId": "Building_EarlyRome_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_Amphitheatre_5",
        "type": "collectable",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_Collectable_Amphitheatre_5",
                "range": 4,
                "points": 1300
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_Amphitheatre_5"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_Amphitheatre_5",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_Amphitheatre_5",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_Amphitheatre_5"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_Amphitheatre_5",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "collectableAmphitheatre",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Ranged_3",
                "target": "Building_RomanEmpire_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "16800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Sagittarii"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-655",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-655",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-655",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Velites",
                    "level": 31
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "82000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2410,
            "effect": 0.027
        },
        "level": 13,
        "assetId": "Building_EarlyRome_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_Amphitheatre_4",
        "type": "collectable",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_Collectable_Amphitheatre_4",
                "range": 4,
                "points": 1000
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_Amphitheatre_4"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_Amphitheatre_4",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_Amphitheatre_4",
                "target": "Building_BronzeAge_Collectable_Amphitheatre_5",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_Amphitheatre_4",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_Amphitheatre_4"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_Amphitheatre_4",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "collectableAmphitheatre",
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_MinoanEra_CultureSite_Little_1",
                "range": 1,
                "points": 130
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_CultureSite_Little_1",
                "duration": "1440s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Shepherding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_CultureSite_Little_1",
                "target": "Building_ClassicGreece_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "1260s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Philosophy"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "400",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "littleCulture",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Collectable_Amphitheatre_3",
        "type": "collectable",
        "width": 5,
        "height": 4,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_Collectable_Amphitheatre_3",
                "range": 4,
                "points": 750
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Collectable_Amphitheatre_3"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Collectable_Amphitheatre_3",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Collectable_Amphitheatre_3",
                "target": "Building_BronzeAge_Collectable_Amphitheatre_4",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/RebuildConstructionComponentDTO",
                "id": "RebuildConstruction_Building_BronzeAge_Collectable_Amphitheatre_3",
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Collectable_Amphitheatre_3"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Collectable_Amphitheatre_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "BuildingPiece|Building_BronzeAge_Collectable_Amphitheatre_1",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "forInventory": true
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "collectableAmphitheatre",
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_EarlyRome_CultureSite_Little_1",
                "range": 1,
                "points": 160
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_CultureSite_Little_1",
                "duration": "3060s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_MarksOfHistory"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-25000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_CultureSite_Little_1",
                "target": "Building_RomanEmpire_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "2640s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_TenantFarming"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-26500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "3300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "littleCulture",
        "level": 5
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_FeudalAge_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "FeudalAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-890",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_Home_Premium_1",
                "target": "Building_IberianEra_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_FeudalAge_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "11000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "180000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 2100,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 27
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Barracks_Ranged_1",
                "duration": "21600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Velites"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1325",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1325",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1325",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Ranged_1",
                "target": "Building_EarlyRome_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "14400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_PilumPrecision"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-120000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-985",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-660",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Velites",
                    "level": 27
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "52000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 2290,
            "effect": 0.024
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_IberianEra_CultureSite_Compact_1",
                "range": 1,
                "points": 670
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_CultureSite_Compact_1",
                "duration": "9300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Masia"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-600000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_CultureSite_Compact_1",
                "target": "Building_KingdomOfSicily_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "8100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_SicilianVilletta"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-210000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "66000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "compactCulture",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Ranged_1",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Ranged_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Barracks_Ranged_1",
                "duration": "33000s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_SaracenArchers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1400000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-2515",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-2515",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-2515",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Ranged_1",
                "target": "Building_ByzantineEra_Barracks_Ranged_2",
                "autoFinish": true,
                "duration": "21900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_PrecisionArmguards"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-240000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-490",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-325",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Ranged_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_SaracenArchers",
                    "level": 45
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Ranged_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "160000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3040,
            "effect": 0.028
        },
        "level": 17
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Ranged_2",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Ranged_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Ranged_2",
                "target": "Building_ByzantineEra_Barracks_Ranged_3",
                "autoFinish": true,
                "duration": "23100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_FlightPathAnalysis"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-270000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-605",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Ranged_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_SaracenArchers",
                    "level": 48
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Ranged_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3210,
            "effect": 0.028
        },
        "level": 18,
        "assetId": "Building_ByzantineEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Barracks_Ranged_3",
        "type": "barracks",
        "width": 3,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_ByzantineEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_ByzantineEra_Barracks_Ranged_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Barracks_Ranged_3",
                "target": "Building_AgeOfTheFranks_Barracks_Ranged_1",
                "autoFinish": true,
                "duration": "24600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_FrancArchers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-300000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-495",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-495",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-495",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Barracks_Ranged_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_ByzantineEra_Player_SaracenArchers",
                    "level": 51
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Barracks_Ranged_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "220000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "rangedBarracks",
        "happinessEffect": {
            "maxHappiness": 3390,
            "effect": 0.028
        },
        "level": 19,
        "assetId": "Building_ByzantineEra_Barracks_Ranged_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "8000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "8000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "cityHall",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_City_CityHall_1",
        "type": "cityHall",
        "width": 5,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_EarlyRome_City_CityHall_1",
                "amount": 4
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_City_CityHall_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_City_CityHall_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "5850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "5850",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "cityHall",
        "level": 6
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_KingdomOfSicily_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "KingdomOfSicily"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-990",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "310000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "KingdomOfSicily",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 2700,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 33
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_CultureSite_Little_1",
        "type": "cultureSite",
        "width": 1,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_BronzeAge_CultureSite_Little_1",
                "range": 1,
                "points": 110
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_CultureSite_Little_1",
                "duration": "330s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_SacredGroves"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_CultureSite_Little_1",
                "target": "Building_MinoanEra_CultureSite_Little_1",
                "autoFinish": true,
                "duration": "120s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Shepherding"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_CultureSite_Little_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_CultureSite_Little_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "32",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "littleCulture",
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_IberianEra_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "IberianEra"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-940",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_Home_Premium_1",
                "target": "Building_KingdomOfSicily_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_IberianEra_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "240000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 2360,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 30
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Workshop_Carpenter_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Workshop_Carpenter_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "planks"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1600000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1145",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1145",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-1145",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Workshop_Carpenter_1",
                "target": "Building_AgeOfTheFranks_Workshop_Carpenter_1",
                "autoFinish": true,
                "duration": "8700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "cartwheel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-960000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-635",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-635",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Workshop_Carpenter_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Workshop_Carpenter_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "planks",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Workshop_Carpenter_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "190000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ByzantineEra",
        "group": "carpenter",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Workshop_Artisan_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Workshop_Artisan_1",
                "duration": "6600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "gold_laurel"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-270000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1365",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1365",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Workshop_Artisan_1",
                "target": "Building_RomanEmpire_Workshop_Artisan_1",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "goblet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-288000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1040",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Workshop_Artisan_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_EarlyRome_Workshop_Artisan_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "bronze_bracelet",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_EarlyRome_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "iron_pendant",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_EarlyRome_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "silver_ring",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_EarlyRome_Workshop_Artisan_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "gold_laurel",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Workshop_Artisan_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "35000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "EarlyRome",
        "group": "artisan",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 4
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Small_2",
                "target": "Building_AgeOfTheFranks_Home_Small_3",
                "autoFinish": true,
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Patrimony"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-27000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-225",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-340",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2250",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "23000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2080,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 23
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Small_3",
                "target": "Building_FeudalAge_Home_Small_1",
                "autoFinish": true,
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_Feudalism"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-29000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-225",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cartwheel",
                            "amount": "-225",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-225",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2300",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "25000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2150,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 24
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Siege_2",
                "target": "Building_EarlyRome_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "18300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_SiegeEngineering"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-160000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-850",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1270",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Siege",
                    "level": 29
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "76000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2310,
            "effect": 0.026
        },
        "level": 12,
        "assetId": "Building_EarlyRome_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Siege_3",
                "target": "Building_RomanEmpire_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "19800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_RomanEmpire_Ballistas"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-190000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "tunic",
                            "amount": "-770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-770",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-770",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Siege",
                    "level": 31
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "95000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2410,
            "effect": 0.027
        },
        "level": 13,
        "assetId": "Building_EarlyRome_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_EarlyRome_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_EarlyRome_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_EarlyRome_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_EarlyRome_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_EarlyRome_Barracks_Siege_1",
                "duration": "25200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-460000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-1555",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-1555",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-1555",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_EarlyRome_Barracks_Siege_1",
                "target": "Building_EarlyRome_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "16800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_EarlyRome_WarMachines"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-140000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "stone_tablet",
                            "amount": "-1155",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "gold_laurel",
                            "amount": "-770",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_EarlyRome_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_EarlyRome_Player_Siege",
                    "level": 27
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_EarlyRome_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "60000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "EarlyRome",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 2290,
            "effect": 0.024
        },
        "level": 11
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_AgeOfTheFranks_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_Home_Small_1",
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_RegnumFrancorum"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-170000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-230",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-230",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_Home_Small_1",
                "target": "Building_AgeOfTheFranks_Home_Small_2",
                "autoFinish": true,
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_LinguaFranca"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-25000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "ink",
                            "amount": "-170",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "salt",
                            "amount": "-260",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_AgeOfTheFranks_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "20000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 2020,
            "effects": {
                "coins": 0.37
            }
        },
        "level": 22
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Home_Small_1",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_StoneAge_Home_Small_1",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_StoneAge_Home_Small_1",
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_TribalSettlement"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "food",
                            "amount": "-1",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Home_Small_1",
                "target": "Building_StoneAge_Home_Small_2",
                "autoFinish": true,
                "duration": "5s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_Firemaker"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-10",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "food",
                            "amount": "-5",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Home_Small_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Home_Small_1",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "73s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "150",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Home_Small_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "food",
                            "amount": "1",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 150,
            "effects": {
                "coins": 0.299
            }
        },
        "level": 1
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Home_Small_3",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_StoneAge_Home_Small_3",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Home_Small_3",
                "target": "Building_BronzeAge_Home_Small_1",
                "autoFinish": true,
                "duration": "150s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Village"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Home_Small_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Home_Small_3",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "300s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "450",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Home_Small_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "12",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 500,
            "effects": {
                "coins": 0.299
            }
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_StoneAge_Home_Small_2",
        "type": "home",
        "width": 2,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_StoneAge_Home_Small_2",
                "amount": 1
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_StoneAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_StoneAge_Home_Small_2",
                "target": "Building_StoneAge_Home_Small_3",
                "autoFinish": true,
                "duration": "70s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_StoneAge_BabyBoom"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-20",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_StoneAge_Home_Small_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_StoneAge_Home_Small_2",
                "auto": true,
                "duration": "10800s",
                "minCollectionPeriod": "60s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "230",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "21600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_StoneAge_Home_Small_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "StoneAge",
        "group": "smallHome",
        "happinessEffect": {
            "maxHappiness": 250,
            "effects": {
                "coins": 0.299
            }
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Average_3",
                "target": "Building_ClassicGreece_Home_Average_1",
                "autoFinish": true,
                "duration": "2100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ClassicGreece_Carpentry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-8100",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-145",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-145",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-145",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1280",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2800",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1030,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Average_2",
                "target": "Building_MinoanEra_Home_Average_3",
                "autoFinish": true,
                "duration": "1860s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Administration"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-6400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-160",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-235",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 980,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_MinoanEra_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_MinoanEra_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_MinoanEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_MinoanEra_Home_Average_1",
                "duration": "3600s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Masonry"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-8000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-80",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-80",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-80",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_MinoanEra_Home_Average_1",
                "target": "Building_MinoanEra_Home_Average_2",
                "autoFinish": true,
                "duration": "1560s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Screws"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-5400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-135",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-205",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_MinoanEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_MinoanEra_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1100",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_MinoanEra_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "1300",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "MinoanEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 940,
            "effects": {
                "coins": 0.212
            }
        },
        "level": 7
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_IberianEra_CultureSite_Large_1",
        "type": "cultureSite",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_IberianEra_CultureSite_Large_1",
                "range": 3,
                "points": 1280
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_IberianEra_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_IberianEra_CultureSite_Large_1",
                "duration": "18900s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_IberianEra_Patios"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4600000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_IberianEra_CultureSite_Large_1",
                "target": "Building_KingdomOfSicily_CultureSite_Large_1",
                "autoFinish": true,
                "duration": "16500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_KingdomOfSicily_Piazza"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-1630000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_IberianEra_CultureSite_Large_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_IberianEra_CultureSite_Large_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "510000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "IberianEra",
        "group": "largeCulture",
        "level": 10
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_AgeOfTheFranks_CultureSite_Compact_1",
        "type": "cultureSite",
        "width": 2,
        "height": 1,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_AgeOfTheFranks_CultureSite_Compact_1",
                "range": 1,
                "points": 480
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_AgeOfTheFranks_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_AgeOfTheFranks_CultureSite_Compact_1",
                "duration": "7200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Skirmishers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-290000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_AgeOfTheFranks_CultureSite_Compact_1",
                "target": "Building_FeudalAge_CultureSite_Compact_1",
                "autoFinish": true,
                "duration": "6300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_FeudalAge_MythsAndLegends"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-135000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_AgeOfTheFranks_CultureSite_Compact_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_AgeOfTheFranks_CultureSite_Compact_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "34000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "AgeOfTheFranks",
        "group": "compactCulture",
        "level": 8
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Siege_3",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Siege_3",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Siege_3",
                "target": "Building_MinoanEra_Barracks_Siege_1",
                "autoFinish": true,
                "duration": "2700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_MinoanEra_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-24000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-140",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-140",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Siege_3"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Siege",
                    "level": 16
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Siege_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "4900",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1430,
            "effect": 0.014
        },
        "level": 4,
        "assetId": "Building_BronzeAge_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Siege_2",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Siege_2",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Siege_2",
                "target": "Building_BronzeAge_Barracks_Siege_3",
                "autoFinish": true,
                "duration": "1200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_BronzeArtilleryGears"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-18000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-49",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wool",
                            "amount": "-33",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Siege_2"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Siege",
                    "level": 14
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Siege_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2500",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1350,
            "effect": 0.011
        },
        "level": 3,
        "assetId": "Building_BronzeAge_Barracks_Siege_1"
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_BronzeAge_Barracks_Siege_1",
        "type": "barracks",
        "width": 6,
        "height": 5,
        "components": [
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_ability_training.Building_BronzeAge_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroAbilityTrainingComponentDTO"
            },
            {
                "@type": "type.googleapis.com/GameDesignReference",
                "id": "hero_building_boost.Building_BronzeAge_Barracks_Siege_1",
                "dtoTypeUrl": "type.googleapis.com/HeroBuildingBoostComponentDTO"
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_BronzeAge_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_BronzeAge_Barracks_Siege_1",
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_Siege"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-2200",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_BronzeAge_Barracks_Siege_1",
                "target": "Building_BronzeAge_Barracks_Siege_2",
                "autoFinish": true,
                "duration": "300s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_BronzeAge_MantletShields"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-4400",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "bronze_bracelet",
                            "amount": "-8",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "alabaster_idol",
                            "amount": "-13",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_BronzeAge_Barracks_Siege_1"
            },
            {
                "@type": "type.googleapis.com/BuildingUnitProviderComponentDTO",
                "unit": {
                    "definitionId": "unit.Unit_BronzeAge_Player_Siege",
                    "level": 12
                },
                "defaultSlots": 5
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_BronzeAge_Barracks_Siege_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "700",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "BronzeAge",
        "group": "siegeBarracks",
        "happinessEffect": {
            "maxHappiness": 1100,
            "effect": 0.009
        },
        "level": 2
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Average_3",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Average_3",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Average_3",
                "target": "Building_AgeOfTheFranks_Home_Average_1",
                "autoFinish": true,
                "duration": "5700s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_AgeOfTheFranks_Villers"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-56000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-240",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-240",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Average_3"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Average_3",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2600",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Average_3",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "42000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1990,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 21
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_RomanEmpire_Home_Premium_1",
        "type": "home",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_RomanEmpire_Home_Premium_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_RomanEmpire_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_RomanEmpire_Home_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "RomanEmpire"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-740",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_RomanEmpire_Home_Premium_1",
                "target": "Building_ByzantineEra_Home_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-50",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_RomanEmpire_Home_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_RomanEmpire_Home_Premium_1",
                "auto": true,
                "duration": "28800s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "7000",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "57600s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_RomanEmpire_Home_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "78000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "RomanEmpire",
        "group": "premiumHome",
        "happinessEffect": {
            "maxHappiness": 1370,
            "effects": {
                "coins": 0.65
            }
        },
        "level": 18
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Average_2",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Average_2",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Average_2",
                "target": "Building_ByzantineEra_Home_Average_3",
                "autoFinish": true,
                "duration": "5400s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_DomicalVault"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-51000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "planks",
                            "amount": "-190",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-290",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Average_2"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Average_2",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2500",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Average_2",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "36000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1910,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 20
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ByzantineEra_Home_Average_1",
        "type": "home",
        "width": 3,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/GrantWorkerComponentDTO",
                "id": "GrantWorker1_Building_ByzantineEra_Home_Average_1",
                "amount": 2
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ByzantineEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ByzantineEra_Home_Average_1",
                "duration": "7500s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_PendentiveDome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-260000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "cape",
                            "amount": "-1205",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "goblet",
                            "amount": "-1205",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "mosaic",
                            "amount": "-1205",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ByzantineEra_Home_Average_1",
                "target": "Building_ByzantineEra_Home_Average_2",
                "autoFinish": true,
                "duration": "5100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/ResearchRequirementDTO",
                            "id": "Technology_ByzantineEra_CrossgroinedVault"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-46000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "parchment",
                            "amount": "-155",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "pepper",
                            "amount": "-235",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 1
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ByzantineEra_Home_Average_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ByzantineEra_Home_Average_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "2400",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ByzantineEra_Home_Average_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "31000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "ByzantineEra",
        "group": "averageHome",
        "happinessEffect": {
            "maxHappiness": 1850,
            "effects": {
                "coins": 0.219
            }
        },
        "level": 19
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_FeudalAge_CultureSite_Premium_1",
        "type": "cultureSite",
        "width": 3,
        "height": 2,
        "components": [
            {
                "@type": "type.googleapis.com/CultureComponentDTO",
                "id": "Culture_Building_FeudalAge_CultureSite_Premium_1",
                "range": 2,
                "points": 2320
            },
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_FeudalAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_FeudalAge_CultureSite_Premium_1",
                "duration": "0s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/AgeRequirementDTO",
                            "minAgeDefinition": "FeudalAge"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-1040",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_FeudalAge_CultureSite_Premium_1",
                "target": "Building_IberianEra_CultureSite_Premium_1",
                "autoFinish": true,
                "duration": "0s",
                "start": {
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "premium",
                            "amount": "-90",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_FeudalAge_CultureSite_Premium_1"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_FeudalAge_CultureSite_Premium_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "220000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "age": "FeudalAge",
        "group": "premiumCulture",
        "level": 9
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_ClassicGreece_Workshop_StoneMason_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_ClassicGreece_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_ClassicGreece_Workshop_StoneMason_1",
                "duration": "4800s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "column"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "marble_bust",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "iron_pendant",
                            "amount": "-605",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "linen_shirt",
                            "amount": "-605",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/UpgradeComponentDTO",
                "id": "Upgrade_Building_ClassicGreece_Workshop_StoneMason_1",
                "target": "Building_EarlyRome_Workshop_StoneMason_1",
                "autoFinish": true,
                "duration": "4200s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "stone_tablet"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-171000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "toga",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "silver_ring",
                            "amount": "-690",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "column",
                            "amount": "-690",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 2
                },
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_ClassicGreece_Workshop_StoneMason_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_ClassicGreece_Workshop_StoneMason_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "alabaster_idol",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_ClassicGreece_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "marble_bust",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_ClassicGreece_Workshop_StoneMason_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 3
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "column",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_ClassicGreece_Workshop_StoneMason_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "14000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "ClassicGreece",
        "group": "stoneMason",
        "happinessEffect": {
            "maxHappiness": 2000,
            "effect": 0.015
        },
        "level": 3
    },
    {
        "@type": "type.googleapis.com/BuildingDefinitionDTO",
        "id": "Building_KingdomOfSicily_Workshop_Scribe_1",
        "type": "workshop",
        "width": 4,
        "height": 3,
        "components": [
            {
                "@type": "type.googleapis.com/InitComponentDTO",
                "id": "Init_Building_KingdomOfSicily_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ConstructionComponentDTO",
                "id": "Construction_Building_KingdomOfSicily_Workshop_Scribe_1",
                "duration": "17100s",
                "start": {
                    "requirements": [
                        {
                            "@type": "type.googleapis.com/GoodRequirementDTO",
                            "id": "tome"
                        }
                    ],
                    "costs": [
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "coins",
                            "amount": "-7100000",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "saffron",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "door",
                            "amount": "-6455",
                            "fromResourceChange": true
                        },
                        {
                            "@type": "type.googleapis.com/ResourceDTO",
                            "definitionId": "wax_seal",
                            "amount": "-6455",
                            "fromResourceChange": true
                        }
                    ]
                },
                "complete": {},
                "workersRequired": {
                    "amount": 3
                },
                "finish": {},
                "usesConstructionSlots": true
            },
            {
                "@type": "type.googleapis.com/MoveComponentDTO",
                "id": "Move_Building_KingdomOfSicily_Workshop_Scribe_1"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production1_Building_KingdomOfSicily_Workshop_Scribe_1",
                "auto": true,
                "duration": "21600s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "parchment",
                            "amount": "180",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "43200s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production7_Building_KingdomOfSicily_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "ink",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production8_Building_KingdomOfSicily_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "manuscript",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production9_Building_KingdomOfSicily_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "wax_seal",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/ProductionComponentDTO",
                "id": "Production10_Building_KingdomOfSicily_Workshop_Scribe_1",
                "auto": true,
                "duration": "14400s",
                "minCollectionPeriod": "600s",
                "earlyCollectable": true,
                "skipPricePerMinute": 1.0,
                "behaviours": [
                    {
                        "@type": "type.googleapis.com/WorkerBehaviourDTO",
                        "amount": 5
                    }
                ],
                "start": {},
                "finish": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "tome",
                            "amount": "120",
                            "fromResourceChange": true
                        }
                    ]
                },
                "subscriptionBenefitDefinitionId": "subscription_benefit.PRODUCTION_DURATION",
                "subscriptionDuration": "28800s"
            },
            {
                "@type": "type.googleapis.com/SellComponentDTO",
                "id": "Sell_Building_KingdomOfSicily_Workshop_Scribe_1",
                "start": {
                    "rewards": [
                        {
                            "@type": "type.googleapis.com/ResourceRewardDTO",
                            "baseData": {},
                            "resource": "coins",
                            "amount": "790000",
                            "fromResourceChange": true
                        }
                    ]
                }
            }
        ],
        "cities": [
            "City_Capital"
        ],
        "freeProductionSlots": 3,
        "age": "KingdomOfSicily",
        "group": "scribe",
        "happinessEffect": {
            "maxHappiness": 4000,
            "effect": 0.007
        },
        "level": 5
    }
]

export default allBuildings;