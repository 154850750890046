const sectionsDefinition = {
    page: "research",
    tabs: [
        {
            id: 1,
            url: "",
            name: "Intro",
            img: "",
            show: false,
            dynamicSegments: [],
        },
    ]
}

export default sectionsDefinition;